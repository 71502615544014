import React from 'react';
import { withRouter } from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typograph, Input, Modal, Form, Select, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import { getApiUrl, moneyFormat } from '../../utils.js'
import { connect } from "react-redux";
import { Divider, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import get from "lodash.get";
//import './obsolete.css';



class OrderListTracking extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      listaOrdini: [],
      modalKey: 1,
      showAdminPopup: false,
      adminRow: null,
      webOrderCode: '',
      cCheckoutTime: '',
      uIdCliente: '1820'

    }

    this.refRefTrack = React.createRef();
    this.refSped = React.createRef();
    this.refDel = React.createRef();
    this.refForm = React.createRef();
    this.refRefParcel = React.createRef();
    this.refCorriere = React.createRef();



    this.insertTrack = this.insertTrack.bind(this);
    this.setResults = this.setResults.bind(this)
    this.onManageClick = this.onManageClick.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  setResults(data) {
    this.setState({
      ...this.state,
      listaOrdini: data,

    });

    console.log(this.state.listaOrdini)

  }
  //ORDINAMENTO COLONNA CODICE




  //MANAGING MODAL
  onManageClick(record) {

    this.setState({
      ...this.state,
      showAdminPopup: true,
      adminRow: [record],
      modalKey: this.state.modalKey + 1,

      webOrderCode: record.webOrderCode,
      cCheckoutTime: record.cCheckoutTime

    })

  }
  hideModal() {
    this.setState({
      ...this.state,
      showAdminPopup: false,
      adminRow: null,
      showSuccessMessages: false
    })
  }

  insertTrack() {




    this.refForm.current.validateFields()
      .then(v => {
        let param = {
          dataShipped: this.refSped.current.props.value._d.toISOString(),
          dataEstimatedDelivery: this.refDel.current.props.value._d.toISOString(),
          tracking: this.refRefTrack.current.props.value,
          service: this.refCorriere.current.props.value,
          parcelNumber: this.refRefParcel.current.props.value,
          webOrderCode: this.state.webOrderCode,
          cCheckoutTime: this.state.cCheckoutTime
        }

        fetch(getApiUrl() + "/AD/api/insertTracking?uIdCliente=" + this.state.uIdCliente,{
          method: 'POST',
          cache: 'no-cache',
          credentials: 'include',
          headers: new Headers({
            "Content-Type": 'application/json',
            "Accept": 'application/json'
          }),
          body: JSON.stringify(param)
        })
          .then(res => res.json())
          .then(res => {
            if (!res) {
              throw (res);
            } else {

              this.setState({
                ...this.state,
                listaOrdini: res.data

              })
              message.success("Tracking inserito!")
            }
          })
          .catch(res => {
            alert(res.message)
          })
      })
      //ERROR IN VALIDATION
      .catch(errorInfo => console.log("Validation error"))

  }







  //FILTRO DI RICERCA

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }


  getColumnSearchProps = (dataIndex, exact, dataIndex2, replaceDotWithComma) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Elimina i filtri
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
    onFilter: function (value, record) {

      console.log("On filter called with value: " + value + " and record")
      console.log(record)

      if (replaceDotWithComma) {
        value = value.replaceAll(',', '.')
      }


      //Search on field1 + field2  
      if (dataIndex2 !== null && dataIndex2 !== undefined) {
        if (exact == null || exact === undefined || exact === false) {
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase().includes(value.toString().toLowerCase())
        } else {
          //exact
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase() === (value.toString().toLowerCase())
        }

      }
      else if (exact === null || exact === undefined || exact === false) {
        console.log("Search exact false ")
        console.log(("Record:" + get(record, dataIndex) || "").toString().toLowerCase())
        console.log("Value:" + value.toString().toLowerCase())
        return (get(record, dataIndex) || "").toString().toLowerCase().includes(value.toString().toLowerCase())
      }
      else {
        return (get(record, dataIndex) || "").toString().toLowerCase() === value.toString().toLowerCase()
      }
    }
    ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });




  render() {

    function onChange(pagination, filters, sorter, extra) {
      console.log('params', pagination, filters, sorter, extra);
      console.log(extra)
    }

    let setResults = this.setResults;
    // let initLoad = this.initLoad;


    fetch(getApiUrl() + "/AD/api/getOrders?uIdCliente=" + this.state.uIdCliente,
      {
        method: 'GET',
        credentials: 'include',
        headers: new Headers({
          "Accept": 'application/json',

        }),
      }).then(res => res.json()).then(res => {


        if (!res.error && (!this.state.listaOrdini.length) && (res.data.length != 0)) {
          setResults(res.data);

        }
        //let dati = res.data;

      }).catch(err => {
        message.error("Non è stato possibile recuperare la lista degli ordini")
      })



    /* let config = {
        onChangeQntRich : ()=> null,
        onAddToChart : null,
        onFindCrossReference : null,
        onDeliveryDate : null,
        onNoteClick : null
        };

    let dtc = new DataColumnUtil();
    let columns = dtc.createProductTableCol(config); 
    let colums2 = columns.filter(element => element.title !== 'Qnt').filter(element => element.title !== 'Sconto').filter(element => element.title !== 'Azioni').filter(element => element.title !== 'Netto').filter(element => element.title !== 'Fam.').map( (element, index) => {
        if (element.title == 'Disp.'){
            return {
                    title: 'Disp.', dataIndex: 'disp', key: 'disp'
                }
        }
        else{
            return element
        }
    })  */

    let t = this

    const columns = [
      {
        title: 'Ordine n', width: "10%", key: 'webOrderCode', index: 'webOrderCode',
        render: function (text, record, index) {
          return (<span>{record.webOrderCode}</span>);
        },
        ...this.getColumnSearchProps(["webOrderCode"])
      },
      {
        title: 'Check out', width: "15%", key: 'cCheckoutTime', index: 'cCheckoutTime',
        render: function (text, record, index) {
          return (<span>{record.cCheckoutTime}</span>);
        },
        dataIndex: 'cCheckoutTime',
        //sorter: (a, b) => a.cCheckoutTime.localeCompare(b.cCheckoutTime),
        //sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps(["cCheckoutTime"])
      },
      {
        title: 'Data Spedizione', width: "15%", key: 'dataShipped', index: 'dataShipped',
        render: function (text, record, index) {
          return (<span>{record.dataShipped}</span>);
        },
        ...this.getColumnSearchProps(["codsco"])
      },
      {
        title: 'Data prevista consegna', width: "15%", key: 'dataEstimatedDelivery', index: 'dataEstimatedDelivery',
        render: function (text, record, index) {
          return (<span>{record.dataEstimatedDelivery}</span>);
        },
        ...this.getColumnSearchProps(["dataEstimatedDelivery"])
      },
      {
        title: 'Pacco Numero', width: "15%", key: 'parcelNumber', index: 'parcelNumber',
        render: function (text, record, index) {
          return (<span>{record.parcelNumber}</span>);
        },

        ...this.getColumnSearchProps(["parcelNumber"])
      },
      {
        title: 'Corriere', width: "5%", key: 'service', index: 'service',
        render: function (text, record, index) {
          return (<span>{record.service}</span>);
        },
        ...this.getColumnSearchProps(["service"])
      },

      {
        title: 'Tracking Number', width: "25%", key: 'tracking', index: 'tracking',
        render: function (text, record, index) {
          return (<span>{record.tracking}</span>);
        },
        ...this.getColumnSearchProps(["tracking"])
      },

      {
        title: 'Azioni', width: "50px", key: 'action', index: 'action',
        render: function (text, record, index) {
          return (/*record.tracking === undefined &&*/ <span><SettingOutlined onClick={() => { t.onManageClick(record) }} /></span>)
        },
      }

    ]

    const columns2 = [
      {
        title: 'Ordine n', width: "10%", key: 'webOrderCode', index: 'webOrderCode',
        render: function (text, record, index) {

          return (<span>{record.webOrderCode}</span>);
        },

      },
      {
        title: 'Check out', width: "15%", key: 'cCheckoutTime', index: 'cCheckoutTime',
        render: function (text, record, index) {
          return (<span>{record.cCheckoutTime}</span>);
        },
        dataIndex: 'cCheckoutTime',

      },
    ]



    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    
    
    let uploadProps = {
      
      name: 'file',
      action: getApiUrl() + "/AD/api/updateTrackingCSV",
     /*action: getApiUrl() + "/admin/AD/updateTrackingCSV",*/
      headers: {},
      onChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} File caricato correttamente`);
        } else if (info.file.status === 'error') {
          message.error(`Upload del file " ${info.file.name} " fallito.`);
        }
      }
    }
    

    return (
      <div>
      <Divider style ={{margin: 2} } orientation='left'> Caricamento Tracking via CSV </Divider>  
        <Upload style ={{margin: 0} } className = "UploadCsv" {...uploadProps}>
          <Button className="buttonUploadCsv" type="primary">UploadCsv</Button>
        </Upload>
        <Divider style ={{margin: 4} }> </Divider>
        <Modal key={this.state.modalKey} width={"85%"} title="Conferma Reso" visible={this.state.showAdminPopup} onOk={null} onCancel={this.hideModal}
          okText={null} cancelText="Chiudi" okButtonProps={{ style: { display: 'none' } }} >
          <p>Stai inserendo i dettagli di spedizione per l'articolo:</p>

          <Table className="search-results-table" columns={columns2} dataSource={this.state.adminRow} />


          <Form ref={this.refForm} {...layout} name="basic" initialValues={{ remember: true }}  >

            <Form.Item label="Inserisci la data di Spedizione" name="spedizione" rules={[{ required: true, message: 'Inserire data di spedizione' }]} ref={this.refSped}>
              <DatePicker placeholder="Spedizione" ref={this.refSped} />

            </Form.Item>

            <Form.Item label="Inserisci la data di prevista consegna" name="consegna" rules={[{ required: true, message: 'Inserire data di prevista consegna' }]} ref={this.refDel}>
              <DatePicker placeholder="Consegna" ref={this.refDel} />

            </Form.Item>

            <Form.Item label="Corriere" name="corriere" rules={[{ required: true }]}>
              <Input placeholder="Nome Corriere" ref={this.refCorriere} />
            </Form.Item>
            <Form.Item label="Tracking" name="tracking" rules={[{ required: true }]}>
              <Input placeholder="Tracking Number" ref={this.refRefTrack} />
            </Form.Item>
            <Form.Item label="Pacco Numero" name="pacco" rules={[{ required: false }]}>
              <Input placeholder="Parcel Number" ref={this.refRefParcel} />
            </Form.Item>

            <Form.Item label="Imposta" name="qnt" >
              <Button onClick={this.insertTrack} type="primary">Imposta</Button>
            </Form.Item>

          </Form>

          {this.state.showSuccessMessages === true && (
            <Alert message="Conferma" description="Lo stato è stato cambiato con successo." type="success" showIcon />
          )
          }

        </Modal>
        <Table columns={columns} dataSource={this.state.listaOrdini} onChange={onChange}></Table>
      </div>
    )







  }




}
export default withRouter(OrderListTracking)