import React from 'react';
import { Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { fetchSearch } from '../../actions/actions.js'
import { connect } from "react-redux";
import './search.css';
import FormItem from 'antd/lib/form/FormItem.js';




function mapDispatchToProps(dispatch) {
    return {
        fetchSearch: (stringToSearch) => dispatch(fetchSearch(stringToSearch))
    };
}

class Search extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,

        }

        this.searchHandler = this.searchHandler.bind(this)
    }

    searchHandler(e) {
        if (e.target.value.length < 3 && e.target.value.length !== 0) {
            this.setState({ loading: true });
            document.getElementById("error").style.visibility = "visible"

        } else {

            this.setState({ loading: false });
            document.getElementById("error").style.visibility = "hidden"
        }

    }

    render() {

        const formItemLayout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 12 },
          };

        return (

            <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <img src="logo/peugeot2.png " style={{ width: "4.5em", height: "4.5em" }} />
                    <img src="logo/citroen.png" style={{ width: "4.5em", height: "4.5em" }} />
                    <img src="logo/opel.png" style={{ width: "5em", height: "5em" }} />
                    <img src="logo/ds.png" style={{ width: "5em", height: "5em" }} />
                    <img src="logo/FIAT_logo_(2020).svg.png" style={{ width: "4.5em", height: "4.5em" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 15 }}>
                    <img src="logo/1200px-Alfa_Romeo_2015.svg.png" style={{ width: "4.5em", height: "4.5em" }} />
                    <img src="logo/Lancia-Logo-2024.png" style={{ width: "4.5em", height: "4.5em" }} />
                    <img src="logo/Abarth_logo.png" style={{ width: "4.5em" }} />
                    <img src="logo/jeep.png " style={{ width: "4.5em" }} />
                    <img src="logo/fiat_professional_logo.png" style={{ width: "4.5em" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 15 }}>
                    <img src="logo/new_logo_eurorepar.png" style={{ height: "3.5em" }} />
                    <img src="logo/piaggio commercial.png" style={{ height: "3.5em" }} />
                </div>
                <h1 className="searchTitle">Cerca un ricambio</h1>

                <Input.Search className="searchBox" placeholder="digita un codice (Min 3 caratteri)" onChange = {this.searchHandler} enterButton="Search" size="large" loading={this.state.loading} onSearch={value => {if (value !== "")  this.props.fetchSearch(value)}} />
                 <em style={{visibility: 'hidden', color: 'red'}} id ="error">Inserire almeno 3 caratteri</em>   

            </div>
        )
    }
}


export default connect(null, mapDispatchToProps)(Search);