import React from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import CustomerInfoSingleForm from "./customerInfoSingleForm";
import CustomerInfoTable from "./customerInfoTable";

function mapStateToProps(state) {
    return { user: state.user }
}

class CustomerInfoContainer_2 extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            user: this.props.user
        }
    }
    
    //necessito della proprietà di user per potermi chiamare la infoTable o la customerInfosingleForm - dove prendo lo user? -> reducer
    
    render() {
        
        if(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN"){
            return (<div><CustomerInfoTable/></div>);
        }else{
            return (<div><CustomerInfoSingleForm/></div>)
        }
        return(<div>HELLO</div>)
    }
}

//EXTRACTS DATA FROM REDUX-STORE
export default connect(mapStateToProps)(CustomerInfoContainer_2)