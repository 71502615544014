import React from 'react';
import { Button, Tooltip, Modal } from 'antd';
import Icon from '@ant-design/icons';
import { connect } from "react-redux";
import { fetchNewCart, clearSeachHistory } from '../../actions/actions.js'
import { ExclamationCircleOutlined, FileExcelOutlined, ClearOutlined, QuestionOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import Cart from '../Cart/cart.js'

const { confirm } = Modal;

function mapDispatchToProps(dispatch) {
  return {
    fetchNewCart: () => dispatch(fetchNewCart()),
    clearSeachHistory: () => dispatch(clearSeachHistory())
  };
}

const mapStateToProps = state => {
  return {
    searchResults: state.lastSearch,
    prevSearchResults: state.prevSearch,
  };
};

class ActionBar extends React.Component {

  constructor(props) {
    super(props);
    this.onClickEmptyCart = this.onClickEmptyCart.bind(this);
    this.showConfirmEmptyCarts = this.showConfirmEmptyCarts.bind(this);
    this.showCarts = this.showCarts.bind(this);
    this.onMissingClick = this.onMissingClick.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.richiediPreventivo = this.richiediPreventivo.bind(this)
    this.state = {
      checkoutIsPending: props.requstIsPending || false,
      searchDone: this.props.searchResults.results.length > 0 ? true : false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.cart !== undefined && this.props.cart.requstIsPending !== prevProps.cart.requstIsPending) {
      this.setState({
        checkoutIsPending: this.props.cart.requstIsPending
      });
    }
    if (this.props.searchResults !== prevProps.searchResults) {
      this.setState({
        ...this.state,
        searchDone: this.props.searchResults.results.length > 0 ? true : false
      })
    }
  }

  onClickEmptyCart() {
    this.showConfirmEmptyCarts()
  }

  onClickLoadCSV() {
    this.props.history.push('/loadFile')
  }

  richiediPreventivo() {
    this.props.history.push('/preventivo')
  }

  showConfirmEmptyCarts() {
    let that = this;
    confirm({
      title: 'Svuota il carrello',
      icon: <ExclamationCircleOutlined />,
      content: 'Sei sicuro di voler svuotare il carrello?',
      onOk() {
        that.props.fetchNewCart();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  showCarts() {
    this.props.history.push('/cart')
  }

  onMissingClick() {
    this.props.history.push('/missing')
  }

  onClearSearch() {
    this.props.clearSeachHistory();
  }

  render() {

    const ImgFlushCart = () => (
      <img style={{ width: "2.2em", marginTop: "-5px" }} src="icon/icons8-svuota-carrello-48-tratto-4.png"></img>
    );

    const ImgCartDetail = () => (
      <img style={{ width: "2.2em", marginTop: "-5px" }} src="icon/icons8-carrello-della-spesa-48-tratto-4.png"></img>
    );

    const NotaImportante = () => (
      <img style={{ width: "2.2em", marginTop: "-5px" }} src="icon/icons8-nota-importante-48.png"></img>
    );

    const IconFlushCart = props => <Icon component={ImgFlushCart} {...props} />;

    const IconCartDetail = props => <Icon component={ImgCartDetail} {...props} />;

    let showOrderButton = this.props.showOrderButton === true ? true : false

    return (
      <div className="actionbar">
        <Button onClick={() => { this.showConfirmEmptyCarts() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<IconFlushCart />}>Svuota Carrello </Button>

        {!showOrderButton && (
          <Button onClick={() => { this.showCarts() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<IconCartDetail />}>Visualizza Carrello </Button>
        )}

        {!showOrderButton && (
          <Button onClick={() => { this.onMissingClick() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<ExclamationCircleOutlined />}>Lista Mancanti</Button>
        )}

        {showOrderButton && (
          <Button onClick={this.props.sendNotesCallback} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<NotaImportante />}>Salva Note e Riferimenti</Button>
        )}
        {showOrderButton && (
          <Button loading={this.state.checkoutIsPending} onClick={this.props.checkoutCartCallback} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<IconCartDetail />}>Invia Ordine</Button>
        )}
        {showOrderButton && (<Button onClick={() => { this.onClickLoadCSV() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<FileExcelOutlined />}>Carica CSV </Button>)}
        {!showOrderButton && (
          <Button onClick={() => { this.richiediPreventivo() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<QuestionOutlined />}>Richiedi Preventivo</Button>
        )}
        {!showOrderButton && this.state.searchDone && (
          <Button onClick={() => { this.onClearSearch() }} style={{ marginLeft: "1em", marginRight: "1em" }} type="primary" icon={<ClearOutlined />}>Pulisci ricerche</Button>
        )}

        <Cart mode="cartSummaryInline" showUserIcon="false" />

      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionBar));