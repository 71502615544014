import React from 'react';
import { connect } from "react-redux";
import {fetchCheckLogin} from '../../actions/actions.js'
import {Redirect } from "react-router-dom";
import { withRouter } from "react-router";


const mapStateToProps = state => {
    return { 
        init : state.init,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fetchCheckLogin : () => dispatch(fetchCheckLogin())
    };
}

class Auth extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
       this.state = {
        init: this.props.init
        }
            this.props.fetchCheckLogin();
    }


        /* Manage state update */
        componentDidUpdate(prevProps, prevState, snapshot) {
    
            if (this.props.init !== prevProps.init) {
              console.log("Auth: ComponentDidUpdate")
              this.setState ({
                ...this.state,
                init:{ 
                    ...this.props.init,
                },
              });
            }
          }

    render(){
        console.log("Auth component...")
        if(this.props.location.pathname==='/login' || this.props.location.pathname==='/kstoreLanding' ||  this.props.location.pathname.startsWith('/report/') ){
            console.log("...user is not logged but alloweed url")
            return null;
        }else if(this.state.init.userIsLogged===false){
            console.log("...user is not logged - redirect to login")
            let from = window.location.pathname
            return (<Redirect to={"/login?from=" + from}></Redirect>)
        }else{
            console.log("...user is logged - return null")
            return null;
        }
    }

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Auth));