export const ADD_ARTICLE_TO_CART = "ADD_ARTICLE_TO_CART";

export const FETCH_SEARCH_PEND = "FETCH_SEARCH_PEND";
export const FETCH_SEARCH_SUCC = "FETCH_SEARCH_SUCC";
export const FETCH_SEARCH_FAIL = "FETCH_SEARCH_FAIL";
export const CLEAR_SEARCH_HISTORY = "CLEAR_SEARCH_HISTORY";

export const FETCH_AUTH_PEND = "FETCH_AUTH_PEND";
export const FETCH_AUTH_SUCC = "FETCH_AUTH_SUCC";
export const FETCH_AUTH_FAIL = "FETCH_AUTH_FAIL";

export const FETCH_CHECK_LOGIN = "FETCH_CHECK_LOGIN";
export const SET_LOGIN_MESSAGE = "SET_LOGIN_MESSAGE"

export const FETCH_CART_PEND = "FETCH_CART_PEND";
export const FETCH_CART_SUCC = "FETCH_CART_SUCC";
export const FETCH_CART_FAIL = "FETCH_CART_FAIL";

export const FETCH_URGENT_CART_PEND = "FETCH_URGENT_CART_PEND";
export const FETCH_URGENT_CART_SUCC = "FETCH_URGENT_CART_SUCC";
export const FETCH_URGENT_CART_FAIL = "FETCH_URGENT_CART_FAIL";


export const FETCH_UPDATE_QNT_RICH = "FETCH_UPDATE_QNT_RICH";
export const FETCH_UPDATE_QNT_RICH_PROMO = "FETCH_UPDATE_QNT_RICH_PROMO";

export const FETCH_UPDATE_NOTES = "FETCH_UPDATE_NOTES";

export const CART_NOTE_UPD = "CART_NOTE_UPD";
export const CART_RIF_UPD = "CART_RIF_UPD";

export const FETCH_SEND_ORDER_PEND = "FETCH_SEND_ORDER_PEND";
export const FETCH_SEND_ORDER_SUCC = "FETCH_SEND_ORDER_SUCC";
export const FETCH_SEND_ORDER_FAIL = "FETCH_SEND_ORDER_FAIL";
export const FETCH_SEND_ORDER_WARN = "FETCH_SEND_ORDER_WARN";

export const FETCH_ORDER_DETAIL_PEND = "FETCH_ORDER_DETAIL_PEND"
export const FETCH_ORDER_DETAIL_SUCC = "FETCH_ORDER_DETAIL_SUCC"
export const FETCH_ORDER_DETAIL_FAIL = "FETCH_ORDER_DETAIL_FAIL"
export const INVALIDATE_ORDER_LIST = "INVALIDATE_ORDER_LIST"

export const FETCH_ORDER_LIST_PEND = "FETCH_ORDER_LIST_PEND";
export const FETCH_ORDER_LIST_SUCC = "FETCH_ORDER_LIST_SUCC";
export const FETCH_ORDER_LIST_FAIL = "FETCH_ORDER_LIST_FAIL";

export const FIND_CROSS_REFERENCE_PEND = "FIND_CROSS_REFERENCE_PEND";
export const FIND_CROSS_REFERENCE_SUCC = "FIND_CROSS_REFERENCE_SUCC";
export const FIND_CROSS_REFERENCE_FAIL = "FIND_CROSS_REFERENCE_FAIL";
export const FIND_CROSS_REFERENCE_PROMO_PEND = "FIND_CROSS_REFERENCE_PROMO_PEND";
export const FIND_CROSS_REFERENCE_PROMO_SUCC = "FIND_CROSS_REFERENCE_PROMO_SUCC";
export const FIND_CROSS_REFERENCE_PROMO_FAIL = "FIND_CROSS_REFERENCE_PROMO_FAIL";


export const DELIVERY_DATE_PEND = "DELIVERY_DATE_PEND";
export const DELIVERY_DATE_SUCC = "DELIVERY_DATE_SUCC";
export const DELIVERY_DATE_FAIL = "DELIVERY_DATE_FAIL";

export const DELIVERY_DATE_PROMO_PEND = "DELIVERY_DATE_PROMO_PEND";
export const DELIVERY_DATE_PROMO_SUCC = "DELIVERY_DATE_PROMO_SUCC";
export const DELIVERY_DATE_PROMO_FAIL = "DELIVERY_DATE_PROMO_FAIL";

export const FETCH_LOGOUT_SUCC = "FETCH_LOGOUT_SUCC";

export const IAM_AVALAIBILITY_PEND = "IAM_AVALAIBILITY_PEND";
export const IAM_AVALAIBILITY_SUCC = "IAM_AVALAIBILITY_SUCC";
export const IAM_AVALAIBILITY_FAIL = "IAM_AVALAIBILITY_FAIL";
export const IAM_AVALAIBILITY_PROMO_SUCC = "IAM_AVALAIBILITY_PROMO_SUCC";
export const IAM_AVALAIBILITY_PROMO_FAIL = "IAM_AVALAIBILITY_PROMO_FAIL";

export const MISSING_PEND = "MISSING_PEND";
export const MISSING_SUCC = "MISSING_SUCC";
export const MISSING_FAIL = "MISSING_FAIL";

export const FETCH_BOLLE_PEND = "BOLLE_PEND";
export const FETCH_BOLLE_SUCC = "BOLLE_SUCC";
export const FETCH_BOLLE_FAIL = "BOLLE_FAIL";

export const FETCH_ORDINI_PEND = "FETCH_ORDINI_PEND";
export const FETCH_ORDINI_SUCC = "FETCH_ORDINI_SUCC";
export const FETCH_ORDINI_FAIL = "FETCH_ORDINI_FAIL";


export const FETCH_BOLLE_DETAIL_PEND = "FETCH_BOLLE_DETAIL_PEND";
export const FETCH_BOLLE_DETAIL_SUCC = "FETCH_BOLLE_DETAIL_SUCC";
export const FETCH_BOLLE_DETAIL_FAIL = "FETCH_BOLLE_DETAIL_FAIL";
export const FETCH_BOLLE_DETAIL_CLEAR = "FETCH_BOLLE_DETAIL_CLEAR";

export const FETCH_FATT_SUCC = "FETCH_FATT_SUCC";
export const FETCH_FATT_PEND = "FETCH_FATT_PEND";
export const FETCH_FATT_FAIL = "FETCH_FATT_FAIL";

export const SAVE_NOTE_CLIENTE = "SAVE_NOTE_CLIENTE";

export const FETCH_USER_PROMO_PEND = "FETCH_USER_PROMO_PEND";
export const FETCH_USER_PROMO_SUCC = "FETCH_USER_PROMO_SUCC";
export const FETCH_USER_PROMO_FAIL = "FETCH_USER_PROMO_FAIL";

export const FETCH_PROMO_DETAIL_PEND = "FETCH_PROMO_DETAIL_PEND";
export const FETCH_PROMO_DETAIL_SUCC = "FETCH_PROMO_DETAIL_SUCC";
export const FETCH_PROMO_DETAIL_FAIL = "FETCH_PROMO_DETAIL_FAIL";
export const FETCH_BOLLE_CLEAR = "FETCH_BOLLE_CLEAR";

export const FETCH_INIT_SUCC = "FETCH_INIT_SUCC";

export const FADE_OUT_PRODUCT = "FADE_OUT_PRODUCT";
export const REMOVE_PRODUCT_FROM_SEARCH = "REMOVE_PRODUCT_FROM_SEARCH";

export const RESI_PEND = "RESI_PEND";
export const RESI_SUCC = "RESI_SUCC";
export const RESI_FAIL = "RESI_FAIL";

export const RESI_REQ_PEND = "RESI_REQ_PEND";
export const RESI_REQ_SUCC = "RESI_REQ_SUCC";
export const RESI_REQ_FAIL = "RESI_REQ_FAIL";

export const FETCH_BEHALF_PEND = "FETCH_BEHALF_PEND"; 
export const FETCH_BEHALF_SUCC = "FETCH_BEHALF_SUCC";
export const FETCH_BEHALF_FAIL = "FETCH_BEHALF_FAIL";
export const FETCH_BEHALF_EXIT_SUCC = "FETCH_BEHALF_EXIT_SUCC"

export const FETCH_ALTERNATIVE_SUCC = "FETCH_ALTERNATIVE_SUCC";
export const FETCH_ALTERNATIVE_ERR = "FETCH_ALTERNATIVE_ERR";

export const FETCH_ALL_PROMO_SUCC = "FETCH_ALL_PROMO_SUCC";
export const FETCH_ALL_PROMO_PEND = "FETCH_ALL_PROMO_PEND";
export const FETCH_ALL_PROMO_FAIL= "FETCH_ALL_PROMO_FAIL";