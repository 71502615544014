import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip, message, Card, Avatar, Row, Col } from 'antd';
import { fetchSearch, fetchAllPromo } from '../../actions/actions.js'
import { connect } from "react-redux";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import Promo from '../Promo/promo.js'
import { withRouter } from 'react-router-dom'
import { getApiUrl } from '../../utils.js'
import './promoconsole.css';
import { T } from 'antd/lib/upload/utils';

const { Meta } = Card;


function mapDispatchToProps(dispatch) {
    return {
        fetchSearch: (stringToSearch) => dispatch(fetchSearch(stringToSearch)),
        fetchAllPromo: () => dispatch(fetchAllPromo())
    };
}

class PromoConsole extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            promo: [],

        }

        this.onPromoEditorClick = this.onPromoEditorClick.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onPromoEditorClick() {
        this.props.history.push("/promoEditor/");
    }

    componentDidMount() {
        let url = this.state.url;

        fetch(getApiUrl() + '/promo/getPromoList', {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                this.setState({
                    ...this.state,
                    promo: res.data
                })
            })
            .catch(error => {
                message.error("C'è stato un problema nel recuperare le promo");
            })
    }
componentDidUpdate(){
    this.props.fetchAllPromo()
}
    onChange(e) {

        let url = '/promo/getPromoList'
        
        if (e.target.checked == true) {

            url += '?filterPromoActive=false'
           

        }
        
        fetch(getApiUrl() + url, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        }).then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                
                this.setState({
                    ...this.state,
                    promo: res.data

                })
                console.log(this.state.promo)
            })
            .catch(error => {
                message.error("C'è stato un problema nel recuperare le promo");
            })

    }

    render() {
        let onChange = this.onChange
        let that = this;
        let data = this.state.promo || []
        console.log(data)
        return (
            <div className="site-card-wrapper">
                <table cellpadding='10' >
                    <tr>
                        <td valign='middle'><Button onClick={() => { that.onPromoEditorClick() }} style={{ marginBottom: "2em" }}>
                            Aggiungi Nuova Promo
                        </Button></td>
                        <td valign='top'><Checkbox onChange={onChange}><b>Mostra/Nascondi promozioni scadute</b></Checkbox></td>
                    </tr>
                </table>
                <Row  gutter={[32, 32]} >
                    
                    {data.map((promo, index) => {
                        return (
                            <Col key={promo.proId} xs={12} sm={12} md={8}  lg={6} xl={6} >
                               <Promo promo={promo} admin={true} />
                            </Col>
                        )
                    })}
                </Row>
            </div>
        );
    }
}


export default withRouter(connect(null, mapDispatchToProps)(PromoConsole));