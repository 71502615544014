import React from 'react';
import {withRouter} from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typography } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import {getApiUrl} from '../../utils.js'
import {fetchCart} from "../../actions/actions.js"
import { connect } from "react-redux";

function mapDispatchToProps(dispatch) {
  return {
      fetchCart : () => dispatch(fetchCart()),
  };
}
class LoadFile extends React.Component {

    constructor(props) {
        super(props);


        let initialState = {
          mode : "CSV",
          ris : {
            startInst : null,
            endInst: null,
            running : false,
            resultCarts : null,
          }
        }

        /*
         * LOCAL STATE
        */
        this.state = {
          mode : "CSV",
          ris : {
            startInst : null,
            endInst: null,
            running : false,
            resultCarts : [],
          }
        }

        this.onChangeMode = this.onChangeMode.bind(this);
        this.setResults = this.setResults.bind(this);
        this.initLoad = this.initLoad.bind(this);
        this.getStatus = this.getStatus.bind(this);

    }

    componentDidMount() {
      this.getStatus();

      this.interval = setInterval(() => {
        this.getStatus();
      }, 10000);
    }

    componentWillUnmount(){
      clearInterval(this.interval);
    }

    getStatus(){

      let fetchCartLocal = this.props.fetchCart;

      fetch(getApiUrl() + "/cart/addFromCSVStatus/" ,{
        method: 'GET', 
        cache: 'no-cache',
        credentials: 'include',
        headers: new Headers({
            "Accept" : 'application/json'
        }),
      })
      .then(res => res.json())
      .then(res => {
      if(res.error) {
        throw(res);
      }
      else{
        
        if(res.data!==undefined && res.data !== null){
          this.setState({
            ...this.state,
            ris : res.data
          }, ()=> { console.log("State after getStatus..."); console.log(this.state) }  )
        }

        //Update Carts
        if(res.data!==undefined && res.data.running!==undefined && res.data.running===false){
           fetchCartLocal()
        }

      }
    })
    .catch(res => {
      message.error("Errore nel recuperare lo stato degli upload" + res) 
    })


    }

    onChangeMode(ev){
      this.setState({
        ...this.state,
        mode : ev.target.value
      })
    }

    initLoad(){

      /*
      this.setState({
        ...this.state,
        ris : null,
      })
      */

    }

    setResults(ris){

      if(ris===undefined || ris===null){
        return
      }

      this.setState({
        ...this.state,
        ris : ris
      }, ()=> { console.log("State after setResults..."); console.log(this.state) } )
    }

    showCarts() {
      this.props.history.push('/cart')
    }
    
    render() {

        const { Dragger } = Upload;
        let mode = this.state.mode

        let state = this.state;
        let setResults = this.setResults;
        let initLoad = this.initLoad;

        const props = {
            name: 'file',
            multiple: false,
            customRequest: function(obj) {
              
              initLoad();

              console.log(obj);

              var formData  = new FormData();
              //formData.append("file", obj.data);
              formData.append("file", obj.file);

              fetch(getApiUrl() + "/cart/addFromCSV" + "?mode=" + mode, {
                    method: 'POST', 
                    cache: 'no-cache',
                    credentials: 'include',
                    headers: new Headers({
                        "Accept" : 'application/json'
                    }),
                    body : formData
              })
              .then(res => res.json())
              .then(res => {
                setResults(res);
                obj.onSuccess(res)
              })
              .catch(err => {
                obj.onError(err)
              });
            },
            
            onChange: function(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
          };


         let config = {
            onChangeQntRich : null,
            onAddToChart : null,
            onFindCrossReference : null,
            onDeliveryDate : null,
            onNoteClick : null
         };
        
        let dtc = new DataColumnUtil();
        let columns = dtc.createProductTableCol(config);  
        const ImgCartDetail = () => (
          <img style={{width:"2.2em",marginTop:"-5px"}} src="icon/icons8-carrello-della-spesa-48-tratto-4.png"></img>
        );
        const IconCartDetail = props => <Icon component={ImgCartDetail} {...props} />;
        
        const { Title } = Typography;

        return (
            <div>
                
                <h1>Formato del file</h1>
                <Radio.Group onChange={this.onChangeMode} value={this.state.mode}>
                  <Radio value={"CSV"}>CSV con separatore virgola</Radio>
                  <Radio value={"SEMICOLON"}>CSV con separatore punto e virgola</Radio>
                  {/*
                  <Radio value={"TXT_FROM_OF"}>Export Infinity Ordine Fornitore</Radio>
                  */}
                </Radio.Group>

                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <h1>Seleziona o trascina un file che contiene i codici e le quantità da caricare per importare il suo contenuto nel carrello</h1>
                    <h1>Il file deve contenere su ogni riga il codice e la quantità</h1>
                </Dragger>

                {/* LOADING IN PROGRESS */}
                { this.state.ris.startInst !==undefined && this.state.ris.startInst !== null && this.state.ris.running === true && (
                  <div>
                  <Title level={4}> E' ancora in corso il carimento del file iniziato il {this.state.ris.startInst}. Al completato del caricamento del file, potrai visualizzare qui sotto un riepilogo dei prodotti caricati. </Title>
                  </div>
                ) 
                }

                {/* LAST LOADINF */}
                { (this.state.ris.running === false && this.state.ris.resultCarts || []).map( c =>  
                    (
                    <div>
                    <br />
                    <Title level={4}> Ultimo caricamento completato il {this.state.ris.endInst}. Segue riepilogo dei prodotti caricati: </Title>
                     <p>Carrello {c.cId} </p>
                     <Table columns={columns} dataSource={c.productsList} />
                  </div>
                  )                
                )}

            </div>
        )
    }

}

export default withRouter(connect(null,mapDispatchToProps)(LoadFile));