import React from 'react'; 
import {Divider, DatePicker, Form, Input, Button} from 'antd'
import { Link } from "react-router-dom";

class ResiTemp extends React.Component {



    render() {
        return(
        <div>
            <h1>La funzionalità per i resi è in costruzione</h1>
            <p>Stiamo lavorando per crearla in modo da dare un quadro preciso su tutto il processo dei resi, dalla richiesta al rimborso.</p>

            <p>
                Al momento per richiedere un reso inviare una mail a <b>mag@sirmec.eu</b> specificando i dati della bolla di acquisto e la motivazione del reso
            </p>

            <Link to="/nuovoReso">.</Link> &nbsp; <Link to="/richiesteReso">.</Link>

        </div>
        )
    }
}

export default ResiTemp;