import React from 'react';
import { connect } from "react-redux";
import { Col, Row, message, Spin, Table } from 'antd';
import { Select } from 'antd';
import { InputNumber, Input } from 'antd';
import { Form } from 'antd';
import { Button } from 'antd';
import {getApiUrl} from '../../utils.js'
import {notification, Typography } from 'antd';

const { Option } = Select;

class StockCheck extends React.Component {

    constructor(props) {
            
        super(props);

        this.state = {
            deposito: "1",
            giorniVenduto: 365,
            waiting : false,
            qntaVendutaGreaterOrEqualTo: "",
            dayOfStock: "",
            
            config : [

                {
                    qntaVendutaGreaterOrEqualTo: 4,
                    dayOfStock : 90
                },
                {
                    qntaVendutaGreaterOrEqualTo: 5,
                    dayOfStock : 70
                },
                {
                    qntaVendutaGreaterOrEqualTo: 6,
                    dayOfStock : 60
                },
                {
                    qntaVendutaGreaterOrEqualTo: 7,
                    dayOfStock : 50
                },
                {
                    qntaVendutaGreaterOrEqualTo: 8,
                    dayOfStock : 45
                },
            ]

        } 

        this.onChangeDep = this.onChangeDep.bind(this)
        this.onChangeDaySold = this.onChangeDaySold.bind(this)
        this.onChangeDayStock = this.onChangeDayStock.bind(this)
        this.getStockReport = this.getStockReport.bind(this)
        this.deleteConfig = this.deleteConfig.bind(this)
        this.addConfig = this.addConfig.bind(this)
        this.onChangeQntaVendutaGreaterOrEqualTo = this.onChangeQntaVendutaGreaterOrEqualTo.bind(this)
        this.onChangeDayOfStock = this.onChangeDayOfStock.bind(this)
    }


    onChangeQntaVendutaGreaterOrEqualTo(value){
        this.setState({
            ...this.state,
            qntaVendutaGreaterOrEqualTo:value
        })
    }

    onChangeDayOfStock(value){
        this.setState({
            ...this.state,
            dayOfStock:value
        })
    }

    deleteConfig(index){
        let newConfig = [...this.state.config]
        newConfig.splice(index,1);

        this.setState({
            ...this.state,
            config : newConfig
        })
    }

    addConfig(){

        let el = {
            qntaVendutaGreaterOrEqualTo: this.state.qntaVendutaGreaterOrEqualTo,
            dayOfStock : this.state.dayOfStock
        };

        let newConfig = [...this.state.config]
        newConfig.push(el);

        this.setState({
            ...this.state,
            config : newConfig
        })



    }

    componentDidMount() {
                
    }

    onChangeDep(e){
        this.setState({
            ...this.state,
            deposito: e
        })
    }

    onChangeDaySold(e){
        this.setState({
            ...this.state,
            giorniVenduto: e
        })
    }

    onChangeDayStock(e){
        this.setState({
            ...this.state,
            giorniStock: e
        })
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getStockReport(){

        this.setState({
            ...this.state,
            waiting:true
        }, () => {

        fetch(getApiUrl() + "/admin/getStock?daySold=" + this.state.giorniVenduto + "&dep=" + this.state.deposito + "&dayStock=" +this.state.giorniStock   ,{
            method: 'POST', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'text/plain',
                "Content-Type": 'application/json'
            }),
            body: JSON.stringify(this.state.config)
        })
        .then((response) => response.blob())
        .then((blob) => {

           console.log("Received response")
           console.log(blob)

           // Create blob link to download
           const url = window.URL.createObjectURL(
             new Blob([blob]),
           );
           const link = document.createElement('a');
           link.href = url;

           var d = new Date();
           let reportname =    "stock_report_" + d.getFullYear() + "_" + (d.getMonth()+1) + "_" + d.getDate() + "_" + d.getHours() + d.getMinutes() + ".csv"

           link.setAttribute( 'download', reportname,);
          
           // Append to html link element page
           document.body.appendChild(link);
          
           // Start download
           link.click();
          
           // Clean up and remove the link
           link.parentNode.removeChild(link);

           this.setState({
            ...this.state,
            waiting:false
        });
        })
        .catch(error => {

            let config = {
                message: 'Errore',
                description: 'Errore nel recuperare il report',
            };
            notification.success(config)
        })
    }
        )
    }

    render() {

        const { Title } = Typography;

        let that = this;
        const columns = [
            {
              title: 'Qnt Venduta Maggiore o Uguale di',
              dataIndex: 'qntaVendutaGreaterOrEqualTo',
              key: 'qntaVendutaGreaterOrEqualTo',
            },
            {
              title: 'Giorni di stock',
              dataIndex: 'dayOfStock',
              key: 'dayOfStock',
            },
            {
                title: 'Remove',
                dataIndex: 'remove',
                key: 'dayOfStock',            
                render: function (text, record, index){
                    return (<Button onClick={()=>that.deleteConfig(index)}>Elimina Riga</Button>)
                }

            }
        ];


        return (
            <div className="site-layout-background" style={{minHeight: 380 }}>
               
               <p>
                   Questa funzionalità analizza il venduto in una finestra temporale mobile utilizzando il parametro <b>Giorni di venduto da analizzare</b> &nbsp;
                   e restituisce i livelli di stock attuali e le quantità da acquistare per allineare lo stock ai parametri impostati nella <b>configurazione</b>
               </p>
               
               
               <Form name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ giorni: 365, deposito: "Senise"}}
                /*onFinish={onFinish}
                onFinishFailed={onFinishFailed}*/>

                <Form.Item
                    label="Seleziona il deposito"
                    name="deposito"
                    rules={[{ required: true, message: 'Per favore seleziona il deposito' }]}
                >
                    <Select defaultValue={this.state.deposito} style={{ width: "95%", marginBottom:"1em" }} onChange={this.onChangeDep} >
                        <Option value="1">Senise</Option>
                        <Option value="3">Cagliari</Option>
                     </Select>
                </Form.Item>

                <Form.Item
                    label="Giorni di venduto da analizzare"
                    name="giorni"
                    defaultValue={365}
                    rules={[{ required: true, message: 'Per favore inserisci i giorni da analizzare' }]}
                >
                    <InputNumber min={60} max={365} value={this.state.giorni} onChange={this.onChangeDaySold} />
                </Form.Item>

                {/*
                <Form.Item
                    label="Giorni di stock"
                    name="giorni_stock"
                    defaultValue={30}
                    rules={[{ required: true, message: 'Per favore inserisci i giorni da analizzare' }]}
                >
                    <InputNumber min={30} max={365} value={this.state.giorni} onChange={this.onChangeDayStock} />
                </Form.Item>
                */}

                </Form>

                <Title level={4}>Configurazione</Title>
                
                <Form>
                <Form.Item label="Stock">
                    <Input.Group compact>
                        <Form.Item label="Quantità vendute maggiore o uguale a:">
                            <InputNumber min={1} max={10000} style={{ width: '50%' }} placeholder="1" value={this.state.qntaVendutaGreaterOrEqualTo} onChange={this.onChangeQntaVendutaGreaterOrEqualTo} />
                        </Form.Item>

                        <Form.Item label="Allinea lo stock giorni:" name={['address', 'street']} >
                            <InputNumber min={1} max={365} style={{ width: '50%' }} placeholder="30" value={this.state.dayOfStock}  onChange={this.onChangeDayOfStock} />
                        </Form.Item>
                        
                        <Button onClick={()=>this.addConfig()}>INSERISCI RIGA</Button>
                    </Input.Group>
                </Form.Item>
                

                <Table columns={columns} dataSource={this.state.config} />




                <Button loading={this.state.waiting} disabled={this.state.waiting} type="primary" onClick={this.getStockReport} >Genera Report</Button>

                { this.state.waiting && (
                <Spin style={{marginLeft:"10%", paddingTop:"2em"}} size="large" tip="Generazione Report in corso..." /> 
                )
                }
                </Form>

                
            </div>
        )
    }
}
export default (StockCheck);