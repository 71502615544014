import React from 'react';
import {withRouter} from 'react-router-dom'
import { connect } from "react-redux";
import { Form, Input, Button, Checkbox, message, InputNumber  } from 'antd';
import { Select } from 'antd';
import {getApiUrl} from '../../utils.js'

const { Option } = Select;

const mapStateToProps = state => {
    return { 
        userPromo : state.userPromo,
    };
};

class PromoEditor extends React.Component {

    constructor(props) {

        super(props);

        this.initializeState();

        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onDepoChange = this.onDepoChange.bind(this);

        this.fetchPromoType = this.fetchPromoType.bind(this);
        this.createPromo = this.createPromo.bind(this);
        this.updatePromo = this.updatePromo.bind(this);
        this.initializeState = this.initializeState.bind(this);

        /*
          FORM EVENT
        */
       this.onDepoChange = this.onDepoChange.bind(this);
       this.onChangePromoType = this.onChangePromoType.bind(this);
       this.onChangeStartDate = this.onChangeStartDate.bind(this);
       this.onChangeEndDate = this.onChangeEndDate.bind(this);
       this.onChangePromoImgUrl = this.onChangePromoImgUrl.bind(this);
       this.onChangePromoVideoUrl = this.onChangePromoVideoUrl.bind(this);
       this.onChangeCoverImgUrl = this.onChangeCoverImgUrl.bind(this);
       this.onChangeTitle = this.onChangeTitle.bind(this);
       this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
       this.onChangePromoText = this.onChangePromoText.bind(this);
       this.onChangePromoOrder = this.onChangePromoOrder.bind(this);
       this.onChangePromoPopup = this.onChangePromoPopup.bind(this);
       this.onChangeProduct = this.onChangeProduct.bind(this);
       
  
    }


    initializeState(){

        let editMode = false;
        let promoProductsString =""
        let promoProducts = []
        let proImgUrl= []
        let proImgUrlString = ""
        let proTypePreset = ""
        let proTypePresetId=""
        let proDepRifString = ""
        let proDepRifStringPresetCheck = []
        let proId = ""
        let proVideoUrl= []
        let proVideoUrlString = ""

        /*
         * INITIALIZE EDIT  MODE 
        */
        if(this.props.promo!==undefined && this.props.promo.promoProducts!==undefined){
          
          editMode = true;

          proId = this.props.promo.proId

          /* Edit mode: initialize products*/
          this.props.promo.promoProducts.map((item, index)=>{
            if(index===0){
              promoProductsString+=item.precodice + ";" + item.codice;
            }else{
              promoProductsString+="\n" + item.precodice + ";" + item.codice;
            }

            let p = {
              precodice: item.precodice,
              codice : item.codice
            }
            promoProducts.push(p);
         });

         proImgUrl = this.props.promo.proImgUrl || []
         proVideoUrl = this.props.promo.proVideoUrl || []

         /* Edit mode: initialize Images */
         this.props.promo.proImgUrl.map((item, index)=>{
          if(index===0){
            proImgUrlString+=item;
          }else{
            proImgUrlString+="\n" + item;
          }
         });

         /* Edit mode: initialize Video */
         this.props.promo.proVideoUrl.map((item, index)=>{
          if(index===0){
            proVideoUrlString+=item;
          }else{
            proVideoUrlString+="\n" + item;
          }
         });
       
        proTypePreset = this.props.promo.proTypeDesc;
        proTypePresetId =  this.props.promo.proType
        proDepRifString = this.props.promo.proDepRifString;
        
        if(proDepRifString.includes("01")){
          proDepRifStringPresetCheck.push("01");
        }
        if(proDepRifString.includes("03")){
          proDepRifStringPresetCheck.push("03");
        }

        }

        this.state = {
          depoSelected : "",
          promoType: null,
          editMode : editMode,
          form : {
            proId : proId,
            proDepRifStringPresetCheck :  proDepRifStringPresetCheck,  //selected depo for checkbox
            proDepRifString: proDepRifString,                    //selected depo string ex. (01+03)
            proType: proTypePresetId,       //selected promo (id)
            proTypeDesc:    proTypePreset,  //selected promo (desc)
            proBannerLink: (this.props.promo!== undefined && this.props.promo.proBannerLink !==undefined) ?  this.props.promo.proBannerLink : "",
            proStartInst:  (this.props.promo!== undefined && this.props.promo.proStartInst  !==undefined) ? this.props.promo.proStartInst :  "",
            proStartEnd:   (this.props.promo!== undefined && this.props.promo.proStartEnd   !==undefined) ? this.props.promo.proStartEnd  :  "",
            proTitle:      (this.props.promo!== undefined && this.props.promo.proTitle      !==undefined) ? this.props.promo.proTitle : "",
            proSubtitle:   (this.props.promo!== undefined && this.props.promo.proSubtitle   !==undefined) ? this.props.promo.proSubtitle : "",
            proText:       (this.props.promo!== undefined && this.props.promo.proText       !==undefined) ? this.props.promo.proText : "",
            proOrder:      (this.props.promo!== undefined && this.props.promo.proOrder      !==undefined) ? this.props.promo.proOrder : 1,
            proPopup:      (this.props.promo!== undefined && this.props.promo.proPopup      !==undefined) ? this.props.promo.proPopup : 0,            
            proArea:       "Pannello Sup",
            proImgUrl :    proImgUrl,
            proImgUrlString : proImgUrlString,
            proVideoUrl : proVideoUrl,
            proVideoUrlString : proVideoUrlString,
            promoProducts: promoProducts,
            promoProductsString : promoProductsString
          }
        }
    }

    createPromo(){
      console.log(this.state.form);

      let arr = this.state.form.promoProductsString.split('\n');
      
      let productList = []

      arr.forEach(function(value,index){
        let parts = value.split(";");
        let p = {
          "precodice": parts[0],
          "codice" : parts[1],
        }
        productList.push(p);
      })

      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          promoProducts: productList
        }
      }, function(){

        fetch(getApiUrl() + "/promo/addPromo", {
          credentials: "include",
          method: "POST",
          headers: new Headers({
              "Accept" : 'application/json',
              "Content-Type" : "application/json"
          }),
          body: JSON.stringify(this.state.form)
        })
        .then(res => res.json())
        .then(res => {
          if(res.error) {
              throw(res.error);
          }else{
            message.success("Promo creata con successo");  
          }
        })
        .catch(error => {
          message.error("C'è stato un problema nel recuperare le promo");
        })

      })
    }

    updatePromo(){
      console.log("Update promo with form data:")
      console.log(this.state.form);

      let arr = this.state.form.promoProductsString.split('\n');
      
      let productList = []

      arr.forEach(function(value,index){
        let parts = value.split(";");
        let p = {
          "precodice": parts[0],
          "codice" : parts[1],
        }
        productList.push(p);
      })

      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          promoProducts: productList
        }
      }, function(){

      fetch(getApiUrl() + "/promo/updatePromo?proId=" + this.state.form.proId, {
        credentials: "include",
        method: "PUT",
        headers: new Headers({
            "Accept" : 'application/json',
            "Content-Type" : "application/json"
        }),
        body: JSON.stringify(this.state.form)
      })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
            throw(res.error);
        }else{
          message.success("Promo aggiornata con successo");  
        }
      })
      .catch(error => {
        message.error("C'è stato un problema nell'aggiornare la promo");
      })

    })
    }

    fetchPromoType(){

      fetch(getApiUrl() + "/promo/getPromoType", {
        credentials: "include",
        method: "GET",
        headers: new Headers({
            "Accept" : 'application/json'
        })
      })
      .then(res => res.json())
      .then(res => {
        if(res.error) {
            throw(res.error);
        }
        this.setState({
            ...this.state,
            promoType : res.data
        })
      })
      .catch(error => {
        message.error("C'è stato un problema nel recuperare le promo");
      })
    }
    componentDidMount() {
      this.fetchPromoType();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {     
    }

    onDepoChange(values){

      let proDepRifString = "";
      let it = 0;
      values.forEach(function (arrayItem) {

        if(it>0){
          proDepRifString += "+"
        }
        proDepRifString  += arrayItem
        it++;
      });

      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proDepRifString: proDepRifString
        }
      })
    }
    onChangePromoType(value){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proType: value
        }
      })
    }
    onChangeStartDate(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proStartInst: ev.target.value
        }
      })
    }
    onChangeEndDate(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proStartEnd: ev.target.value
        }
      })
    }
    onChangeCoverImgUrl(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proBannerLink: ev.target.value
        }
      })
    }
    onChangePromoImgUrl(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proImgUrlString: ev.target.value,
          proImgUrl: ev.target.value.split("\n")
        }
      })
    }
    onChangeTitle(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proTitle: ev.target.value
        }
      })
    }
    onChangeSubtitle(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proSubtitle: ev.target.value
        }
      })
    }
    onChangePromoText(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proText: ev.target.value
        }
      })
    }
    onChangePromoOrder(value){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proOrder: value
        }
      })
    }
    onChangePromoPopup(value){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proPopup: value
        }
      })
    }
    onChangeProduct(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          promoProductsString: ev.target.value
        }
      })
    }

    onChangePromoVideoUrl(ev){
      this.setState({
        ...this.state,
        form : {
          ...this.state.form,
          proVideoUrlString: ev.target.value,
          proVideoUrl: ev.target.value.split("\n")
        }
      })
    }


    onBlur() {
        console.log('blur');
      }    
    onFocus() {
       console.log('focus');
      }  
    onSearch(val) {
        console.log('search:', val);
    }

    render() {    
        
        let depos=[
          {
            label: 'Senise[01]', value: '01', 
          },
          {
            label: 'Cagliari[03]', value: '03', 
          }
        ]

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
        
        const { TextArea } = Input;
        
        return (
        <Form {...layout} >
            <h2>Seleziona i depositi di riferimento per la promo</h2>
            <Checkbox.Group options={depos}  onChange={this.onDepoChange}  defaultValue={this.state.form.proDepRifStringPresetCheck} />   
            
            <br /><br />

            <h2>Seleziona il tipo di promo</h2>
            <Select showSearch style={{ width: 200 }} placeholder="Seleziona il tipo di promo" 
                defaultValue={this.state.form.proTypeDesc}
                onChange={this.onChangePromoType}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
            {(this.state.promoType || []).map((value, index) => {
                return <Option key={index} value={value.proTypeId}>{value.proTypeDesc}</Option>
            })}
            
        </Select>

        <br /><br />
        
        <h2>Data Inizio della promo [formato AAAA-MM-GG]</h2>
        <Input value={this.state.form.proStartInst} placeholder="" onChange={this.onChangeStartDate} />
        <br /><br />

        <h2>Data fine della promo [formato AAAA-MM-GG]</h2>
        <Input value={this.state.form.proStartEnd} placeholder="" onChange={this.onChangeEndDate} />
        <br /><br />

        <h2>Immagine di copertina</h2>
        <Input value={this.state.form.proBannerLink} onChange={this.onChangeCoverImgUrl} placeholder="http://example.com/abcdef1" />
        <br /><br />
          
        <h2>Altre immagini</h2>
        <p>Inserisci un link per ogni riga</p>
        <TextArea value={this.state.form.proImgUrlString} onChange={this.onChangePromoImgUrl} rows={5} />

        <h2>Video</h2>
        <p>Inserisci un url per ogni riga</p>
        <TextArea value={this.state.form.proVideoUrlString} onChange={this.onChangePromoVideoUrl} rows={5} />


        <h2>Titolo</h2>
        <Input value={this.state.form.proTitle} onChange={this.onChangeTitle} placeholder="TITOLO DELLA PROMO" />
        <br /><br />

        <h2>Sottotitolo</h2>
        <Input value={this.state.form.proSubtitle} onChange={this.onChangeSubtitle} placeholder="SOTTOTITOLO" />
        <br /><br />

        <h2>Testo</h2>
        <TextArea value={this.state.form.proText} onChange={this.onChangePromoText} rows={5} placeholder="TESTO DELLA PROMO" />
        <br /><br />
        
        <h2>Posizione (Ordine)</h2>
        <InputNumber min={1} max={1000} placeholder="" value={this.state.form.proOrder} defaultValue="1" onChange={this.onChangePromoOrder} />
        <br /><br />

        <h2>Notizia Importante</h2>
        <p>Se questo campo contiene un numero maggiore di zero, verrà visualizzato un messaggio bloccante all'utente fino all'apertura della Promo</p>
        <InputNumber min={0} max={10} defaultValue="0" value={this.state.form.proPopup} onChange={this.onChangePromoPopup} />
        <br /><br />

        <h2>Codici in promo</h2>
        <p>Inserisci un codice per ogni riga nel formato: PRECODICE;CODICE A 10 CIFRE</p>
        <TextArea value={this.state.form.promoProductsString} onChange={this.onChangeProduct} rows={20} />
        
        {this.state.editMode === false && (
        <Button style={{marginTop:"2em", marginBottom:"2em"}} type="primary" size={"large"} onClick={this.createPromo}>Crea Promo</Button>
        )}

        {this.state.editMode === true && (
          <Button style={{marginTop:"2em", marginBottom:"2em"}} type="primary" size={"large"} onClick={this.updatePromo}>Aggiorna Promo</Button>
        )}

        </Form>

        )
    }

}

export default withRouter(connect(mapStateToProps,null)(PromoEditor));
