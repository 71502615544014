import { Form, InputNumber, message, Table } from "antd"
import FormItem from "antd/lib/form/FormItem"
import React from "react"
import { getApiUrl } from "../../utils"

class CustomerInfoTable extends React.Component {

    //inizializzazione array customersInfo
    constructor(props) {
        super(props)
        this.state = {
            customerInfoArray: [],
        }
    }

    componentDidMount(){
        let getCustomerInfoEndpoint = getApiUrl()+"/registration/getCustomerInfo";
        fetch(getCustomerInfoEndpoint, {
            credentials : "include",
            method : "GET",
            headers : new Headers({
                "Accept": "application/json",
                
            })
        }).then(res => res.json()).then( res =>{
            if(res.error){
               
                throw (res.error)
               
            }else{
                this.setState({
                    ...this.state, 
                    customerInfoArray : res.data
                })
                    message.success("Aggiornata la tabella con i profili dei clienti")
                
            }
        }
        ).catch(error=>{
            message.error("C'è stato un problema nel recuperare i dati dei clienti" + error)
        }
        )
    }

    render() {
        let customerColumns = [
            { title: 'Ragione Sociale', fixed: 'left', dataIndex: 'businessName', key: 'businessName' },
            { title: 'Id.Cliente', dataIndex: 'uIdCliente', key: 'uIdCliente', width: 100 },
            { title: 'Accettatori', dataIndex: 'cNumAccettatori', key: 'cNumAccettatori', width: 100 },
            { title: 'Meccanici-Carrozzieri', dataIndex: 'cNumTecnici', key: 'cNumTecnici', width: 100 },
            { title: 'Ponti Officina', dataIndex: 'cNumPonti', key: 'cNumPonti', width: 100 },
            { title: 'Marca Olio', dataIndex: 'cMarcaOlio', key: 'cMarcaOlio', width: 100 },
            {
                title: 'Servizio Gomme', dataIndex: 'cServizioGomme', key: 'cServizioGomme', width: 100,
                render: cServizioGomme => {
                    if (cServizioGomme) {
                        return "SI"
                    } else {
                        return "NO"
                    }
                }
            },
            {title: 'Modifica Info Cliente'}
        ]
        
        let dataSource = this.state.customerInfoArray;

        


        return (
            <Table columns ={customerColumns} dataSource={dataSource}>
            
            </Table>
            )
    }

}
export default CustomerInfoTable