var formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  export function moneyFormat(v){
    return formatter.format(v); 
  };

  export function discountFormat(v){

    if(v===undefined || v===null){
      return v
    }else {
      /* Change to string in order to use replace func*/
      v = " " + v
    }

    let noZero = v.replace(/\.00/g, '')

    let italian = noZero.replace(/\./g, ',')
    return italian
  }

  //find elements in listOfProduct and in all children
  export function updateProductList(listOfProduct, precodice, codice, prop){

    /*
        {
          quantitaRichiesta: number
          deliveryTimeSearchIsPending: true/false
        }
    */


    return (listOfProduct || []).map(function(value){

      if(value.precodice === precodice && value.codice === codice){

        if(prop.quantitaRichiesta!==null && prop.quantitaRichiesta!==undefined){
          value.qntRichiesta = prop.quantitaRichiesta
        }

        if(prop.deliveryTimeSearchIsPending!==null && prop.deliveryTimeSearchIsPending!== undefined){
          value.deliveryTimeSearchIsPending=prop.deliveryTimeSearchIsPending;
        }

        if(prop.message !== null && prop.message !== undefined){
          value.message = prop.message;
        }

        if(prop.IAMAvalaibilityPending !== null && prop.IAMAvalaibilityPending !== undefined){
          value.IAMAvalaibilityPending = prop.IAMAvalaibilityPending;
        }

        if(prop.avalaibility !== null && prop.avalaibility !== undefined){
          value.disp = prop.avalaibility.totalOrderable;

          /* 
           * set-up information about wharehouse
           */
          value.availabilityWarehouse = prop.avalaibility.availabilityWarehouse
          value.primaryWarehouse = prop.avalaibility.primaryWarehouse

        }

        if(prop.noteCliente !== null && prop.noteCliente !== undefined){
          value.noteCliente = prop.noteCliente
        }

        if(prop.animationClass !== null && prop.animationClass !== undefined){
          value.animationClass = prop.animationClass
        }
        
      }
      
      if(value.alternative!==undefined && value.alternative!==null){
        let altRis = updateProductList(value.alternative, precodice, codice, prop);
        value.alternative=altRis;
      }

      //suggested can be a list
      if(prop.suggested!==undefined && prop.suggested!==null){
        value.suggested = prop.suggested
      }

      return value;
   });
 }

 export function deleteFromProductList(listOfProduct, precodice, codice){

  return (listOfProduct || []).filter(function(value){
    if(value.precodice === precodice && value.codice === codice){
      return false;
    }else {
      return true;
    }
 });
}


 export function getApiUrl(){

  if(window.location.href.toLowerCase().startsWith(process.env.REACT_APP_API_URL_HOST_BCK)){
     return process.env.REACT_APP_API_URL_BCK
  }else {
     //return first as default
     return process.env.REACT_APP_API_URL
  }
}
