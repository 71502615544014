import React from 'react';
import { withRouter } from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typography } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import { getApiUrl } from '../../utils.js'
import { connect } from "react-redux";
import { Tabs } from 'antd';
import { Collapse } from 'antd';
import reducers from 'video-react/lib/reducers';



class LoadTariffario extends React.Component {

    constructor(props) {
        super(props)

        /* localState */

        this.state = {
            notParsed: [],
            adList: [],                     
            disabled: true
        }

        this.setResults = this.setResults.bind(this)

    }

    setResults(data) {
        this.setState({
            ...this.state,
            adList: data.adList,
            notParsed: data.notParsed,
            disabled: !data.notParsed
        });

        console.log(this.state.adList)
        console.log(this.state.notParsed)
    }

    render() {
        
        const { Dragger } = Upload;
        //let state = this.state;
        let initLoad = this.initLoad;
        let setResults = this.setResults;
        const types = ["text/csv"];
        const props = {
            name: 'file',
            multiple: false,
            customRequest: function (obj) {
                var formData = new FormData();
                console.log(obj)
                console.log(obj.file)
                formData.append('file', obj.file);
                console.log(formData);
                fetch(getApiUrl() + "/admin/loadCSV",
                    {
                        method: 'POST',
                        credentials: 'include',
                        cache: 'no-cache',
                       
                        headers: new Headers({
                            'Accept': 'application/json',
                           
                        }),
                        body: formData
                    }).then(res => res.json()).then(res => {
                        obj.onSuccess(res)
                        if (!res.error) {
                            setResults(res.data);
                        }
                        if (res.error == true) {

                            message.error("Il file: \"" + obj.file.name + "\" non rispetta i requisiti. Prego consultarli.")

                        }

                       
                          if (res.data.notParsed.length !== 0) {
                            message.error("Attenzione il file: \"" + obj.file.name + "\" è stato caricato ma contiene degli errori")

                        }
                        

                    }).catch(err => {

                        obj.onError(err);

                    }).catch(res => {

                        message.error("Errore nell'upload del file: " + res.error)
                    })
            },
            onChange: function (obj) {
                const status = obj.status;

                if (status !== 'uploading') {
                    console.log(obj.file, obj.fileList);
                }
                if (status === 'done') {
                    console.log(status);
                    message.success(`${obj.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${obj.file.name} file upload failed.`);
                }
            }


        }
        let config = {
            onChangeQntRich: () => null,
            onAddToChart: null,
            onFindCrossReference: null,
            onDeliveryDate: null,
            onNoteClick: null
        };

        //const { Title } = Typography;
        let dtc = new DataColumnUtil();
        let columns = dtc.createProductTableCol(config);

        let colums2 = columns.filter(element => element.title !== 'Qnt').filter(element => element.title !== 'Sconto').filter(element => element.title !== 'Cod. Sc.').filter(element => element.title !== 'Fam.').filter(element => element.title !== 'Listino').filter(element => element.title !== 'Info Prod.').filter(element => element.title !== 'Disp.').filter(element => element.title !== 'Azioni').map((element, index) => {
            if (element.title == 'Desc.') {
                element =  {
                    title: 'EAN', dataIndex: 'ean', key: 'ean'
                }
            }
           
            if (element.title == 'Netto') {
                element =  {
                    title: 'Accisa', dataIndex: 'fee', key: 'fee'
                }
            }
            else {
                return element
            }


return element;

        })
        //TABS PER RISULTATI
        const { TabPane } = Tabs;
        function callback(key) {
            console.log(key);

        }

        //tabella oggetti errori
        let dtcn = new DataColumnUtil();
        let colonne = dtc.createProductTableCol(config);

        let colonne2 = colonne.filter(element => element.title !== 'Fam.').filter(element => element.title !== 'Info Prod.').filter(element => element.title !== 'Qnt').filter(element => element.title !== 'Sconto').filter(element => element.title !== 'Netto').filter(element => element.title !== 'Lettera Sconto').filter(element => element.title !== 'Desc.').filter(element => element.title !== 'Listino').filter(element => element.title !== 'Disp.').filter(element => element.title !== 'Cod. Sc.').map((element, index) => {

            if (element.title == 'Azioni') {
                element = { title: 'Numero Riga', dataIndex: 'numRiga', key: 'azioni' };
            }
           
            else {

                return element
            }

            return element;

        })


        const { Panel } = Collapse;



        return (
            <div>
                <h1>Tariffario Autodoc</h1>
                <Collapse accordion>
                    <Panel header="Mostra i requisiti del file da caricare" key="1">
                        <p>Il form accetta <b>solo</b> file di formato <em>.CSV</em> con i seguenti requisiti:</p>
                        <ul>
                            <li>I campi devono essere forniti nell'ordine:
                                <table className='requisiti'><tr><th className='requisiti'>Precodice</th><th className='requisiti'>Codice</th><th className='requisiti'>EAN</th></tr></table>
                            </li>

                            <li>Utilizzare il separatore "<b className='puntoVirgola'>;</b>" tra i campi</li>
                            <li>Non deve contenere intestazioni</li>
                        </ul>
                    </Panel>
                </Collapse>

                <title></title>
                <body>
                    <Dragger {...props} accept=".csv" beforeUpload={(file) => {
                        if (!types.includes(file.type)) {
                            message.error(`${file.name} is not a csv file`);
                            return false;
                        } else {
                            return true
                        }
                    }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <h1>Seleziona o trascina un file che contiene i codici e le quantità da caricare per importare il suo contenuto nel carrello</h1>
                        <h1>Il file deve contenere su ogni riga il codice e la quantità</h1>
                    </Dragger>

                    <br></br>
                    <Tabs defaultActiveKey="1" onChange={callback} >
                        <TabPane tab="Caricati" key="1">
                            <div><Table columns={colums2} dataSource={this.state.adList}></Table></div>
                        </TabPane>
                        <TabPane tab="Errori" key="2" disabled={this.state.disabled}>
                            <div><Table columns={colonne2} dataSource={this.state.notParsed}></Table></div></TabPane>


                    </Tabs>




                </body>
            </div>
        )
    }

}

export default withRouter(LoadTariffario)