import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { fetchMissingArticles } from "../../actions/actions.js"

import { Table, Input, Button, Icon, Tag } from 'antd';
//import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';


const mapStateToProps = state => {
  return {
    missing: state.missing,
    user: state.user
  };
};
function mapDispatchToProps(dispatch) {
  return {
    fetchMissing: (startFrom) => dispatch(fetchMissingArticles(startFrom))
  }
}
class MissingArticles extends React.Component {

  constructor(props) {
    super(props);
    /*
     * LOCAL STATE
    */
    this.state = {
      user: this.props.user,
      missing: null,
      codeToFilter: null,
      pageSize: 10,
      key: 1
    }

    this.clearFilter = this.clearFilter.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
  }


  clearFilter() {
    this.setState({
      ...this.state,
      key: this.state.key + 1
    })
  }


  onShowSizeChange(current, value) {
    this.setState({
      ...this.state,
      pageSize: value
    })
  }

  //page, type: 'page' | 'prev' | 'next', originalElement
  itemRender(page, type, originalElement) {
    return originalElement
  }

  /* Manage state update */
  componentDidUpdate(prevProps, prevState, snapshot) {


    if (this.props.missing !== prevProps.missing) {
      console.log("Updating state - Missing Articles changed ")
      console.log(this.props.missing)

      let loadNext = false

      if (this.props.missing.data.length > 0) {
        loadNext = true
      }

      this.setState({
        ...this.state,
        missing: this.props.missing,
        pagination: this.props.missing.pagination

      })
    }
  }

  componentDidMount() {
    this.props.fetchMissing();
  }

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  getColumnSearchProps = (dataIndex, exact) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Elimina i filtri
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
    onFilter: function (value, record) {

      if (exact === null || exact === undefined || exact === false) {
        return (record[dataIndex] || "").toString().toLowerCase().includes(value.toLowerCase())
      } else {
        return (record[dataIndex] || "").toString().toLowerCase() === value.toLowerCase()
      }
    }
    ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        /*  
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />*/
        text
      ) : (
        text
      ),
  });



  render() {

    const columns = [

      { title: 'Codice', dataIndex: 'codice', key: 'codice', width: "10%", ...this.getColumnSearchProps('codice') },
      { title: 'Desc.', dataIndex: 'descrArticolo', key: 'descrArticolo', width: "13%", ...this.getColumnSearchProps('descrArticolo') },
      { title: 'Qnt.', dataIndex: 'quantita', key: 'quantita', width: "4%", ...this.getColumnSearchProps('quantita', true) },
      { title: 'N.Ord.Cli', dataIndex: 'ordineClienteFull', key: 'ordineClienteFull', width: "8%", ...this.getColumnSearchProps('ordineClienteFull') },
      { title: 'Data Ord.Cli', dataIndex: 'dataOrdineCliente', key: 'dataOrdineCliente', width: "10%", ...this.getColumnSearchProps('dataOrdineCliente') },
      {
        title: 'Data prev.cons.', dataIndex: 'dataConsegna', key: 'dataConsegna', width: "12%", ...this.getColumnSearchProps('dataConsegna', false),
        render: data => (<Tag color={'magenta'}>{data}</Tag>)
      },
      { title: 'Note Prev.Consegna', dataIndex: 'dettaglioData', key: 'dettaglioData', width: "10%", ...this.getColumnSearchProps('dettaglioData', false) },
      { title: 'N.Ord.Forn', dataIndex: 'ordineFornitoreFull', width: "7%", key: 'ordineFornitoreFull', ...this.getColumnSearchProps('ordineFornitoreFull', true) },
      { title: 'N.Ord.Casamadre', dataIndex: 'numOrdCasaMadre', key: 'numOrdCasaMadre', width: "7%", ...this.getColumnSearchProps('ordineSAP', true) },
      { title: 'Id. Sollec.', dataIndex: 'idSollecito', key: 'idSollecito', width: "10%", ...this.getColumnSearchProps('idSollecito', true) },
      { title: 'Nota Cliente', dataIndex: 'noteClienteRigaOrdine', key: 'noteClienteRigaOrdine', width: "9%", ...this.getColumnSearchProps('noteClienteRigaOrdine', false) }
    ];

    const columnsAdmin = [
      { title: 'Rag.Soc.', fixed: 'left', dataIndex: 'ragioneSoc', key: 'ragioneSoc', width: 220, ...this.getColumnSearchProps('ragioneSoc') },
      { title: 'N.Cli.', dataIndex: 'uIdCliente', key: 'uIdCliente', width: 100, ...this.getColumnSearchProps('uIdCliente', true) },
      { title: 'Codice', dataIndex: 'codice', key: 'codice', width: 150, ...this.getColumnSearchProps('codice') },
      { title: 'Desc.', dataIndex: 'descrArticolo', key: 'descrArticolo', width: 180, ...this.getColumnSearchProps('descrArticolo') },
      { title: 'Qnt.', dataIndex: 'quantita', key: 'quantita', width: 64, ...this.getColumnSearchProps('quantita', true) },
      { title: 'N.Ord.Cli.', dataIndex: 'ordineClienteFull', key: 'ordineClienteFull', width: 128, ...this.getColumnSearchProps('ordineClienteFull') },
      { title: 'Data Ord.Cli.', dataIndex: 'dataOrdineCliente', key: 'dataOrdineCliente', width: 128, ...this.getColumnSearchProps('dataOrdineCliente') },
      {
        title: 'Data prev.cons.', dataIndex: 'dataConsegna', width: 200, key: 'dataConsegna', ...this.getColumnSearchProps('dataConsegna', false),
        render: data => (<Tag color={'magenta'}>{data}</Tag>)
      },
      { title: 'Note Prev.Consegna', dataIndex: 'dettaglioData', width: 150, key: 'dettaglioData', ...this.getColumnSearchProps('dettaglioData', false) },
      { title: 'N.Ord.Forn.', dataIndex: 'ordineFornitoreFull', width: 128, key: 'ordineFornitoreFull', ...this.getColumnSearchProps('ordineFornitoreFull', true) },
      { title: 'Data Ord.Forn.', dataIndex: 'dataOrdineFornitore', width: 128, key: 'dataOrdineFornitore', ...this.getColumnSearchProps('dataOrdineFornitore', false) },
      { title: 'N.Ord.Casamadre', dataIndex: 'numOrdCasaMadre', key: 'numOrdCasaMadre', width: 100, ...this.getColumnSearchProps('ordineSAP', true) },
      { title: 'Id. Sollec.', dataIndex: 'idSollecito', width: 100, key: 'idSollecito', ...this.getColumnSearchProps('idSollecito', true) },
      { title: 'Nota Cliente', dataIndex: 'noteClienteRigaOrdine', width: 120, key: 'noteClienteRigaOrdine', ...this.getColumnSearchProps('noteClienteRigaOrdine', false) },
      { title: 'Dep.', dataIndex: 'deposito', key: 'deposito', width: 64, ...this.getColumnSearchProps('deposito') }

    ];

    let userCol = columns;
    let xScroll = true;

    if (this.state.user.uRole === "ADMIN") {
      userCol = columnsAdmin
      xScroll = 1600;
    }

    let dataAggiornamento = "";

    let m = []

    if (this.state.missing !== undefined && this.state.missing !== null &&
      this.state.missing.data !== undefined && this.state.missing.data !== null) {
      m = this.state.missing.data;
    }


    for (let i = 0; i < m.length && dataAggiornamento == ""; i++) {
      if (m[i].dataAggiornamento !== undefined && m[i].dataAggiornamento !== null && m[i].dataAggiornamento !== "") {
        dataAggiornamento = m[i].dataAggiornamento;
        break;
      }
    }

    return (
      <div>
        <h1>Nella tabella seguente, trovi la previsione di arrivo per i prodotti ordinati e non ancora consegnati</h1>

        {dataAggiornamento !== "" && (
          <h1>Previsioni di arrivo aggiornate al: {dataAggiornamento} </h1>
        )
        }

        <Button type="primary" onClick={this.clearFilter} style={{ margin: "1em" }}> Elimina tutti i filtri</Button>

        {this.state.missing !== undefined && this.state.missing !== null && (
          <Table key={this.state.key} className="search-results-table"
            scroll={{ x: xScroll }} columns={userCol} dataSource={this.state.missing.data}
            pagination={{
              pageSize: this.state.pageSize, showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange,
              pageSizeOptions: [5, 10, 15, 30, 50], itemRender: this.itemRender
            }} />
        )}

      </div>
    )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MissingArticles));