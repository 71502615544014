import React from 'react';
import {withRouter} from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typograph, Input } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import {getApiUrl, moneyFormat} from '../../utils.js'
import { connect } from "react-redux";
import {Divider, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import get from "lodash.get";
//import './obsolete.css';



class ListaObsolete extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            listaObsoleti: [],
           
        }

        this.setResults = this.setResults.bind(this)

    }

    setResults(data){
        this.setState({
            ...this.state,
            listaObsoleti: data
            
        });
        
        console.log(this.state.listaObsoleti)
       
    }
    //ORDINAMENTO COLONNA CODICE
    


     


   //FILTRO DI RICERCA

   handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  handleSearch(selectedKeys, confirm, dataIndex){
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }


   getColumnSearchProps = (dataIndex, exact, dataIndex2, replaceDotWithComma) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filtra
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Elimina i filtri
          </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
    onFilter: function(value, record) {
  
        console.log("On filter called with value: " + value + " and record")
        console.log(record)
        
        if(replaceDotWithComma){
          value = value.replaceAll(',','.')
        }
  
  
        //Search on field1 + field2  
        if(dataIndex2!==null && dataIndex2!==undefined){
          if(exact==null || exact===undefined || exact===false ){
            return ( (get(record, dataIndex) || "")  + "-" + (get(record, dataIndex2) || "") ).toString().toLowerCase().includes(value.toString().toLowerCase())
          }else{
            //exact
            return ( (get(record, dataIndex) || "")  + "-" + (get(record, dataIndex2) || "") ).toString().toLowerCase() === (value.toString().toLowerCase())
          }
          
        }
        else if(exact===null || exact===undefined || exact===false){
          console.log("Search exact false " )
          console.log( ("Record:" + get(record, dataIndex)  || "").toString().toLowerCase() )
          console.log("Value:"  + value.toString().toLowerCase())
          return (get(record, dataIndex)  || "").toString().toLowerCase().includes(value.toString().toLowerCase())
        }
        else{
          return (get(record, dataIndex) || "").toString().toLowerCase() === value.toString().toLowerCase()
        }
    }
      ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

   
    

    render() {

        function onChange(pagination, filters, sorter, extra) {
            console.log('params', pagination, filters, sorter, extra);
            console.log(extra)
          }
        
        let setResults = this.setResults;
       // let initLoad = this.initLoad;
       
        fetch(getApiUrl() + "/obsolete/getSupplierObsoletesList",
        {
            method: 'GET', 
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json',
                
            }),}).then(res => res.json()).then(res => {
             
                
                if(!res.error && (!this.state.listaObsoleti.length) && (res.data.length != 0)){
                    setResults(res.data);
                    
                } 
                //let dati = res.data;
               
                }).catch(err => {
                    message.error("Non è stato possibile recuperare la lista di prodotti obsoleti o nessun prodotto caricato")
                })
        
              
               
        /* let config = {
            onChangeQntRich : ()=> null,
            onAddToChart : null,
            onFindCrossReference : null,
            onDeliveryDate : null,
            onNoteClick : null
            };

        let dtc = new DataColumnUtil();
        let columns = dtc.createProductTableCol(config); 
        let colums2 = columns.filter(element => element.title !== 'Qnt').filter(element => element.title !== 'Sconto').filter(element => element.title !== 'Azioni').filter(element => element.title !== 'Netto').filter(element => element.title !== 'Fam.').map( (element, index) => {
            if (element.title == 'Disp.'){
                return {
                        title: 'Disp.', dataIndex: 'disp', key: 'disp'
                    }
            }
            else{
                return element
            }
        })  */



        const columns = [
            {
                title: 'Prec.', width: "7%", key: 'precodice', index: 'precodice',
                render: function (text, record, index) {
                    return (<span>{record.precodice}</span>);
                },
                ...this.getColumnSearchProps(["precodice"])
              },
              {
                title: 'Codice', width: "10%", key: 'codice', index: 'codice',
                render: function (text, record, index) {
                    return (<span>{record.codice}</span>);
                },
                dataIndex: 'codice',
               sorter: (a, b) => a.codice.localeCompare(b.codice),
               sortDirections: ['descend', 'ascend'],
                ...this.getColumnSearchProps(["codice"])
              },
              {
                title: 'Lett.Sc.', width: "8%", key: 'codsco', index: 'codsco',
                render: function (text, record, index) {
                    return (<span>{record.codsco}</span>);
                },
                ...this.getColumnSearchProps(["codsco"])
              }, 
              {
                title: 'Descr.', width: "22%", key: 'descrisption', index: 'description',
                render: function (text, record, index) {
                    return (<span>{record.description}</span>);
                },
                ...this.getColumnSearchProps(["description"])
              },
              {
                title: 'Listino', width: "8%", key: 'listino', index: 'listino',
                render: function (text, record, index) {
                    return (<span>{record.listino}</span>);
                },

                ...this.getColumnSearchProps(["listino"])
              }, 
              {
                title: 'Info Prod.', width: "30%",  key: 'info', index: 'info',
                render: function (text, record, index) {
                    return (<span>{record.info}</span>);
                },
                ...this.getColumnSearchProps(["info"])
              }, 
             
              {
                title: 'Disp.', width: "10%",  key: 'disp', index: 'disp',
                render: function (text, record, index) {
                    return (<span>{record.disp}</span>);
                },
                ...this.getColumnSearchProps(["disp"])
              }, 
             

        ]
        
        return(  
            <div> 
                <Table key={this.state.listaObsoleti.key} columns={columns} dataSource={this.state.listaObsoleti} onChange={onChange}></Table>      
            </div>
            )
                         


    
            
                    

    }




}
export default withRouter(ListaObsolete)