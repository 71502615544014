import { Button, Spin, InputNumber, Form, Input, Row, Col, Radio, Checkbox, Select, message, Tabs } from 'antd'
import { BackgroundColor } from 'chalk'
import React from 'react';
import 'antd/dist/antd.css';
import { getApiUrl } from '../../utils.js'


class Registration extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            uUsername: "",
            uPassword: "",
            uIdCliente: "",
            uIdDestinazione: "",
            uEmailsString: "",
            uDepRifString: "01",
            uShowIAM: true,
            uAllowedPromo: "CONFIG_DEPO",
            uRole: "USER",
            uShowOnlyDestData: false,
            userPrecodes: [],
            uPrecodeGroup: "1",
            uIsObsoleteSupplier: 0,
            osPrecodice: null,
            osDeliveryDays: null,
            clients: [],
            allClients: [],
            wholeClients: [],
            uId: "",
            obsoleteInput: "none"

        }

        this.onChangeUidCliente = this.onChangeUidCliente.bind(this)
        this.onChangeUShowIAM = this.onChangeUShowIAM.bind(this)
        this.onChangeUAllowedPromo = this.onChangeUAllowedPromo.bind(this)
        this.onChangeUEmailsString = this.onChangeUEmailsString.bind(this)
        this.onChangeUPassword = this.onChangeUPassword.bind(this)
        this.onChangeURole = this.onChangeURole.bind(this)
        this.onChangeUShowOnlyDestData = this.onChangeUShowOnlyDestData.bind(this)
        this.onChangeUUsername = this.onChangeUUsername.bind(this)
        this.onChangeUidDestinazione = this.onChangeUidDestinazione.bind(this)
        this.onDepoChange = this.onDepoChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChangePrecode = this.onChangePrecode.bind(this)
        this.switchToEditReg = this.switchToEditReg.bind(this)
        this.fetchClientDetail = this.fetchClientDetail.bind(this)
        this.onClientSearch = this.onClientSearch.bind(this)
        this.onSelectClient = this.onSelectClient.bind(this);
        this.modificaUtente = this.modificaUtente.bind(this);
        this.onChangeUIsObsoleteSupplier = this.onChangeUIsObsoleteSupplier.bind(this)
        this.onChangeGiorniDiConsegna = this.onChangeGiorniDiConsegna.bind(this)
        this.onChangePrecodiceFornitore = this.onChangePrecodiceFornitore.bind(this)
        this.refForm = React.createRef();//form modifica registrazione

    }

    componentDidMount() {
        let getUsersPrecodes = getApiUrl() + "/registration/getUsersPrecodes";
        fetch(getUsersPrecodes, {
            method: "GET",
            credentials: "include",
            headers: new Headers({
                "Accept": "application/json"
            })
        }
        ).then(res => res.json()).then(res => {
            if (res.error) {
                throw (res.error)
            } else {
                let resData = res.data
                if (resData !== undefined && res.data.length !== 0) {


                    this.setState({
                        ...this.state,
                        userPrecodes: res.data,


                    })


                }
            }
        }
        ).catch(error => message.error("C'è stato un problema nel recuperare i precodici utente"))
    }


    onChangeUidCliente(event) {
        this.setState({
            ...this.state,
            uIdCliente: event.target.value
        }
        )
    }

    onChangeUidDestinazione(event) {
        this.setState({
            ...this.state,
            uIdDestinazione: event.target.value
        }
        )
    }

    onChangeUUsername(event) {
        this.setState({
            ...this.state,
            uUsername: event.target.value
        }
        )
    }

    onChangeUPassword(event) {
        this.setState({
            ...this.state,
            uPassword: event.target.value
        }
        )
       

    }

    onChangeUShowIAM(event) {
        console.log(event)
        this.setState({
            ...this.state,
            uShowIAM: event.target.value
        })
    }

    onChangeUEmailsString(event) {
        this.setState({
            ...this.state,
            uEmailsString: event.target.value
        }
        )
    }

    onChangeUAllowedPromo(event) {
        this.setState({
            ...this.state,
            uAllowedPromo: event
        }
        )
    }

    onChangeURole(event) {
        this.setState({
            ...this.state,
            uRole: event
        })
    }

    onChangeUShowOnlyDestData(event) {
        this.setState({
            ...this.state,
            uShowOnlyDestData: event.target.value
        })
    }
    onChangePrecode(event) {
        this.setState({
            ...this.state,
            uPrecodeGroup: event.target.value
        })
    }

    onDepoChange(values) {
        let uDepRifString = "";
        let it = 0;
        values.forEach(function (arrayItem) {

            if (it > 0) {
                uDepRifString += "+"
            }
            uDepRifString += arrayItem
            it++;
        });

        this.setState({
            ...this.state,
            uDepRifString: uDepRifString

        })
    }


    onChangeUIsObsoleteSupplier(e) {
        console.log("is obsolete: " + e.target.value)
        if (e.target.value === 'true') {
            this.setState({
                ...this.state,
                obsoleteInput: "flex",
                uIsObsoleteSupplier: 1
            })
        } else {

            this.setState({
                ...this.state,
                obsoleteInput: "none",
                uIsObsoleteSupplier: 0
            })


        }

    }

    onChangePrecodiceFornitore(e) {
        console.log("PRECODICE:" + e)
        this.setState({
            ...this.state,
            osPrecodice: e.target.value
        })


    }

    onChangeGiorniDiConsegna(e) {

        console.log(e)
        this.setState({
            ...this.state,
            osDeliveryDays: e
        })
        console.log(this.state.osDeliveryDays)

    }

    onSubmit() {


        fetch(getApiUrl() + "/registration", {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(this.state)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                } else {
                    message.success("Utente registrato con successo");
                    this.refForm.current.resetFields()//una volta compilato registrato resetto tutti i campi
                    
                    this.setState({
                        ...this.state,
                      uDepRifString: "01", 
                      uShowIAM: true 
                    })//forzo
                }
            })
            .catch(error => {
                message.error("C'è stato un problema nel registrare l'utente");
            })


    }

    switchToEditReg(e) {
        if (e === '2') {
            this.fetchClientDetail()



        } else {
            console.log(e)
            this.setState({
                ...this.state,
                obsoleteInput: 'none',

            })

        }
    }

    fetchClientDetail() {

        fetch(getApiUrl() + "/auth/getAvaibleUsersForBehalf", {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept": 'application/json'
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }

                let ris = res.data.map(elem => {
                    var ret = {
                        label: elem.user.uUsername + " - " + elem.customer.businessName, //+ " - " + elem.city,
                        value: elem.user.uUsername + " - " + elem.customer.businessName, //+ " - " + elem.city,
                        id: elem.user.uId
                    };
                    return ret
                });

                this.setState({
                    ...this.state,
                    clients: ris,
                    allClients: res.data,
                    wholeClients: ris
                })


            })
            .catch(res => {
                this.setState({
                    ...this.state,
                    error: "Errore nel recuperare la lista dei clienti" + res
                })
            })




    }

    onClientSearch = (searchText) => {

        searchText = searchText.toUpperCase()
        if (searchText === "") {
            this.setState({
                ...this.state,
                clients: this.state.wholeClients
            })
            return
        }

        let r = this.state.wholeClients.filter(elem => elem.label.replaceAll('.', '').toUpperCase().includes(searchText) ? true : false)
        this.setState({
            ...this.state,
            clients: r
        })
    }



    onSelectClient(option) {

        let index = this.state.allClients.findIndex(clienti => clienti.user.uId === Number(option))
        // console.log(this.state.allClients[0].user.uId)
        //console.log(option[0].key)

        fetch(getApiUrl() + "/registration/getUserData?uUsername=" + this.state.allClients[index].user.uUsername + "&uIsObsoleteSupplier=" + this.state.allClients[index].user.uIsObsoleteSupplier, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept": 'application/json'
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }

                if (res.data.uIsObsoleteSupplier == true) {
                    this.setState({
                        ...this.state,
                        obsoleteInput: 'flex'
                    })
                }
                this.refForm.current.setFieldsValue({
                    uIdCliente: res.data.uIdCliente,
                    uUsername: res.data.uUsername,
                    uIdDestinazione: res.data.uIdDestinazione,
                    uEmailsString: res.data.uEmailsString,
                    uShowIAM: res.data.uShowIAM,
                    userPrecode: res.data.uPrecodeGroup,
                    uDepRifString: res.data.uDepRifString,
                    uAllowedPromo: res.data.uAllowedPromo,
                    uRole: res.data.uRole,
                    uShowOnlyDestData: res.data.uShowOnlyDestData,
                    uIsObsoleteSupplier: res.data.uIsObsoleteSupplier,

                })
                this.setState({
                    ...this.state,
                    uId: res.data.uId,
                    uDepRifString: res.data.uDepRifString
                })
                if (res.data.uIsObsoleteSupplier == true) {
                    this.setState({
                        ...this.state,
                        obsoleteInput: 'flex',
                        osPrecodice: res.data.osPrecodice,
                        osDeliveryDays: res.data.osDeliveryDays
                    })

                } else {
                    this.setState({
                        ...this.state,
                        obsoleteInput: 'none',
                        osPrecodice: null,
                        osDeliveryDays: null
                    })

                }



            }).catch(res => {
                this.setState({
                    ...this.state,
                    error: "Errore nel recuperare i dati dell'utente" + res
                })
            })





    }

    modificaUtente() {


        this.refForm.current.validateFields().then(v => {
            //SUCCESS VALIDATION

            let par = {
                uId: this.state.uId,
                uUsername: v.uUsername,
                uPassword: v.uPassword,
                uIdCliente: v.uIdCliente,
                uIdDestinazione: v.uIdDestinazione,
                uEmailsString: v.uEmailsString,
                uDepRifString: this.state.uDepRifString,
                uShowIAM: v.uShowIAM,
                uAllowedPromo: v.uAllowedPromo,
                uRole: v.uRole,
                uShowOnlyDestData: v.uShowOnlyDestData,
                uPrecodeGroup: v.userPrecode,
                uIsObsoleteSupplier: v.uIsObsoleteSupplier,
                osPrecodice: this.state.osPrecodice,
                osDeliveryDays: this.state.osDeliveryDays
            }

            fetch(getApiUrl() + "/registration/updateUserData", {
                credentials: "include",
                method: "POST",
                headers: new Headers({
                    "Accept": 'application/json',
                    "Content-Type": "application/json"
                }),
                body: JSON.stringify(par)
            })
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        throw (res.error);
                    } else {

                        message.success("Utente modificato con successo");

                        console.log(this.refForm.current.getFieldsValue())
                        this.refForm.current.resetFields()
                        this.setState({
                            ...this.state,
                            osPrecodice: null,
                            osDeliveryDays: null
                        })
                        this.fetchClientDetail()



                    }
                })
                .catch(error => {
                    message.error("Non è stato possibile registrare le informazioni del cliente");
                    console.log(error)
                })
        })





    }





    render() {
        require('./registrationForm.css');
        const depoOptions = [
            { label: 'Senise', value: '01' },
            { label: 'Cagliari', value: '03' },
        ];

        const userPrecodes = this.state.userPrecodes
        const switchToEditReg = this.state.switchToEditReg
        const { TabPane } = Tabs;
        const { Option } = Select;
        const { fetching } = this.state;
        return (

            <Tabs defaultActiveKey="1" onChange={this.switchToEditReg}>
                <TabPane tab="Nuova Registrazione" key="1">
                    <Col xs={24} sm={24} md={18} lg={12} xl={12} style={{ margin: "auto" }} >
                        <Form
                            name="basic"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 5 }}
                            initialValues={{
                                uDepRifString: ["01"]
                            }} ref={this.refForm}
                        >
                            <Row gutter={8} justify={'start'} xs={24} sm={24} md={18} lg={12} xl={12} >
                                <Col span={10}>
                                    <Form.Item
                                        label="Codice Cliente"
                                        name="uIdCliente"
                                        rules={[{ required: true, message: 'Inserire il codice cliente' }]} labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUidCliente} type="number" />
                                    </Form.Item> </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Codice Destinazione"
                                        name="uIdDestinazione"
                                        rules={[{ required: true, message: 'Inserire il codice destinazione del cliente' }]}
                                        labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 20 }}>
                                        <Input onChange={this.onChangeUidDestinazione} type="number" />
                                    </Form.Item></Col>
                            </Row>
                            <Row gutter={8} justify={'start'}>
                                <Col span={10}>
                                    <Form.Item
                                        label="Username"
                                        name="uUsername"
                                        rules={[{ required: true, message: 'Inserire lo username' }]} labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUUsername} type="string" />
                                    </Form.Item></Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Password"
                                        name="uPassword"
                                        rules={[{ required: true, message: 'Inserire la password' }]} labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUPassword} type="string" />
                                    </Form.Item></Col>
                            </Row>

                            <Row gutter={8}  ><Col span={10}>
                                <Form.Item
                                    label="E-mail"
                                    name="uEmailsString"
                                    rules={[{ required: true, message: 'Inserire l\'indirizzo e-mail' }]} labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Input onChange={this.onChangeUEmailsString} type="string" />
                                </Form.Item></Col>
                                <Col span={10} >
                                    <Form.Item
                                        label="Iam abilitato"
                                        name="uShowIAM"
                                    >
                                        <Radio.Group defaultValue={true} onChange={this.onChangeUShowIAM} style={{ display: 'flex' }}>
                                            <Radio value={true} >SI</Radio>
                                            <Radio value={false}>NO</Radio>
                                        </Radio.Group>
                                    </Form.Item></Col>
                            </Row>

                            <Form.Item
                                label="Precodici Utente"
                                name="userPrecode"
                            >
                                <Radio.Group onChange={this.onChangePrecode} style={{ display: 'flex' }} defaultValue={1}>
                                    {
                                        userPrecodes?.map(option =>

                                            <Radio  {...userPrecodes} value={option.upId} >{option.upDesc}</Radio>

                                        )
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Depositi collegati"
                                name="uDepRifString"
                                rules={[{ required: true, message: "Specificare almeno uno dei 2 depositi" }]}
                            >
                                <Checkbox.Group onChange={this.onDepoChange} options={depoOptions} defaultValue={['01']}  >
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                label="Configurazione Promo"
                                name="uAllowedPromo"
                                rules={[{ required: true, message: "Specificare il tipo di promo da visualizzare" }]}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 10 }}>
                                <Select defaultValue="CONFIG_DEPO" onChange={this.onChangeUAllowedPromo}>
                                    <Select value="ALL">Tutte</Select>
                                    <Select value="CONFIG_DEPO">Promo del deposito di riferimento</Select>
                                    <Select value="CUSTOM">Personalizzato</Select>
                                    <Select value="NONE">Nessuna</Select>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Ruolo"
                                name="uRole"
                            >
                                <Select defaultValue="USER" onChange={this.onChangeURole}>
                                    <Select value="USER" >User</Select>
                                    <Select value="ADMIN" >Admin</Select>
                                    <Select value="SALESMAN">Salesman</Select>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Mostra dati della specifica destinazione"
                                name="uShowOnlyDestData"
                                onChange={this.onChangeUShowOnlyDestData}
                            >
                                <Radio.Group defaultValue={false}>
                                    <Radio value={true}>SI</Radio>
                                    <Radio value={false}>NO</Radio>

                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Fornitore Obsoleti"
                                name="uIsObsoleteSupplier"
                                onChange={this.onChangeUIsObsoleteSupplier}
                            >
                                <Radio.Group defaultValue={false}>
                                    <Radio value={true} >SI</Radio>
                                    <Radio value={false}>NO</Radio>

                                </Radio.Group>


                            </Form.Item>
                            <Form.Item
                                label="Precodice Fornitore"
                                name="osPrecodice"
                                rules={[{ required: true, message: "Specificare il precodice" }]}
                                style={{ display: this.state.obsoleteInput }} onChange={this.onChangePrecodiceFornitore}>
                                <Input placeholder="Precodice fornitore" /> </Form.Item>
                            <Form.Item
                                label="Giorni di consegna"
                                name="osDeliveryDays"
                                rules={[{ required: true, message: "Specificare i giorni di consegna" }]}
                                style={{ display: this.state.obsoleteInput }} >
                                <InputNumber onChange={this.onChangeGiorniDiConsegna} /> </Form.Item>
                            <Form.Item
                                label=" "
                                name="button"
                                colon={false}
                            >
                                <Button type="primary" onClick={this.onSubmit}>Register</Button>
                            </Form.Item>
                        </Form>
                        <br />
                        <br />

                    </Col>
                </TabPane>
                <TabPane tab="Modifica Registrazione" key="2">
                    <Col xs={24} sm={24} md={18} lg={12} xl={12} style={{ margin: "auto" }} >


                        <Form
                            name="basic"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 5 }}
                            initialValues={{
                                uDepRifString: ["01"]
                            }} ref={this.refForm}
                        >


                            <Row gutter={8} justify={'start'} xs={24} sm={24} md={18} lg={12} xl={12} >
                                <label>Digita il nome del cliente da modificare (senza punti)</label>
                                <Select showSearch style={{ width: '87%', marginBottom: 15 }} placeholder="Seleziona un cliente" optionFilterProp="children" onChange={this.onSelectClient} onSearch={this.onClientSearch}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>

                                    {this.state.clients.map(d => (

                                        <Option key={d.id}>{d.label}</Option>

                                    ))}
                                </Select>

                                <Col span={10}>
                                    <Form.Item
                                        label="Codice Cliente"
                                        name="uIdCliente"
                                        rules={[{ required: true, message: 'Inserire il codice cliente' }]} labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUidCliente} type="number" />
                                    </Form.Item> </Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Codice Destinazione"
                                        name="uIdDestinazione"
                                        rules={[{ required: true, message: 'Inserire il codice destinazione del cliente' }]}
                                        labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 20 }}>
                                        <Input onChange={this.onChangeUidDestinazione} type="number" />
                                    </Form.Item></Col>
                            </Row>
                            <Row gutter={8} justify={'start'}>
                                <Col span={10}>
                                    <Form.Item
                                        label="Username"
                                        name="uUsername"
                                        rules={[{ required: true, message: 'Inserire lo username' }]} labelCol={{ span: 10 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUUsername} type="string" />
                                    </Form.Item></Col>
                                <Col span={10}>
                                    <Form.Item
                                        label="Password"
                                        name="uPassword"
                                        labelCol={{ span: 12 }}
                                        wrapperCol={{ span: 20 }}
                                    >
                                        <Input onChange={this.onChangeUPassword} type="string" />
                                    </Form.Item></Col>
                            </Row>

                            <Row gutter={8}  ><Col span={10}>
                                <Form.Item
                                    label="E-mail"
                                    name="uEmailsString"
                                    rules={[{ required: true, message: 'Inserire l\'indirizzo e-mail' }]} labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 20 }}
                                >
                                    <Input onChange={this.onChangeUEmailsString} type="string" />
                                </Form.Item></Col>
                                <Col span={10} >
                                    <Form.Item
                                        label="Iam abilitato"
                                        name="uShowIAM"
                                    >
                                        <Radio.Group defaultValue={true} onChange={this.onChangeUShowIAM} style={{ display: 'flex' }}>
                                            <Radio value={true} >SI</Radio>
                                            <Radio value={false}>NO</Radio>
                                        </Radio.Group>
                                    </Form.Item></Col>
                            </Row>

                            <Form.Item
                                label="Precodici Utente"
                                name="userPrecode"
                            >
                                <Radio.Group onChange={this.onChangePrecode} style={{ display: 'flex' }}>
                                    {
                                        userPrecodes?.map(option =>

                                            <Radio  {...userPrecodes} value={option.upId} >{option.upDesc}</Radio>

                                        )
                                    }</Radio.Group></Form.Item>
                            <Form.Item
                                label="Depositi collegati"
                                name="uDepRifString"
                                rules={[{ required: true, message: "Specificare almeno uno dei 2 depositi" }]}
                            >
                                <Checkbox.Group onChange={this.onDepoChange} options={depoOptions} >
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                                label="Configurazione Promo"
                                name="uAllowedPromo"
                                rules={[{ required: true, message: "Specificare il tipo di promo da visualizzare" }]}
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 10 }}>
                                <Select defaultValue="CONFIG_DEPO" onChange={this.onChangeUAllowedPromo}>
                                    <Select value="ALL">Tutte</Select>
                                    <Select value="CONFIG_DEPO">Promo del deposito di riferimento</Select>
                                    <Select value="CUSTOM">Personalizzato</Select>
                                    <Select value="NONE">Nessuna</Select>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Ruolo"
                                name="uRole"
                            >
                                <Select defaultValue="USER" onChange={this.onChangeURole}>
                                    <Select value="USER" >User</Select>
                                    <Select value="ADMIN" >Admin</Select>
                                    <Select value="SALESMAN">Salesman</Select>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Mostra dati della specifica destinazione"
                                name="uShowOnlyDestData"
                                onChange={this.onChangeUShowOnlyDestData}
                            >
                                <Radio.Group defaultValue={false}>
                                    <Radio value={true}>SI</Radio>
                                    <Radio value={false}>NO</Radio>

                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Fornitore Obsoleti"
                                name="uIsObsoleteSupplier"
                                onChange={this.onChangeUIsObsoleteSupplier}
                            >
                                <Radio.Group defaultValue={false}>
                                    <Radio value={true} >SI</Radio>
                                    <Radio value={false}>NO</Radio>

                                </Radio.Group>


                            </Form.Item>
                            <Form.Item
                                label="Precodice Fornitore"
                                name="osPrecodice"

                                style={{ display: this.state.obsoleteInput }} onChange={this.onChangePrecodiceFornitore} >
                                <Input placeholder="Precodice fornitore" value={this.state.osPrecodice} /> </Form.Item>
                            <Form.Item
                                label="Giorni di consenga"
                                name="osDeliveryDays"

                                style={{ display: this.state.obsoleteInput }} >
                                <InputNumber onChange={this.onChangeGiorniDiConsegna} value={this.state.osDeliveryDays} /> </Form.Item>
                            <Form.Item
                                label=" "
                                name="button"
                                colon={false}
                            >
                                <Button type="primary" onClick={this.modificaUtente}>Modifica Utente</Button>
                            </Form.Item>
                        </Form>
                        <br />
                        <br />

                    </Col>

                </TabPane>
            </Tabs>
        )
    };

}

export default Registration