import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import {fetchSearch} from '../../actions/actions.js'
import { Card, Avatar, Row, Col, message, Modal } from 'antd';
import { connect } from "react-redux";
import { MoreOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import Linkify from 'react-linkify';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {getApiUrl} from '../../utils.js'
import './promo.css'
import PromoEditor from '../PromoEditor/promoEditor.js';

class Promo extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            promo : this.props.promo,
            admin : this.props.admin,
            cssStyle : this.props.cssStyle
        }
        this.onPromoClick = this.onPromoClick.bind(this);
        this.deletePromo = this.deletePromo.bind(this);
        this.showDeleteMessage = this.showDeleteMessage.bind(this);
        this.editPromo = this.editPromo.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
    }


    deletePromo(){

        fetch(getApiUrl() + "/promo/" + this.state.promo.proId,{
                method: 'DELETE', 
                cache: 'no-cache',
                credentials: 'include',
                headers: new Headers({
                    "Accept" : 'application/json'
                }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res);
            }
            else{
                message.success("La promo è stata eliminata, ricaricare la pagina per vedere la nuova lista");     
            }
        })
        .catch(res => {
            message.error("Errore nell'eliminazione della promo:" + res) 
        })
    }

    onPromoClick(){
        this.props.history.push("/promoDetail/" + this.state.promo.proId);
    }

    onPromoClickEdit(){
        this.props.history.push("/promoDetail/edit/" + this.state.promo.proId );
    }


    showDeleteMessage(){
            const { confirm } = Modal;

            let that = this;
            confirm({
              title: 'Eliminare la promo?',
              icon: <ExclamationCircleOutlined />,
              content: 'Sei sicuro di voler eliminare la promo? ',
              onOk() {
                that.deletePromo(that.state.promo.proId);
              },
              onCancel() {
              },
            });
    }

    editPromo(){
        this.setState({
            ...this.state,
            edit : true
        })
    }

    closeEdit(){
        this.setState({
            ...this.state,
            edit : false
        })
    }

    render() {

        const { Meta } = Card;
    
        if(this.state.promo===undefined){
            return null;
        }

        let actions = null;
        if(this.state.admin===true){
            actions = [ 
                <MoreOutlined key="setting" />,
                <EditOutlined key="edit" onClick={(e)=> {  e.stopPropagation(); this.onPromoClickEdit(); } }/>,
                <DeleteOutlined key="ellipsis" onClick={(e)=> {  e.stopPropagation(); this.showDeleteMessage(); } } />
            ]
        }else {
            actions = [ <MoreOutlined key="Guarda i prodotti in promo" /> ]
        }
        
            return (
                <div>
                    <Card
                        onClick={this.onPromoClick}
                        className={"promocard "  + ( this.state.cssStyle || "" ) }
                        cover={ <div style={{ overflow: "hidden", height: "100px" }}>
                        <img alt="example" className="cardCoverImage" src={this.state.promo.proBannerLink} style={{ maxHeight: "100%", width: "100%" }} /> </div>}
                        actions={actions} 
                       ><div style={{ overflow: "hidden", height: "100px"} }>
                        <h3> {this.state.promo.proTitle} </h3> 
                        <span style={{fontWeight:900}}> Dep. {this.state.promo.proDepRifString}</span>    
                        <Meta
                            avatar={<Avatar src={process.env.PUBLIC_URL + "/sirmec-logo-192.png"} /> }
                            title={this.state.promo.proSubtitle}
                            description={this.state.promo.proDescription}
                        /></div>
                        <div className="promoDesc" style={{marginTop:"1.5em"}}>
                        <Linkify>{this.state.promo.proText}</Linkify>

                        {/*if there is no text, add a fake line to keep same heigth*/} 
                        { (!this.state.promo.proText &&
                                <br></br>
                        )}

                        </div>                     
                    </Card>
                </div>
            )
    }
}

export default withRouter(Promo);