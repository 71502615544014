import React from 'react';
import { connect } from "react-redux";
import { moneyFormat, getApiUrl } from "../../utils.js"
import { fetchCart, fetchRemoveFromCart, updateCartNote, updateCRifOrdCli, fetchUpdateNotes, fetchSendOrder, saveNoteCliente, fetchUpdateQntToCart, fetchExitBehalf, fetchUrgentOrder } from "../../actions/actions.js"
import { Avatar, Card, Col, Divider, Row, Spin } from 'antd'
import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Statistic, Checkbox, message, Tooltip } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import { Table, Modal } from 'antd';
import ActionBar from '../ActionBar/actionBar.js'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import { LogoutOutlined } from '@ant-design/icons';
import { ToolOutlined } from '@ant-design/icons';
import { ShoppingCartOutlined } from '@ant-design/icons';

import './cart.css'
import Meta from 'antd/lib/card/Meta.js';

const { confirm } = Modal;

const { TextArea } = Input;
const mapStateToProps = state => {
    return {
        cart: state.cart,
        user: state.user,
        isPriorityOrder: state.isPriorityOrder
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchCart: () => dispatch(fetchCart()),
        fetchRemoveFromCart: (record) => dispatch(fetchRemoveFromCart(record)),
        updateCartNote: (notes) => dispatch(updateCartNote(notes)),
        updateCRifOrdCli: (cRifCli) => dispatch(updateCRifOrdCli(cRifCli)),
        fetchUpdateNotes: (notes, rifCli ) => dispatch(fetchUpdateNotes(notes, rifCli)),
        //fetchSendOrder va inserito probabilmente un altro argomento per il riferimento ordine cliente
        fetchSendOrder: (notes, rifCli) => dispatch(fetchSendOrder(notes, rifCli)),
        saveNoteCliente: (product, notaClienteText) => dispatch(saveNoteCliente(product, notaClienteText, "CART")),
        fetchUpdateQntToCart: (product, value) => dispatch(fetchUpdateQntToCart(product, value)),
        fetchExitBehalf: () => dispatch(fetchExitBehalf()),
        fetchUrgentOrder: () => dispatch(fetchUrgentOrder())
    };
}
class Cart extends React.Component {

    constructor(props) {
        super(props);

        this.renderCartSummary = this.renderCartSummary.bind(this);
        this.renderCart = this.renderCart.bind(this);
        this.onClickCart = this.onClickCart.bind(this);
        this.onUpdateNotes = this.onUpdateNotes.bind(this);
        this.onCheckoutCart = this.onCheckoutCart.bind(this);

        this.onOpenNoteCliente = this.onOpenNoteCliente.bind(this);
        this.onCloseNoteCliente = this.onCloseNoteCliente.bind(this);
        this.onSaveNoteCliente = this.onSaveNoteCliente.bind(this);
        this.deleteProductConfirmationDialog = this.deleteProductConfirmationDialog.bind(this);

        this.notesRef = React.createRef();
        this.rifCliRef = React.createRef();
        this.onFinish = this.onFinish.bind(this)
        this.getTimeCountdown = this.getTimeCountdown.bind(this)
        this.onUrgentOrder = this.onUrgentOrder.bind(this)
        /*
         * LOCAL STATE
        */
        this.state = {
            cart: this.props.cart,
            user: this.props.user,
            deadline: 0,
            isPriorityOrder: false,
            /* this is a record for which popup will be opened - null if will be close */
            popupNoteCliente: null
        }
    }

    onChangeQnt(text, record) {
        console.log("Changed quantity to " + text + " on index " + record.key);
    }

    onOpenNoteCliente(record) {
        console.log("Open note popup...")
        this.setState({
            ...this.state,
            popupNoteCliente: record
        })
    }

    onCloseNoteCliente(record) {
        this.setState({
            ...this.state,
            popupNoteCliente: null
        })
    }

    onSaveNoteCliente() {

        let text = (this.state.popupNoteCliente !== undefined && this.state.popupNoteCliente !== null &&
            this.state.popupNoteCliente.noteCliente !== undefined) ? this.state.popupNoteCliente.noteCliente : ""

        this.props.saveNoteCliente(this.state.popupNoteCliente, text);

        this.setState({
            ...this.state,
            popupNoteCliente: null
        })

        //console.log(this.refNoteTextArea.current.state.value)
        //this.refNoteTextArea.current.state.value = ""
    }


    onClickCart() {
        this.props.history.push('/cart')
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log("Carts: ComponentDidUpdate with numberOfArticles: " + this.props.cart.cartData.totaliArticoli)

        if (this.props.cart.cartData.totaliArticoli !== prevProps.cart.cartData.totaliArticoli ||
            this.props.cart.cartData.netTotal !== prevProps.cart.cartData.netTotal ||
            this.props.cart.cartData.cNotes !== prevProps.cart.cartData.cNotes ||
            this.props.cart.cartData.cRifOrdCli !== prevProps.cart.cartData.cRifOrdCli ||
            this.props.user != prevProps.user ||
            this.props.cart.requstIsPending !== prevProps.cart.requstIsPending
        ) {
            this.setState({
                cart: this.props.cart,
                user: this.props.user,
                isPriorityOrder: this.props.isPriorityOrder
            });
        }
    }

    componentDidMount() {

        if (JSON.parse(localStorage.getItem("isPriorityOrder")) === "true") {



            this.setState({
                ...this.state,
                isPriorityOrder: true
            })



            this.props.fetchUrgentOrder();

        }
        else {
            console.log("1 " + JSON.parse(localStorage.getItem("isPriorityOrder")));
            this.setState({
                ...this.state,
                isPriorityOrder: false
            })

            this.props.fetchCart();


        }




            /*this.getTimeCountdown()*/;
    }

    onUpdateNotes() {
        console.log(this.notesRef.current.props.value)
        console.log(this.rifCliRef.current.props.value)
        this.props.fetchUpdateNotes(this.notesRef.current.props.value, this.rifCliRef.current.props.value)
    }

    

    onCheckoutCart() {
        let notes = this.notesRef.current.props.value || "";
        let cRifOrdCli = this.rifCliRef.current.props.value || "";
        this.props.fetchSendOrder(notes, cRifOrdCli);
    }

    //countdown functions

    onFinish() {
        console.log('finished!');
        /*this.getTimeCountdown()*/
    }

    getTimeCountdown() {
        if (this.state.cart.cartData.totaliArticoli === 1 && JSON.parse(localStorage.getItem("firstProduct")) === 'false')
            localStorage.setItem("firstProduct", JSON.stringify("true"))

        fetch(getApiUrl() + "/documents/getAverageDeparture", {


            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept": 'application/json'
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                } else {

                    var fullDate = new Date();
                    // console.log("actual time:", fullDate);
                    // fullDate.setHours(fullDate.getHours() + res.data.hour)
                    // fullDate.setMinutes(fullDate.getHours() + res.data.minute)
                    // if(res.data.hour < fullDate.getHours()){
                    //     fullDate.setDate(fullDate.getDay()+1)

                    // }
                    if (res.data.hour < fullDate.getHours())
                        fullDate.setDate((fullDate.getDate() + 1))
                    console.log("date: " + fullDate.getDate())
                    let timeTo = fullDate.getHours();
                    fullDate.setHours(res.data.hour)
                    console.log("actual time:", fullDate);

                    fullDate.setMinutes(res.data.minute)
                    this.setState({
                        ...this.state,
                        deadline: fullDate
                    })

                }
                localStorage.setItem("firstProduct", JSON.stringify("false"))
            })
            .catch(res => {
                console.log("Errore nel recuperare i dati" + res)
                if (res.errorCode === 0)
                    localStorage.setItem("firstProduct", JSON.stringify("false"))
            })
    }

    onUrgentOrder(e) {

        if (e.target.checked) {


            this.setState({
                ...this.state,
                isPriorityOrder: true
            })
            this.props.fetchUrgentOrder()
            localStorage.setItem("isPriorityOrder", JSON.stringify("false"))


        } else {
            localStorage.setItem("isPriorityOrder", JSON.stringify("false"));
            this.setState({
                ...this.state,
                isPriorityOrder: false
            })
            this.props.fetchCart()

        }





    }



    renderCartSummary() {
        const { Countdown } = Statistic;

        return (
            <div className="cartInner">
                <div style={{ marginRight: "1em" }}>
                    <Avatar style={{ margin: "auto", display: "block" }} size="medium" icon={<UserOutlined />} />
                    <div>{this.state.user.uUsername}

                        {this.state.user.originalUser !== undefined && this.state.user.originalUser !== null && (
                            <span>*&nbsp;<LogoutOutlined onClick={() => this.props.fetchExitBehalf()} /> </span>
                        )}
                    </div>

                </div>
                <div className="cartInfo" onClick={this.onClickCart}>
                    <div className="cartInfo1">
                        <img onClick={this.props.addArticleToCarts} src={process.env.PUBLIC_URL + '/icon/icons8-carrello.png'}></img>
                        <span>&nbsp; {moneyFormat(this.state.cart.cartData.netTotal)}</span>

                    </div>
                    <div className="cartInfo2">
                        <p> {this.state.cart.cartData.totaliArticoli} articoli nel carrello</p>
                    </div >
                    {/*{this.state.cart.cartData.totaliArticoli > 0 && this.state.deadline !== 0 &&
              
                 <div className="countDown">
                 <p>Se ordini entro</p>
                
                 
                                {JSON.parse(localStorage.getItem("firstProduct")) === 'true' && this.getTimeCountdown()}
                 <Countdown value={Date.parse(this.state.deadline)} onFinish={this.onFinish} valueStyle={{color: '#f37c2e', marginTop: -20 }} />
                 <p>riceverai la merce nelle prossime 24h</p>
                 </div>
                }
            */}
                </div>
            </div>
        );
    }

    renderCartSummaryInline() {

        return (
            <div className="cartInner" style={{ display: "inline-block" }}  >
                <div style={{ display: "inline-block", height: "2em", marginRight: "1em" }} className="cartInfo" onClick={this.onClickCart}>
                    <div className="cartInfo1">
                        {/* {!this.state.isPriorityOrder && localStorage.setItem("isPriorityOrder", JSON.stringify("false")) } */}
                        {this.props.location.pathname === '/cart' &&

                            <Checkbox onChange={this.onUrgentOrder} checked={this.state.isPriorityOrder}>Ordine Urgente
                                <Tooltip title="ATTENZIONE! I prezzi potrebbero subire delle variazioni ">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Checkbox>
                        }
                        <img style={{ height: "2em" }} onClick={this.props.addArticleToCarts} src={process.env.PUBLIC_URL + '/icon/icons8-carrello.png'}></img>
                        <span>&nbsp; {moneyFormat(this.state.cart.cartData.netTotal)}</span>
                    </div>
                </div>
                <div style={{ display: "inline-block" }}>
                    <div style={{ color: "#888" }} className="cartInfo2">
                        <p> {this.state.cart.cartData.totaliArticoli} articoli nel carrello</p>
                    </div>
                </div>
            </div>
        );
    }

    renderCart() {

        let dtc = new DataColumnUtil();

        let config = {
            onNoteClick: this.onOpenNoteCliente,
            onRemoveFromChart: this.deleteProductConfirmationDialog,
            onChangeQntRich: this.props.fetchUpdateQntToCart
        }

        let columns = dtc.createProductTableCol(config);

        let expandable = {
            /*expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,*/
            rowExpandable: record => record.alt === true,
        }

        let noteClienteText = (this.state.popupNoteCliente !== undefined && this.state.popupNoteCliente !== null &&
            this.state.popupNoteCliente.noteCliente !== undefined) ? this.state.popupNoteCliente.noteCliente : ""

        return (
            <div className="site-layout-background" style={{ minHeight: 380 }}>



                <div>
                    <Modal title="Inserisci una nota per l'articolo"
                        visible={this.state.popupNoteCliente !== null} onOk={this.onSaveNoteCliente} onCancel={this.onCloseNoteCliente} >
                        <TextArea maxLength={250} value={noteClienteText}
                            onChange={(e) => {
                                this.setState({
                                    ...this.state,
                                    popupNoteCliente: {
                                        ...this.state.popupNoteCliente || null,
                                        noteCliente: e.target.value
                                    }
                                })
                            }} defaultValue={noteClienteText} ref={this.refNoteTextArea}>
                        </TextArea>
                    </Modal>
                </div>


                <div className="cartInner">
                    <div className="cartInfo1">
                        <span style={{ color: "black", marginLeft: "1em" }}>Rif. Carrello:</span>{this.state.cart.cartData.cId}
                        <span style={{ color: "black", marginLeft: "1em" }}>Totale:</span>{moneyFormat(this.state.cart.cartData.netTotal)}
                        <span style={{ color: "black", marginLeft: "1em" }}>Numero di articoli:</span> {this.state.cart.cartData.totaliArticoli}
                        <span style={{ color: "black", marginLeft: "1em" }}>Quantità totali:</span> {this.state.cart.cartData.totaliQuantita}

                        <ActionBar cart={this.state.cart} showOrderButton={true} checkoutCartCallback={this.onCheckoutCart} sendNotesCallback={this.onUpdateNotes} />

                    </div>
                </div>

                <Row gutter={10}>
                    <Col span={12}>
                        <Card headStyle ={{marginTop : "0"}} bodyStyle={{ padding: '5px' }} title={
                        <div>
                            {<ToolOutlined />} {"Riferimento ordine cliente"}
                        </div>}
                        >
                            <TextArea style={{ resize: 'none' }} className="cRifOrdCli" ref={this.rifCliRef} type="text" onChange={this.props.updateCRifOrdCli} value={this.state.cart.cartData.cRifOrdCli} placeholder="Inserisci un riferimento all'ordine utile per il tuo magazzino. Es: num. targa/num. pratica/num. ordine sul gestionale dell'officina." maxLength={30} ></TextArea>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bodyStyle={{ padding: '5px' }} title={<div> <ShoppingCartOutlined /> {"Nota per Sirmec"} </div>}>
                            <TextArea style={{resize: 'none' }} maxLength={500} onChange={this.props.updateCartNote} ref={this.notesRef} value={this.state.cart.cartData.cNotes} placeholder="Note destinate ai nostri operatori." />
                        </Card>
                    </Col>
                </Row>

                <h1 className="searchTitle">Dettaglio del carrello</h1>
                <h7>Tutti i prezzi sono IVA Esclusa</h7>
                <Table className="search-results-table"
                    columns={columns}
                    expandable={expandable}
                    dataSource={this.state.cart.cartData.productsList} defaultExpandAllRows={true}
                    /*defaultExpandedRowKeys={expandList}*/
                    indentSize={24}
                    childrenColumnName="alternativeList"
                />
            </div>
        )
    }

    deleteProductConfirmationDialog(record) {

        let that = this;
        confirm({
            title: 'Eliminare il Prodotto',
            icon: <ExclamationCircleOutlined />,
            content: 'Sei sicuro di voler eliminare l\'articolo ' + record.precodice + '-' + record.codice + ' dal carrello?',
            onOk() {
                that.props.fetchRemoveFromCart(record);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    render() {

        if (this.state.cart.cartData.requstIsPending === true) {
            return (<Spin style={{ marginLeft: "49%" }} size="large" tip="Aggiornamento in corso..." />)
        }


        if (this.props.mode === "summary") {
            return this.renderCartSummary()
        }
        else if (this.props.mode === "cartSummaryInline") {
            return this.renderCartSummaryInline()
        }
        else {
            return this.renderCart()
        }

    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));