import React from 'react';
import { connect } from "react-redux";
import {moneyFormat} from "../../utils.js"
import {withRouter} from 'react-router-dom'
import { Input } from 'antd';
import { Table, Modal, Row, notification} from 'antd';
import { DatePicker, Space, AutoComplete, Select, Alert, Spin } from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';
import 'moment/locale/it'
import moment from 'moment'
import { StepBackwardOutlined, StepForwardOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './agentReport.css'
import richiesteReso from '../Resi/richiesteReso.js';
import { Card } from 'antd';
import { UserOutlined, FundProjectionScreenOutlined, PhoneOutlined  } from '@ant-design/icons';
import {getApiUrl} from '../../utils.js'

const { confirm } = Modal;
const { Option } = Select;

const { TextArea } = Input;
const mapStateToProps = state => {
    return { 
        init: state.init
    };
};
function mapDispatchToProps(dispatch) {
    return {
    };
}
class AgentReport extends React.Component {

    constructor(props) {

        super(props)
        /*
         * LOCAL STATE
        */
        this.state = {
            date: moment(),
            agentUrl : "",
            clients: [],
            allClients: [],

            /* MODAL STATE */ 
            showModal:false,
            isModify: false,
            modalId : 0,
            datePickerKey : 1,
            selectedClient: "",
            visitType: "Visita",
            uidCliente :  "",
            noteText : "",
            error: "",
            loading: false,
            visits: [],
            vId: "",

            init: this.props.init
        }
        this.onDateChange = this.onDateChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.onAdd = this.onAdd.bind(this)
        this.onClientSearch = this.onClientSearch.bind(this)
        this.onCloseError = this.onCloseError.bind(this)
        this.handleChangeType = this.handleChangeType.bind(this);
        this.onSelectClient = this.onSelectClient.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.prevDate = this.prevDate.bind(this);
        this.nextDate = this.nextDate.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onModify = this.onModify.bind(this);
        this.handleModify = this.handleModify.bind(this);
        this.getFullReport = this.getFullReport.bind(this);
    }

    componentDidMount() {        
        let aUrl = window.location.pathname.split("/").pop()
        
        this.setState({
            ...this.state,
            agentUrl:aUrl
        }, ()=> this.loadReport())

        let date = this.state.date.format('YYYY-MM-DD');

        fetch(getApiUrl() + "/salesman/getCustomersInfo/" + aUrl ,{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res);
            }

            let ris = res.data.map(elem =>{
                    var ret = {
                        label:elem.businessName, //+ " - " + elem.city,
                        value:elem.businessName, //+ " - " + elem.city,
                        id: elem.customerId
                    };
                    return ret
                 });
            
            let newClient = {
                label: 'NUOVO/POTENZIALE CLIENTE - NOME NELLE NOTE', 
                value: 'NUOVO/POTENZIALE CLIENTE - NOME NELLE NOTE', 
                id: 0
            }
            
            ris.push(newClient);

            this.setState({
                ...this.state,
                clients: ris,
                allClients : ris
            })
            
        })
        .catch(res => {
            this.setState({
                ...this.state,
                error: "Errore nel recuperare la lista dei clienti" + res
            })
        })
    }

    onDateChange(d){

        if(d===null){
            return 
        }

        this.setState({
            ...this.state,
            date: d
        }, () => { this.loadReport() } )
    }

    onCloseError(){
        this.setState({
            ...this.state,
            error: ""
        })
    }

    handleChangeType(e){
        this.setState({
            ...this.state,
            visitType : e
        })
    }

    handleSubmit(){
        
        let data = {
            vDate: this.state.date.format('YYYY-MM-DD'),
            vType: this.state.visitType,
            vText: this.state.noteText,
            uIdCliente: this.state.uidCliente,
            vId : this.state.vId
        }

        if(this.state.isModify){
            this.handleModify(data)
            return
        }


        let t = this
        fetch(getApiUrl() + "/salesman/postVisit/" + this.state.agentUrl, {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept" : 'application/json'
            }),
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.message);
            }
            
            let newModalId = t.state.modalId + 1;
            t.setState({
                modalId : newModalId,
                error: "",
                showModal:false,
            })

            this.loadReport()

        })
        .catch(error => {
            t.setState({
                ...this.state,
                error: error
            })
        })

    }


    handleModify(data){

        let t = this
        fetch(getApiUrl() + "/salesman/updateVisit/" + this.state.agentUrl, {
            credentials: "include",
            method: "PUT",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept" : 'application/json'
            }),
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.message);
            }
            
            let newModalId = t.state.modalId + 1;
            t.setState({
                modalId : newModalId,
                error: "",
                showModal:false,
            })

            this.loadReport()

            let config = {
                message: 'Operazione completata',
                description: 'L\'attività è stata modificata',
            };
            notification.success(config)


        })
        .catch(error => {
            let config = {
                message: 'Errore',
                description: 'Errore nell\'aggiornamento del report',
            };
            notification.error(config)
        })

    }


    handleCancel(){
        this.setState({
            ...this.state,
            showModal:false,
            modalId : this.state.modalId + 1,
            error : ""
        })
    }

    onAdd(){
        this.setState({
            ...this.state,
            showModal:true,
            modalId : this.state.modalId + 1,
            visitType: "Visita",
            uidCliente :  "",
            noteText : "",
            selectedClient:"",
            isModify:false,
            vId: "",
            uidCliente: ""
        })
    }

    onClientSearch = (searchText) => {
        searchText =  searchText.toUpperCase()
        if(searchText===""){
            this.setState({
                ...this.state,
                clients : this.state.allClients
            })
            return
        }

        let r = this.state.allClients.filter( elem => elem.label.replaceAll('.','').toUpperCase().includes(searchText) ? true : false )
        this.setState({
            ...this.state,
            clients : r
        })
    }
    
    onSelectClient(value,option){
        this.setState({
            ...this.state,
            uidCliente : option.id
        })
    }

    onInputChange(v){
        this.setState({
            ...this.state,
            noteText: v.target.value
        })
    }

    nextDate(){
        
        let nd = this.state.date.add(1,'days')
        console.log(nd.format("YYYY-MM-DD"))
        this.setState({
            ...this.state,
            date : nd,
            datePickerKey : this.state.datePickerKey + 1
        }, () => this.loadReport())

        
    }

    prevDate(){
        let nd = this.state.date.subtract(1,'days');
        console.log(nd.format("YYYY-MM-DD"))
        this.setState({
            ...this.state,
            date : nd,
            datePickerKey : this.state.datePickerKey + 1
        }, ()=> this.loadReport())

        
    }

    loadReport(){

        this.setState({
            ...this.state,
            loading:true
        })

        fetch(getApiUrl() + "/salesman/visit/" + this.state.agentUrl + "/" + this.state.date.format("YYYY-MM-DD"),{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.message);
            }
            
            this.setState({
                ...this.state,
                visits:res.data,
                loading:false
            })

        })
        .catch(error => {

            let config = {
                message: 'Errore',
                description: 'Errore nel recuperare la lista dei report',
            };
            notification.success(config)
        })
    }

    onDelete(item){

        fetch(getApiUrl() + "/salesman/deleteVisit/" + this.state.agentUrl + "/" + item.vId ,{
            method: 'DELETE', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res);
            }
            let newRis = this.state.visits.filter( (visit) => {
                if(visit.vId!==item.vId){
                    return true
                }else{
                    return false;
                }
            })
            this.setState({
                ...this.state,
                visits:newRis
            })

            let config = {
                message: 'Operazione completata',
                description: 'L\'attività è stata rimossa',
            };
            notification.success(config)

        })
        .catch(res => {
            let config = {
                message: 'Errore',
                description: 'Si è verificato un errore nell\'eliminare l\'attività',
            };
            notification.error(config)
        })


    }

    onModify(item){
        
        this.setState({
            ...this.state,
            showModal:true,
            noteText: item.vText,
            visitType : item.vType,
            isModify: true,
            selectedClient : item.ragSoc,
            vId : item.vId,
            uidCliente: item.uIdCliente
        })

    }

    getFullReport(){
        
        fetch(getApiUrl() + "/salesman/fullReport/" ,{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'text/plain'
            }),
        })
        .then((response) => response.blob())
        .then((blob) => {
           // Create blob link to download
           const url = window.URL.createObjectURL(
             new Blob([blob]),
           );
           const link = document.createElement('a');
           link.href = url;

           var d = new Date();
           let reportname =    "agent_report_" + d.getFullYear() + "_" + (d.getMonth()+1) + "_" + d.getDate() + "_" + d.getHours() + d.getMinutes() + ".csv"

           link.setAttribute( 'download', reportname,);
          
           // Append to html link element page
           document.body.appendChild(link);
          
           // Start download
           link.click();
          
           // Clean up and remove the link
           link.parentNode.removeChild(link);
        })
        .catch(error => {

            let config = {
                message: 'Errore',
                description: 'Errore nel recuperare la lista dei report',
            };
            notification.success(config)
        })

    }

    render(){
        return (
            
            <div>
                 <Modal okText="Invia Report" cancelText="Annulla" className="agentReport modal" key={this.state.modalId} title="Inserisci le note del contatto con il cliente" visible={this.state.showModal} onOk={this.handleSubmit} onCancel={this.handleCancel}>
                     <label>Digita il nome del cliente (senza punti)</label>
                     <AutoComplete defaultValue={this.state.selectedClient} options={this.state.clients} style={{ width: "95%", marginBottom:"1em" }} onSelect={this.onSelectClient} onSearch={this.onClientSearch} placeholder="Digita il nome del cliente" />
                     
                     <label>Tipo</label>
                     <Select defaultValue={this.state.visitType} style={{ width: "95%", marginBottom:"1em" }} onChange={this.handleChangeType} >
                        <Option value="Visita">Visita</Option>
                        <Option value="Telefonata">Telefonata</Option>
                        <Option value="Call conference">Call conference</Option>
                     </Select>

                     <label>Informazioni sulla visita</label>
                     <TextArea defaultValue={this.state.noteText} onChange={this.onInputChange} rows={5} style={{ width: "95%", marginBottom:"1em" }} />
                     
                     { this.state.error !== "" && (
                        <Alert message={this.state.error} type="error" closable onClose={this.onCloseError} />
                      )}

                </Modal>

                <Row>
                    <img className="agentReport logo" src={process.env.PUBLIC_URL + '/sirmec-logo_512_trasp.png'}></img>
                </Row>
                
                <div className="agentReport header">
                    <StepBackwardOutlined onClick={this.prevDate} style={{fontSize:"2em"}} className="item"/>
                    <DatePicker key={this.state.datePickerKey} value={this.state.date} locale={locale} size="large" format="DD/MM/YYYY" className="item" onChange={this.onDateChange} />
                    <StepForwardOutlined onClick={this.nextDate} style={{fontSize:"2em"}} className="item" />
                </div>
                <div className="agentReport header ant-picker-large">
                    <h2 className="item" style={{fontSize:"1.3em", margin: "0.2em",  fontWeight:900, color:"#1890ff"} }>Aggiungi report</h2>
                    <PlusCircleOutlined  style={{fontSize:"2.2em", margin: "0.2em",  fontWeight:900, color:"#1890ff" }} className="item" onClick={this.onAdd}/>
                </div>

                { this.state.init.userIsLogged && (
                    <div style={{width:"100%", margin:"auto"}}>
                        <p style={{textAlign:"center"}}>
                            <a onClick={this.getFullReport}> Scarica il report completo</a>
                        </p>
                   </div>
                )}
                
                {
                    this.state.loading && (
                        <Row>
                           <Spin style={{margin:"auto"}} size="large" />
                        </Row>
                    )
                }

                {   
                    this.state.visits.map( (item) => {
                    
                    let title = <div><span><UserOutlined /> &nbsp; {item.smName + " - " + item.vDate} &nbsp; </span> <p>{item.ragSoc}</p></div>
                    
                    if(item.vType==='Videocall'){
                        title = <div><span><FundProjectionScreenOutlined /> &nbsp; {item.smName + " - " + item.vDate} &nbsp; </span><p>{item.ragSoc}</p></div>
                    }else if(item.vType==='Telefonata'){
                        title = <div><span><PhoneOutlined /> &nbsp; {item.smName + " - " + item.vDate} &nbsp; </span><p>{item.ragSoc}</p></div>
                    }


                    let extraMenu = <div>
                                        <p><a onClick={()=>this.onModify(item)}>Modifica</a></p>
                                        <p><a onClick={()=>this.onDelete(item)}>Elimina</a></p>
                                    </div>
                    
                    return (
                    <Row>
                    <Card className="agentReport card body" title={title} extra={extraMenu} style={{ width: "100%" }}>
                        {item.vText}
                    </Card>
                    </Row>
                    )
                })
                }
            
            </div>

        )
    }

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AgentReport));