import {
    FETCH_SEARCH_PEND, FETCH_SEARCH_SUCC, FETCH_SEARCH_FAIL,
    FETCH_AUTH_PEND, FETCH_AUTH_SUCC, FETCH_AUTH_FAIL, FETCH_CART_PEND, FETCH_CART_SUCC, FETCH_CART_FAIL, FETCH_URGENT_CART_PEND, FETCH_URGENT_CART_SUCC, FETCH_URGENT_CART_FAIL,
    FETCH_UPDATE_QNT_RICH, CART_NOTE_UPD, CART_RIF_UPD, FETCH_SEND_ORDER_PEND, FETCH_SEND_ORDER_SUCC, FETCH_SEND_ORDER_FAIL, FETCH_SEND_ORDER_WARN,
    FETCH_ORDER_LIST_PEND, FETCH_ORDER_LIST_SUCC, FETCH_ORDER_LIST_FAIL, FIND_CROSS_REFERENCE_PEND, FIND_CROSS_REFERENCE_SUCC,
    FIND_CROSS_REFERENCE_FAIL, DELIVERY_DATE_PEND, DELIVERY_DATE_SUCC, DELIVERY_DATE_FAIL, FETCH_LOGOUT_SUCC,
    FETCH_ORDER_DETAIL_PEND, FETCH_ORDER_DETAIL_SUCC, FETCH_ORDER_DETAIL_FAIL, IAM_AVALAIBILITY_SUCC, IAM_AVALAIBILITY_FAIL,
    MISSING_PEND, MISSING_SUCC, MISSING_FAIL, FETCH_BOLLE_PEND, FETCH_BOLLE_SUCC, FETCH_BOLLE_FAIL, FETCH_FATT_FAIL, FETCH_FATT_SUCC,
    FETCH_FATT_PEND, SAVE_NOTE_CLIENTE, FETCH_USER_PROMO_PEND, FETCH_USER_PROMO_SUCC, FETCH_USER_PROMO_FAIL, FETCH_UPDATE_QNT_RICH_PROMO,
    FETCH_PROMO_DETAIL_PEND, FETCH_PROMO_DETAIL_SUCC, FETCH_PROMO_DETAIL_FAIL, FETCH_INIT_SUCC, FIND_CROSS_REFERENCE_PROMO_PEND,
    FIND_CROSS_REFERENCE_PROMO_SUCC, FIND_CROSS_REFERENCE_PROMO_FAIL, DELIVERY_DATE_PROMO_PEND, DELIVERY_DATE_PROMO_SUCC, DELIVERY_DATE_PROMO_FAIL,
    FADE_OUT_PRODUCT, REMOVE_PRODUCT_FROM_SEARCH, IAM_AVALAIBILITY_PROMO_SUCC, CLEAR_SEARCH_HISTORY, FETCH_BOLLE_CLEAR, SET_LOGIN_MESSAGE, INVALIDATE_ORDER_LIST,
    RESI_PEND, RESI_SUCC, RESI_FAIL, RESI_REQ_PEND, RESI_REQ_SUCC, RESI_REQ_FAIL, FETCH_BOLLE_DETAIL_CLEAR,
    FETCH_BOLLE_DETAIL_PEND, FETCH_BOLLE_DETAIL_SUCC, FETCH_BOLLE_DETAIL_FAIL, FETCH_BEHALF_PEND, FETCH_BEHALF_SUCC, FETCH_BEHALF_FAIL, FETCH_BEHALF_EXIT_SUCC,
    FETCH_ALTERNATIVE_SUCC, FETCH_ALTERNATIVE_ERR, FETCH_ALL_PROMO_SUCC, FETCH_ALL_PROMO_PEND, FETCH_ALL_PROMO_FAIL, FETCH_ORDINI_PEND, FETCH_ORDINI_SUCC, FETCH_ORDINI_FAIL


} from "./types";

import React from 'react';

import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getApiUrl } from '../utils.js'

export function fetchSearch(searchString) {

    return dispatch => {

        dispatch(fetchSearchPend());

        fetch(getApiUrl() + "/search?searchString=" + encodeURIComponent(searchString), {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                (res.data || []).forEach(function (element) {
                    //CLC products precodes are made of 4 chars: S plus 3 digit for product family, changed the usual condition to use a regex because will generate conflict with other precodes like SH (Shell)
                    if (element.sScope !== "OBSOLETE" && (element.precodice.match(/Q{1}\d{3}/) || element.precodice.match(/R{1}\d{3}/) || element.precodice.match(/S{1}\d{3}/))) {
                        element.IAMAvalaibilityPending = true;
                        dispatch(fetchIAMAvalaibility(element))
                    }

                    dispatch(fetchSearchAlternative(element))

                })
                dispatch(fetchSearchSucc(res.data));



                return res.data;
            })
            .catch(error => {
                dispatch(fetchSearchFail(error));
            })
    }
}
export function fetchSearchPend(data) {
    return { type: FETCH_SEARCH_PEND, data }
};
export function fetchSearchSucc(data) {
    return { type: FETCH_SEARCH_SUCC, data }
};
export function fetchSearchFail(data) {
    return { type: FETCH_SEARCH_FAIL, data }
};

export function clearSeachHistory() {
    return { type: CLEAR_SEARCH_HISTORY }
}


export function fetchSearchAlternative(el) {

    return dispatch => {

        console.log("FetchSearchAlternative for element:")
        console.log(el)

        fetch(getApiUrl() + "/search/suggestedProductList?precodice=" + el.precodice + "&codice=" + el.codice + "&scDecType=SOSTITUITO&scDecType=RIGENERATO", {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchSearchAlternativeSucc(el, res.data));
            })
            .catch(error => {
                dispatch(fetchSearchAlternativeFail(error));
            })
    }
}

export function fetchSearchAlternativeSucc(el, resp) {
    return { type: FETCH_ALTERNATIVE_SUCC, data: { ris: resp, source: el } }
}

export function fetchSearchAlternativeFail(err) {
    return { type: FETCH_ALTERNATIVE_ERR, err }
}


/* AUTHENTICATION */
export function fetchAuth(username, password, fp) {

    return dispatch => {

        /*https://redux.js.org/advanced/async-actions*/
        let par = new URLSearchParams(
            {
                'username': username,
                'password': password,
                'fp': fp,
            });

        dispatch(fetchAuthPend());

        return fetch(getApiUrl() + "/auth", {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            }),
            body: par
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }
                dispatch(fetchAuthSucc(res.data));
                return res.products;
            })
            .catch(res => {
                dispatch(fetchAuthFail(res));
            })
    }
};
export function fetchAuthPend(data) {
    return { type: FETCH_AUTH_PEND, data }
};
export function fetchAuthSucc(data) {
    return { type: FETCH_AUTH_SUCC, data }
};
export function fetchAuthFail(data) {
    return { type: FETCH_AUTH_FAIL, data }
};

/* BEHALF */
export function fetchBehalf(uid) {

    return dispatch => {

        dispatch(fetchBehalfPend());

        return fetch(getApiUrl() + "/auth/behalf/" + uid, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }
                dispatch(fetchBehalfSucc(res.data));
                dispatch(fetchCart());
                return res.products;
            })
            .catch(res => {
                dispatch(fetchBehalfFail(res.message));
            })
    }
};
export function fetchBehalfPend(data) {
    return { type: FETCH_BEHALF_PEND, data }
};
export function fetchBehalfSucc(data) {
    return { type: FETCH_BEHALF_SUCC, data }
};
export function fetchBehalfFail(data) {
    return { type: FETCH_BEHALF_FAIL, data }
};

export function fetchExitBehalf() {

    return dispatch => {

        return fetch(getApiUrl() + "/auth/behalf/exit", {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }
                dispatch(fetchBehalfExitSucc(res.data));
                dispatch(fetchCart());

                window.location.href = '/home';

                return res.products;
            })
            .catch(res => {
                message.error("Errore nell'abbandonare l'impersonificazione: " + res.message)
            })
    }
};

export function fetchBehalfExitSucc(data) {
    return { type: FETCH_BEHALF_EXIT_SUCC, data }
};


/* token is optional */
export function fetchCheckLogin(token) {

    let tokenQuery = ""
    if (token !== undefined) {
        tokenQuery = "?token=" + token
    }

    return dispatch => {
        fetch(getApiUrl() + "/auth/check" + tokenQuery, {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/x-www-form-urlencoded',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchAuthSucc(res.data));
                return res.data;
            })
            .catch(error => {
                dispatch(fetchAuthFail({}));
            })
    }
}

export function setLoginMessage(data) {
    return { type: SET_LOGIN_MESSAGE, data }
}


/* CART */
export function fetchCart(data) {

    return dispatch => {
        dispatch(fetchCartPend());

        fetch(getApiUrl() + "/cart/myCart", {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                dispatch(fetchCartSucc(res.data));
                return res.data;
            })
            .catch(error => {
                dispatch(fetchCartFail(error));
            })
    }
};



export function fetchUrgentOrder() {

    return dispatch => {

        dispatch(fetchUrgentCartPend());

        fetch(getApiUrl() + "/cart/urgentOrder", {
            method: 'POST',
            cache: 'no-cache',
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }
                if (JSON.parse(localStorage.getItem("isPriorityOrder")) === "false" || JSON.parse(localStorage.getItem("isPriorityOrder")) === undefined) {
                    message.success("Carrello aggiornato con successo!")
                    localStorage.setItem("isPriorityOrder", JSON.stringify("true"));

                }
                dispatch(fetchUrgentCartSucc(res.data));



                return res.data;
            })
            .catch(error => {
                dispatch(fetchUrgentCartFail(error));
                localStorage.setItem("isPriorityOrder", "false")
                message.error(error.message + " non disponibile per ordini urgenti")

            })
    }

};

export function fetchUrgentCartPend(data) {
    return { type: FETCH_URGENT_CART_PEND, data }
};
export function fetchUrgentCartSucc(data) {
    return { type: FETCH_URGENT_CART_SUCC, data }
};
export function fetchUrgentCartFail(data) {
    return { type: FETCH_URGENT_CART_FAIL, data }
};






/* REMOVE TO CART */
export function fetchRemoveFromCart(data) {

    return dispatch => {

        dispatch(fetchCartPend());

        fetch(getApiUrl() + "/cart/remFromCart", {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }),
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchCartSucc(res.data));
                return res.data;
            })
            .catch(error => {
                dispatch(fetchCartFail(error));
            })
    }
};

export function fetchCartPend(data) {
    return { type: FETCH_CART_PEND, data }
};
export function fetchCartSucc(data) {
    return { type: FETCH_CART_SUCC, data }
};
export function fetchCartFail(data) {
    return { type: FETCH_CART_FAIL, data }
};

export function updateCartNote(data) {
    return { type: CART_NOTE_UPD, data }
}

export function updateCRifOrdCli(data) {
    return { type: CART_RIF_UPD, data }
}

/* NEW CART */
export function fetchNewCart(data) {

    return dispatch => {

        dispatch(fetchCartPend());

        fetch(getApiUrl() + "/cart/closeCart", {
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchCartSucc(res.data));
                message.success("Il carrello è stato svuotato!")
                return res.data;
            })
            .catch(error => {
                dispatch(fetchCartFail(error));
            })
    }
};


/* ADD TO CART */
export function fetchAddToCart(product) {

    const { confirm } = Modal;

    return dispatch => {

        const alertMessage = (
            <div>
                <p>Il prodotto che hai aggiunto al carrello è codificato. Per favore inserisci un VIN nel campo note cliccando sull'immagine &nbsp; &nbsp;
                    <img alt="Icona Messaggio" style={{ width: "1.5em" }} src="/icon/icons8-nota-48.png" />
                </p>
            </div>
        );

        fetch(getApiUrl() + "/cart/addToCart", {
            method: 'POST',
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }),
            body: JSON.stringify(product)
        })
            .then(res => res.json())
            .then(res => {

                if (res.error) {

                    /* */
                    if (res.errorCode === 7) {
                        confirm(
                            {
                                title: 'Attenzione articolo codificato',
                                icon: <ExclamationCircleOutlined />,
                                content: alertMessage,
                                okButtonProps: { disabled: false },
                                cancelButtonProps: { disabled: true, hidden: true }
                            }
                        )
                        return;
                    }
                    else {
                        throw (res.error);
                    }
                }

                dispatch(fetchCartSucc(res.data));
                dispatch(fadeOutProduct(product));

                message.success("Prodotto aggiunto al carrello");

                setTimeout(() => {
                    dispatch(removeProductFromSearch(product))
                }, 2500)

                return res.data;
            })
            .catch(error => {
                message.error("Errore nell'aggiunta del prodotto al carrello");
            })
    }
};


export function fadeOutProduct(product) {
    return { type: FADE_OUT_PRODUCT, data: product }
}

export function removeProductFromSearch(product) {
    return { type: REMOVE_PRODUCT_FROM_SEARCH, data: product }
}


export function fetchUpdateQntToCart(product, value) {

    return dispatch => {

        product.qntRichiesta = value;

        fetch(getApiUrl() + "/cart/addToCart?append=false", {
            method: 'POST',
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }),
            body: JSON.stringify(product)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchCartSucc(res.data));
                message.success("Carrello aggiornato");
                return res.data;
            })
            .catch(error => {
                message.error("Errore nell'aggiornamento del carrello");
            })
    }
};

/* UPDATE QUANTITITA RICHIESTA */
export function fetchUpdateQntRichiesta(product, value) {

    let data = {
        product: product,
        value: value
    }
    return { type: FETCH_UPDATE_QNT_RICH, data }
}

/* UPDATE QUANTITITA RICHIESTA FOR PROMO PRODUCT */
export function fetchUpdateQntRichiestaPromo(product, value, promoId) {

    let data = {
        product: product,
        value: value,
        promoId: promoId
    }
    return { type: FETCH_UPDATE_QNT_RICH_PROMO, data }
}

export function fetchUpdateCart(product, value) {

    return dispatch => {

        fetch(getApiUrl() + "/cart/addToCart", {
            method: 'POST',
            credentials: "include",
            headers: new Headers({
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }),
            body: JSON.stringify(product)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchCartSucc(res.data));
                message.success("Prodotto aggiunto al carrello");
                return res.data;
            })
            .catch(error => {
                message.error("Errore nell'aggiunta del prodotto al carrello");
            })
    }
}

export function fetchUpdateNotes(notes, rifCli) {
    return dispatch => {

        if (notes === null || notes === undefined) {
            notes = ""
        }

        if (rifCli === null || rifCli === undefined) {
            rifCli = ""
        }

        let par = new URLSearchParams(
            {
                'cartNotes': notes,
                'cRifOrdCli': rifCli
            });

        fetch(getApiUrl() + "/cart/addCartNotes", {
            credentials: "include",
            method: "POST",
            body: par,
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            })

        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchCartSucc(res.data));
                message.success("Le note sono state salvate")
                return res.data;
            })
            .catch(error => {
                message.error("C'è stato un errore nel salvataggio delle note");
            })
    }
}

/* ORDER */

export function fetchSendOrderPend() {
    return { type: FETCH_SEND_ORDER_PEND }
}

export function fetchSendOrderSucc() {
    return { type: FETCH_SEND_ORDER_SUCC }
}

export function fetchSendOrderFail() {
    return { type: FETCH_SEND_ORDER_FAIL }
}

export function fetchSendOrderWarn() {
    return { type: FETCH_SEND_ORDER_WARN }
}

export function fetchSendOrder(notes, rifCli) {

    return dispatch => {

        let par = new URLSearchParams(
            {
                'cartNotes': notes,
                'cRifOrdCli': rifCli
            });

        dispatch(fetchSendOrderPend());
        fetch(getApiUrl() + "/cart/cartCheckout", {
            credentials: "include",
            method: "POST",
            body: par,
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }

                if (res.data.hasWarning !== undefined && res.data.hasWarning === true) {
                    dispatch(fetchSendOrderWarn())
                    Modal.confirm({
                        title: 'Ordine non inviato',
                        content: (res.data.warningMessage),
                        style: { whiteSpace: "pre-line" },
                        okText: "Aggiorna il carrello",
                        cancelText: "Chiudi senza modifiche",
                        onOk: function () {
                            dispatch(fetchUpdateCartProduct())
                        }
                    });
                } else {

                    const Link = () => {
                        let webOrderCode = res.data.webOrderCode;

                        if (webOrderCode !== null && webOrderCode !== undefined) {
                            let tipoDoc = res.data.webOrderCode.split('-')[0]
                            let numeroDoc = res.data.webOrderCode.split('-')[1]
                            let year = new Date().getFullYear()

                            return (
                                <div>
                                    <p>Il numero d'ordine è  {webOrderCode} </p>
                                    <a href={getApiUrl() + "/orders/orderDetails/pdf/" + tipoDoc + "/" + numeroDoc + "/" + year} target="_blank">
                                        STAMPA ORDINE
                                        <img style={{ width: "2em", height: "2em", color: "green" }} src={process.env.PUBLIC_URL + '/icon/icons8-invia-alla-stampante-48-pronta.png'}></img>
                                    </a>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div>
                                    <p>Il tuo ordine è stato preso in carico.</p>
                                    <p>Il numero d'ordine non è ancora disponibile, potrai trovarlo e stampare a breve utilizzando il menu "Storico Ordini" </p>
                                </div>
                            );
                        }
                    }


                    Modal.info({
                        title: 'Ordine inviato con successo',
                        content: (<Link />)
                    });

                    dispatch(fetchSendOrderSucc());
                    dispatch(invalidateOrderList())
                }
                localStorage.removeItem("isPriorityOrder")
                return res.data;
            })
            .catch(error => {
                dispatch(fetchSendOrderFail());

                let msg = error.message || ""

                message.error("C'è stato un errore nell'invio dell'ordine. " + msg);
            })
    }
}

export function invalidateOrderList() {
    return { type: INVALIDATE_ORDER_LIST }
}

export function fetchUpdateCartProduct() {

    return dispatch => {

        /* 
        Dispatch this state in order to reset cart to initial state
        in order to force refresh
        */
        dispatch(fetchSendOrderSucc());

        fetch(getApiUrl() + "/cart/updateCartChanges", {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json',
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res);
                }
                message.success("Carrello aggiornato correttamente, è ora possibile procedere con l'ordine", 12);
                dispatch(fetchCartSucc(res.data));
            })
            .catch(error => {
                let msg = error.message || ""
                message.error("C'è stato un errore nell'aggiornamento dell carrello. " + msg);
            })
    }
}
// ORDER FILTER
export function fetchOrdini(last, filter) {

    let qs = ""
    if (last != null) {
        qs = "?startFrom=" + last
    }

    return dispatch => {



        dispatch(fetchOrdiniPend());

        fetch(getApiUrl() + "/orders/lastOrdersPost" + qs, {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            }),
            body: JSON.stringify(filter)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                if (res.data.length !== 0) {
                    var data = {
                        data: res.data,
                        lastReq: last,
                        last: res.pagination.last

                    }
                    dispatch(fetchOrdiniSucc(data));
                }

            })
            .catch(error => {
                dispatch(fetchOrdiniFail());
                message.error("C'è stato un problema nel recuperare gli ordini");
            })
    }
}


export function fetchOrdiniPend() {
    return { type: FETCH_ORDINI_PEND }
}
export function fetchOrdiniSucc(dati) {
    return { type: FETCH_ORDINI_SUCC, data: dati }
}
export function fetchOrdiniFail() {
    return { type: FETCH_ORDINI_FAIL }
}




/* ORDER LIST */
export function fetchOrderListPend() {
    return { type: FETCH_ORDER_LIST_PEND }
}

export function fetchOrderListSucc(data) {
    return { type: FETCH_ORDER_LIST_SUCC, data: data }
}

export function fetchOrderListFail() {
    return { type: FETCH_ORDER_LIST_FAIL }
}

export function fetchOrderList(last) {

    return dispatch => {

        let qs = ""
        if (last != null) {
            qs = "?startFrom=" + last
        }

        dispatch(fetchOrderListPend());
        fetch(getApiUrl() + "/orders/lastOrders" + qs, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                if (res.data.length !== 0) {
                    var data = {
                        data: res.data,
                        lastReq: last,
                        last: res.pagination.last
                    }
                    dispatch(fetchOrderListSucc(data));

                }

            })
            .catch(error => {
                dispatch(fetchSendOrderFail());
                message.error("C'è stato un problema nel recuperare gli ordini");
            })
    }
}

/* ORDER DETAILS */
export function fetchOrderDetail(orderSummary) {
    return dispatch => {

        dispatch(fetchOrderDetailPend(orderSummary));
        fetch(getApiUrl() + "/orders/orderDetails", {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            }),
            body: JSON.stringify(orderSummary)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                let data = {
                    data: res.data,
                    item: orderSummary
                }
                dispatch(fetchOrderDetailSucc(data));
            })
            .catch(error => {
                dispatch(fetchOrderDetailFail(orderSummary));
                message.error("C'è stato un problema nel recuperare il dettaglio degli ordini");
            })
    }
}
export function fetchOrderDetailPend(orderSummary) {
    return { type: FETCH_ORDER_DETAIL_PEND, data: orderSummary }
}
export function fetchOrderDetailSucc(data) {
    return { type: FETCH_ORDER_DETAIL_SUCC, data: data }
}
export function fetchOrderDetailFail(orderSummary) {
    return { type: FETCH_ORDER_DETAIL_FAIL, data: orderSummary }
}

/* CROSS REFERENCE */
export function fetchCrossReferencePend(product) {
    return { type: FIND_CROSS_REFERENCE_PEND, data: product }
}

export function fetchCrossReferenceSucc(product) {
    return { type: FIND_CROSS_REFERENCE_SUCC, data: product }
}

export function fetchCrossReferenceFail(product) {
    return { type: FIND_CROSS_REFERENCE_FAIL, data: product }
}
/* CROSS REFERENCE FOR PROMO PRODUCT*/
export function fetchCrossReferencePromoPend(product, promoId) {
    return { type: FIND_CROSS_REFERENCE_PROMO_PEND, data: { productToDecode: product, promoId: promoId } }
}

export function fetchCrossReferencePromoSucc(data) {
    return { type: FIND_CROSS_REFERENCE_PROMO_SUCC, data: data }
}

export function fetchCrossReferencePromoFail(product, promoId) {
    return { type: FIND_CROSS_REFERENCE_PROMO_FAIL, data: { productToDecode: product, promoId: promoId } }
}



export function fetchCrossReference(product) {

    return dispatch => {
        dispatch(fetchCrossReferencePend(product));

        fetch(getApiUrl() + "/iam/alternatives?codice=" + product.codice, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                (res.data || []).forEach(function (element) {

                    if (element.precodice.startsWith('Q') || element.precodice.startsWith('R') || element.precodice.startsWith('S')) {
                        element.IAMAvalaibilityPending = true;
                        dispatch(fetchIAMAvalaibility(element))
                    }
                })

                let data = {
                    data: res.data,
                    productToDecode: product
                }
                dispatch(fetchCrossReferenceSucc(data));
            })
            .catch(error => {
                let data = {
                    data: null,
                    productToDecode: product
                }
                dispatch(fetchCrossReferenceFail(data));
                message.error("C'è stato un problema nel recuperare i codici equivalenti");
            })
    }
}

export function fetchCrossReferencePromo(product, promoId) {

    return dispatch => {
        dispatch(fetchCrossReferencePromoPend(product, promoId));

        fetch(getApiUrl() + "/iam/alternatives?codice=" + product.codice, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                (res.data || []).forEach(function (element) {

                    if (element.precodice.startsWith('Q') || element.precodice.startsWith('R') || element.precodice.startsWith('S')) {
                        element.IAMAvalaibilityPending = true;
                        dispatch(fetchIAMAvalaibility(element, promoId))
                    }
                })

                let data = {
                    data: res.data,
                    productToDecode: product,
                    promoId: promoId
                }
                dispatch(fetchCrossReferencePromoSucc(data));
            })
            .catch(error => {
                let data = {
                    data: null,
                    productToDecode: product
                }
                dispatch(fetchCrossReferencePromoFail(data, promoId));
                message.error("C'è stato un problema nel recuperare i codici equivalenti");
            })
    }
}


export function fetchDeliveryDate(product) {
    let precodice = product.precodice;
    let codice = product.codice
    return dispatch => {

        dispatch(fetchDeliveryDatePend(product));
        fetch(getApiUrl() + "/parts/availability?preCode="+precodice+"&productCode="+codice, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
            
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                let data = {
                    ris: res.data,
                    record: product
                }
                dispatch(fetchDeliveryDateSucc(data));
            })
            .catch(error => {
                let data = {
                    data: null,
                    productToDecode: product
                }
                dispatch(fetchDeliveryDateFail(data));
                message.error("C'è stato un problema nel recuperare le previsioni di arrivo");
            })
    }
}
export function fetchDeliveryDatePend(product) {
    return { type: DELIVERY_DATE_PEND, data: product }
}
export function fetchDeliveryDateSucc(product) {
    return { type: DELIVERY_DATE_SUCC, data: product }
}
export function fetchDeliveryDateFail(product) {
    return { type: DELIVERY_DATE_FAIL, data: product }
}

/* FETCH DELIVERY DATE PROMO */
export function fetchDeliveryDatePromo(product, promoId) {

    return dispatch => {
        let precodice = product.precodice;
        let codice = product.codice;
        dispatch(fetchDeliveryDatePromoPend(product, promoId));
        fetch(getApiUrl() + "/parts/availability?preCode="+precodice+"&productCode="+codice, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                let data = {
                    ris: res.data,
                    record: product,
                    promoId: promoId
                }
                dispatch(fetchDeliveryDatePromoSucc(data));
            })
            .catch(error => {
                let data = {
                    data: null,
                    record: product,
                    promoId: promoId
                }
                dispatch(fetchDeliveryDatePromoFail(data));
                message.error("C'è stato un problema nel recuperare le previsioni di arrivo");
            })
    }
}
export function fetchDeliveryDatePromoPend(product, promoId) {
    return { type: DELIVERY_DATE_PROMO_PEND, data: { product: product, promoId: promoId } }
}
export function fetchDeliveryDatePromoSucc(data) {
    return { type: DELIVERY_DATE_PROMO_SUCC, data: data }
}
export function fetchDeliveryDatePromoFail(data) {
    return { type: DELIVERY_DATE_PROMO_FAIL, data: data }
}


/* IAM AVALAIBILITY */
export function fetchIAMAvalaibilitySucc(record, avalaibility) {
    return { type: IAM_AVALAIBILITY_SUCC, data: { record: record, avalaibility: avalaibility } }
}
export function fetchIAMAvalaibilityFail(record) {
    return { type: IAM_AVALAIBILITY_FAIL, data: { record: record } }
}

export function fetchIAMAvalaibilityPromoSucc(record, promoId, avalaibility) {
    return { type: IAM_AVALAIBILITY_PROMO_SUCC, data: { record: record, promoId: promoId, avalaibility: avalaibility } }
}

export function fetchIAMAvalaibility(record, promoId) {

    //Note: promoId can be null

    return dispatch => {
        //dispatch(fetchIAMAvalaibilityPend(record));

        let url = ""
        if (record.precodice.match(/Q{1}\d{3}/)) {
            url = "/AML/productAvailability?makerCode=" + record.precodice + "&productCode=" + encodeURIComponent(record.codice)
        } else if (record.precodice.match(/R{1}\d{3}/)) {
            url = "/CLL/productAvailability?makerCode=" + record.precodice + "&productCode=" + encodeURIComponent(record.codice)
        }
        //CLC products precodes are made of 4 chars: S plus 3 digit for product family, changed the usual condition to use a regex because will generate conflict with other precodes like SH (Shell)
        else if (record.precodice.match(/S{1}\d{3}/)) {
            url = "/CLC/productAvailability?makerCode=" + record.precodice + "&productCode=" + encodeURIComponent(record.codice)
        }

        fetch(getApiUrl() + url, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {

                if (!promoId) {
                    dispatch(fetchIAMAvalaibilitySucc(record, res));
                } else {
                    dispatch(fetchIAMAvalaibilityPromoSucc(record, promoId, res));
                }

            })
    }
}






/* MISSING ARTICLES AND FORECAST DATES */
export function fetchMissingArticles(startFromInp) {

    let startFrom = ""
    if (startFromInp) {
        startFrom = "?startFrom=" + startFromInp
    }

    return dispatch => {
        dispatch(fetchMissingArticlesPend());

        fetch(getApiUrl() + "/outofstock" + startFrom, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                //add startFromInp to res
                res.startFrom = startFromInp

                dispatch(fetchMissingArticlesSucc(res));

                if (res.data.length > 0) {
                    let startFrom = res.pagination.last;
                    dispatch(fetchMissingArticles(startFrom))
                }

            })
            .catch(error => {
                dispatch(fetchMissingArticlesFail());
                message.error("C'è stato un problema nel recuperare le previsioni di arrivo per i mancanti");
            })
    }
}
export function fetchMissingArticlesPend() {
    return { type: MISSING_PEND }
}
export function fetchMissingArticlesSucc(ris) {
    return { type: MISSING_SUCC, data: ris }
}
export function fetchMissingArticlesFail() {
    return { type: MISSING_FAIL }
}

/* BOLLE */
export function fetchBolle(last, filter) {

    let qs = ""
    if (last != null) {
        qs = "?startFrom=" + last
    }

    return dispatch => {

        //if is the first request, clear data 

        if (last === null) {
            dispatch(fetchBolleClear())
        }

        dispatch(fetchBollePend());

        fetch(getApiUrl() + "/documents/getDdts" + qs, {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            }),
            body: JSON.stringify(filter)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchBolleSucc(res));
            })
            .catch(error => {
                dispatch(fetchBolleFail());
                message.error("C'è stato un problema nel recuperare le bolle");
            })
    }
}
export function fetchBollePend() {
    return { type: FETCH_BOLLE_PEND }
}
export function fetchBolleSucc(ris) {
    return { type: FETCH_BOLLE_SUCC, data: ris }
}
export function fetchBolleFail() {
    return { type: FETCH_BOLLE_FAIL }
}

export function fetchBolleDetail(last, filter, callNumber) {

    if (callNumber === undefined || callNumber === null) {
        callNumber = 1;
    }

    let qs = ""
    if (last != null) {
        qs = "?startFrom=" + last
    }

    return dispatch => {

        //if is the first request, clear data 

        if (last === null) {
            dispatch(fetchBolleDetailClear())
        }

        dispatch(fetchBolleDetailPend());

        fetch(getApiUrl() + "/documents/getDdtsDetails" + qs, {
            credentials: "include",
            method: "POST",
            headers: new Headers({
                "Accept": 'application/json',
                "Content-Type": 'application/json'
            }),
            body: JSON.stringify(filter)
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                dispatch(fetchBolleDetailSucc(res));

                if (res.data.length > 0 && callNumber < 10) {
                    let startFrom = res.pagination.last;
                    callNumber = callNumber + 1;
                    dispatch(fetchBolleDetail(startFrom, filter, callNumber))
                }

            })
            .catch(error => {
                dispatch(fetchBolleDetailFail());
                message.error("C'è stato un problema nel recuperare le bolle");
            })
    }
}
export function fetchBolleDetailPend() {
    return { type: FETCH_BOLLE_DETAIL_PEND }
}
export function fetchBolleDetailSucc(ris) {
    return { type: FETCH_BOLLE_DETAIL_SUCC, data: ris }
}
export function fetchBolleDetailFail() {
    return { type: FETCH_BOLLE_DETAIL_FAIL }
}

export function fetchBolleClear() {
    return { type: FETCH_BOLLE_CLEAR }
}

export function fetchBolleDetailClear() {
    return { type: FETCH_BOLLE_DETAIL_CLEAR }
}

export function fetchFatture(last) {

    let qs = ""
    if (last != null) {
        qs = "?startFrom=" + last
    }

    return dispatch => {
        dispatch(fetchFatturePend());

        fetch(getApiUrl() + "/documents/getInvoices" + qs, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchFattureSucc(res));
            })
            .catch(error => {
                dispatch(fetchFattureFail());
                message.error("C'è stato un problema nel recuperare le bolle");
            })
    }
}
export function fetchFatturePend() {
    return { type: FETCH_FATT_PEND }
}
export function fetchFattureSucc(ris) {
    return { type: FETCH_FATT_SUCC, data: ris }
}
export function fetchFattureFail() {
    return { type: FETCH_FATT_FAIL }
}


/* LOGOUT */
export function fetchLogout(username, password) {

    return dispatch => {
        localStorage.setItem("primaVolta", "false");

        return fetch(getApiUrl() + "/auth/logout", {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept": 'application/json'
            }),
        })
            .then(res => {

                dispatch(fetchLogoutSucc(res.data));

            })
            .catch(res => {
                message.error("Errore durante la disconnessione");
            })

    }

};
export function fetchLogoutSucc() {
    return { type: FETCH_LOGOUT_SUCC }
};

export function saveNoteCliente(record, noteClienteText, context) {

    let data = {
        record: record,
        noteCliente: noteClienteText,
        context: context
    }

    /* DO NOT CALL ANY SERVICE */
    if (context === 'SEARCH') {
        return { type: SAVE_NOTE_CLIENTE, data }
    }
    /* IF PRODUCT IS ON CART - UPDATE DATABASE */
    else if (context === 'CART') {
        return dispatch => {
            fetch(getApiUrl() + "/cart/updateNoteCliente?precodice=" + record.precodice + "&codice=" + record.codice + "&noteCliente=" + noteClienteText, {
                credentials: "include",
                method: "POST",
                headers: new Headers({
                    "Accept": 'application/json',
                    "Content-Type": 'application/json'
                })
            })
                .then(res => {
                    if (res.ok) {
                        dispatch(saveNoteClienteSucc(data));
                    } else {
                        throw "error"
                    }
                })
                .catch(res => {
                    message.error("Errore nell'aggiornamento della nota");
                })

            return { type: SAVE_NOTE_CLIENTE, data }
        }
    }
}

export function saveNoteClienteSucc(data) {
    return { type: SAVE_NOTE_CLIENTE, data }
}

/* FETCH USER PROMO */
export function fetchUserPromo() {

    return dispatch => {
        dispatch(fetchUserPromoPend());

        fetch(getApiUrl() + "/promo/getPromoList", { //back
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchUserPromoSucc(res.data));

            })
            .catch(error => {
                dispatch(fetchUserPromoFail());
                message.error("C'è stato un problema nel recuperare le promo");
            })
    }
}
/* FETCH ALL PROMO */
export function fetchAllPromo() {

    return dispatch => {
        dispatch(fetchAllPromoPend());

        fetch(getApiUrl() + "/promo/getPromoList?filterPromoActive=false", { //QUI RESTITUISCE LE PROMO VECCHIE ANCHE ?filterPromoActive=false
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchAllPromoSucc(res.data));


            })
            .catch(error => {
                dispatch(fetchAllPromoFail());
                message.error("C'è stato un problema nel recuperare le promo");
            })
    }
}

export function fetchAllPromoSucc(ris) {
    return { type: FETCH_ALL_PROMO_SUCC, data: ris }
}

export function fetchAllPromoPend() {
    return { type: FETCH_ALL_PROMO_PEND }
}

export function fetchAllPromoFail() {
    return { type: FETCH_ALL_PROMO_FAIL }
}

export function fetchUserPromoPend() {
    return { type: FETCH_USER_PROMO_PEND }
}
export function fetchUserPromoSucc(ris) {
    return { type: FETCH_USER_PROMO_SUCC, data: ris }
}
export function fetchUserPromoFail() {
    return { type: FETCH_USER_PROMO_FAIL }
}

/* PROMO DETAIL */
export function fetchPromoDetail(promoId, startFrom) {

    console.log("Calling Promo Details for promoId: " + promoId)
    return dispatch => {
        dispatch(fetchPromoDetailPend(promoId));

        fetch(getApiUrl() + "/promo/getPromoDetail?proId=" + promoId + "&startFrom=" + startFrom, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }


                //if there is other results call another time
                if (res.data.promoProducts.length > 0) {
                    let startFrom = res.pagination.last;
                    console.log("There was results in response. Call another time the service with startFrom: " + startFrom);
                    dispatch(fetchPromoDetail(promoId, startFrom))


                }


                (res.data.promoProducts || []).forEach(function (element) {

                    if (element.precodice.startsWith('Q') || element.precodice.startsWith('R') || element.precodice.startsWith('S')) {
                        element.IAMAvalaibilityPending = true;
                        dispatch(fetchIAMAvalaibility(element, promoId))
                    }
                })

                dispatch(fetchPromoDetailSuccess(promoId, res.data, startFrom));

                console.log(res.data)
            })
            .catch(error => {
                message.error("C'è stato un problema nel recuperare i dettagli della promo");
                console.log(error)
                dispatch(fetchPromoDetailFail());
            })
    }
}





export function fetchPromoDetailPend(promoId) {
    return {
        type: FETCH_PROMO_DETAIL_PEND,
        data: promoId
    }
}
export function fetchPromoDetailSuccess(promoId, promo, startFrom) {
    return {
        type: FETCH_PROMO_DETAIL_SUCC,
        data: {
            promoId: promoId,
            promo: promo,
            startFrom: startFrom
        }
    }
}
export function fetchPromoDetailFail() {
    return { type: FETCH_PROMO_DETAIL_FAIL }
}

/* INITIALIZATION */

export function fetchInit() {

    return dispatch => {
        fetch(getApiUrl() + "/apiVersion/", {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }
                dispatch(fetchInitSuccess(res.data));
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchInitSuccess(null));
            })
    }
}

export function fetchInitSuccess(data) {
    return {
        type: FETCH_INIT_SUCC,
        data: data
    }
}

export function fetchResi(startFromInp, yearInp) {

    let baseURL = getApiUrl() + "/returns/getList";
    let url = new URL(baseURL);
    let params = url.searchParams;

    if (startFromInp) {
        params.append("startFrom", startFromInp);
    }

    if (!yearInp) {
        params.append("year", new Date().getFullYear());
    } else {
        params.append("year", yearInp);
    }

    return dispatch => {
        dispatch(fetchResiPend());

        fetch(url.toString(), {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                //add startFromInp to res
                res.startFrom = startFromInp

                dispatch(fetchResiSucc(res));

                if (res.data.length > 0) {
                    let startFrom = res.pagination.last;
                    dispatch(fetchResi(startFrom, yearInp))
                }

            })
            .catch(error => {
                dispatch(fetchResiFail());
                message.error("C'è stato un problema nel recuperare i resi ");
            })
    }
}
export function fetchResiPend() {
    return { type: RESI_PEND }
}
export function fetchResiSucc(ris) {
    return { type: RESI_SUCC, data: ris }
}
export function fetchResiFail() {
    return { type: RESI_FAIL }
}

export function fetchResiReq(startFromInp, yearInp, pageLimit) {

    console.log("---> called fetchResiReq with parameter startFromInp:" + startFromInp + " and yearInp:" + yearInp);

    let baseURL = getApiUrl() + "/returns/getAllReturnRequests";
    let url = new URL(baseURL);
    let params = url.searchParams;

    if (startFromInp) {
        params.append("startFrom", startFromInp);
    }

    if (!yearInp) {
        params.append("year", new Date().getFullYear());
    } else {
        params.append("year", yearInp);
    }

    return dispatch => {
        dispatch(fetchResiReqPend());

        fetch(url.toString(), {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept": 'application/json'
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw (res.error);
                }

                //add startFromInp to res
                res.startFrom = startFromInp

                dispatch(fetchResiReqSucc(res));

                if (res.data.length > 0 && pageLimit > 0) {
                    let startFrom = res.pagination.last;
                    dispatch(fetchResiReq(startFrom, yearInp, pageLimit - 1))
                }

            })
            .catch(error => {
                dispatch(fetchResiReqFail());
                message.error("C'è stato un problema nel recuperare i resi ");
            })
    }
}
export function fetchResiReqPend() {
    return { type: RESI_REQ_PEND }
}
export function fetchResiReqSucc(ris) {
    return { type: RESI_REQ_SUCC, data: ris }
}
export function fetchResiReqFail() {
    return { type: RESI_REQ_FAIL }
}
