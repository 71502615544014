import React from 'react';
import { connect } from "react-redux";
import {moneyFormat} from "../../utils.js"
import {fetchOrderList, fetchOrderDetail, fetchOrdini} from "../../actions/actions.js"
import {Avatar, Spin, List, Button, Skeleton, Badge, Progress, Col} from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import ActionBar from '../ActionBar/actionBar.js'
import {withRouter} from 'react-router-dom'
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import { Collapse, Table } from 'antd';
import {getApiUrl} from '../../utils.js'
import {PDFDownloadLink} from '@react-pdf/renderer';
import {PrintOrder, CreatePDF} from '../../print.js';
import {SyncOutlined} from '@ant-design/icons';
import  OrderFilter from '../Filter/OrderFilter.js'
import './orderList.css'


const mapStateToProps = state => {
    return { 
        user: state.user,
        orderList : state.orderList
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchOrderList : (last) => dispatch(fetchOrderList(last)),
        fetchOrderDetail : (item) => dispatch(fetchOrderDetail(item)),
        fetchOrdini : (last, filter) => dispatch(fetchOrdini(last, filter)),
    }
}

class OrderList extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            user: this.props.user,
            filter: {},
            isFilter: false,
            orderList: this.props.orderList,
            collapseText : "Nascondi i filtri",
            textShow : "Mostra i filtri",
            textHidden : "Nascondi i filtri",
           
        }

        this.onLoadMore = this.onLoadMore.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.updateOrderList = this.updateOrderList.bind(this);
        this.onSubmitFilter = this.onSubmitFilter.bind(this);
        this.toggleCollapseText = this.toggleCollapseText.bind(this);

       
    }
   
    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
       
        if (this.props.orderList !== prevProps.orderList) {
            console.log("Order List Pros has been Updated")
            console.log(this.props.orderList)
            this.setState({
                ...this.state,
                orderList : this.props.orderList,
            })
        }
    }

    componentDidMount() {
        this.props.fetchOrderList(this.state.orderList.last)
    }

    onLoadMore(){
        
        if (this.state.isFilter === false)
            this.props.fetchOrderList(this.state.orderList.last)
        else
            this.props.fetchOrdini(this.state.orderList.last, this.state.filter)
        
    }

    onPrint(item){
        this.props.fetchOrderDetail(item)
    }

    updateOrderList(){
        this.setState({
            ...this.state,
            isFilter: false
        })
        this.props.fetchOrderList(null)
    }

    onSubmitFilter(filter){
        
        this.setState({
            ...this.state,
            filter : filter,
            isFilter: true
        }, function(){
            console.log(filter)
            this.props.fetchOrdini(null, this.state.filter)
        })
    }

    toggleCollapseText(){

        this.setState({
            ...this.state,
            collapseText : this.state.collapseText === this.state.textHidden ? this.state.textShow : this.state.textHidden
        })

    }

    render() {

        const { Panel } = Collapse;

        const IconText = ({ icon, text }) => (
            <div>
              {React.createElement(icon)}
              {text}
            </div>
          );

          const loadMore =
          (
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
              }}
            >
              <Button onClick={this.onLoadMore}>Carica altri ordini</Button>
            </div>
          );


        let dtc = new DataColumnUtil();
        let columns = dtc.createProductTableColOrder();
        let colonne2 = columns.map((element, index) => {
            if (element.title == 'Note') {
                element = { title: 'Note', dataIndex: 'noteCliente', key: 'noteCliente' };
                return element
            }
            return element
        })
        

        const ImgPrinterConst = () => (
            <img style={{width:"1.5em", height:"1.5em", color:"green"}} src={process.env.PUBLIC_URL + '/icon/icons8-invia-alla-stampante-48-pronta.png'}></img>
            );

        const PrintLoading = () => (
            <span style={{fontSize:"8px"}} >Caricamento...</span>
        )

        return (
            <div> 
                 <Collapse defaultActiveKey={['1']} onChange={this.toggleCollapseText} >
                    <Panel header={this.state.collapseText} key="1">
                        <OrderFilter callback={this.onSubmitFilter} />
                    </Panel>
                 </Collapse>
                <Col xs={24} sm={24} md={12} lg={6} xl={6} style={{display:"flex", flexDirection: "row", justifyContent:"space-between", paddingTop: "20px"}}>
                        <h1>Storico ordini</h1>   
                        <Button type="primary" icon={<SyncOutlined />} onClick={this.updateOrderList}>Aggiorna Storico Ordini</Button>
                </Col>   
                         
                <List 
                    itemLayout="vertical" 
                    size="large" /*pagination={{ onChange: page => { console.log(page); }, pageSize: 10, }}*/  
                    dataSource={this.state.orderList.results}
                    footer={<span>&nbsp;</span>}
                    loadMore={loadMore}
                    renderItem={item => (

                    <List.Item key={item.numeroDoc}
                        actions={[
                        ]}
                        extra={

                        <div className="cartInner">    

                            <div style={{display:"flex", width: "100%" }} >
                                
                                <div style={{ flexGrow:0, flexShrink:0, flexBasis: "40%"}} className="cartInfo" onClick={this.onClickCart}>
                                    <div className="cartInfo1">
                                    
                                    <a href ={getApiUrl() + "/orders/orderDetails/pdf/" + item.tipoDoc + "/" + item.numeroDoc + "/" + item.anno } target = "_blank">
                                        <img style={{width:"1.5em", height:"1.5em", color:"green"}} src={process.env.PUBLIC_URL + '/icon/icons8-invia-alla-stampante-48-pronta.png'}></img>
                                    </a>

                                    <span>&nbsp; {moneyFormat(item.netTotal)}</span>
                                    </div>
                                </div>

                                <div style={{flexGrow:0, flexShrink:0, flexBasis: "10%", }}>
                                    <Progress type="circle" width={60} percent={ Math.ceil((item.quantitaDaEvadere === 0) ?  100 : ((item.quantitaEvasa * 100) / (item.quantitaEvasa + item.quantitaDaEvadere))  ) } />
                                </div>

                                <div style={{flexGrow:0, flexShrink:0, flexBasis: "50%"  }}>
                                    <p style={{textAlign:"right"}}><b>Qnt:</b>           <Badge count={item.quantita} showZero={true} style={{ backgroundColor: '#108ee9' }} />     </p>
                                    <p style={{textAlign:"right"}}><b>Qnt Evasa:</b>     <Badge count={item.quantitaEvasa} showZero={true} style={{ backgroundColor: '#52c41a' }}/> </p> 
                                    <p style={{textAlign:"right"}}><b>Qnt Da Evadere:</b><Badge count={item.quantitaDaEvadere} showZero={true}  style={{ backgroundColor: '#f50' }} /> </p>
                                </div>
                            </div>
                        </div>
                    }>
                <List.Item.Meta avatar={ <Avatar style={{margin:"auto",display:"block"}} size="medium" icon={<UserOutlined />} />} title={<a href={item.href}>{"Ordine " + item.tipoDoc + "-" + item.numeroDoc + " del " + item.dataDocumento.substring(0,10) }</a>} description={item.noteDoc || ""} />

                {item.content}

                <Collapse bordered={false} onChange={()=> {this.props.fetchOrderDetail(item)} }>
                    <Panel className="orderDetails" showArrow={false} header="Visualizza/Nascondi Dettagli" key="1" >
                        { item.searchIsPending === true && (
                            <div>Loading...</div>
                        )}

                        { item.searchIsPending === undefined || item.searchIsPending === false && (
                            <Table className="search-results-table" 
                                scroll={{x:true}}
                                columns={colonne2} 
                                dataSource={item.details} defaultExpandAllRows={false} 
                                rowClassName={function(record,index){
                                 console.log("Adding Row className")
                                 console.log(record)

                                 let ris = ""
                                 if(record.qntInevasa > 0){
                                     ris += "toDelivery"
                                 }

                                 if(record.descrizioneMancataVendita!==undefined){
                                    ris += " cancelled"
                                 }
                                 return ris;
                                }}
                            />
                        )}

                    </Panel>
                </Collapse>

            </List.Item>
            )} 
            />
            </div>
        )
    }

}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(OrderList));