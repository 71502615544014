import React from 'react';
import { Menu} from 'antd';
import Cart from '../Cart/cart.js'
import './topheader.css';
import {Link} from "react-router-dom";

const { SubMenu } = Menu;


class SMenu extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inlineCollapsed: true,          
        }
    }

    render() {
        
        return (

            <div className="topHeader">
                 {/*
                <img className="logo" src={process.env.PUBLIC_URL + '/sirmec-logo_512_trasp.png'}></img>
                 */}
                 <div className="sirmec"><Link to="/home"><img className="logo" src={process.env.PUBLIC_URL + '/sirmec-logo_inverso.png'}></img> </Link></div>
                 <div className="cart">
                     <Cart mode="summary" />
                 </div>
            </div>
        )

    }
}
export default SMenu;