import { Form, Input, Button, Upload, message, Row, Col, Collapse, Divider } from 'antd';
import Icon from '@ant-design/icons';

import React, { isValidElement } from 'react';
import { withRouter } from 'react-router-dom'
import { getApiUrl } from '../../utils.js'
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        user: state.user,

    };
};

class Preventivo extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            attachement: [],
            user: this.props.user
        }

        this.refImmagine = React.createRef();
        this.refTelaio = React.createRef();
        this.refMessaggio = React.createRef();
        this.refForm = React.createRef();


        this.handleUpload = this.handleUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUpload(event) {
        const types = [
            "image/apng",
            "image/bmp",
            "image/gif",
            "image/jpeg",
            "image/pjpeg",
            "image/png",
            "image/svg+xml",
            "image/tiff",
            "image/webp",
            "image/x-icon"
        ];

        //console.log("SIZE: " + event.fileList[0].size)
        if (!types.includes(event.file.type)) {
            message.error(`I files non rispettano i requisiti`);

            return false;
        } else {
            if (event.fileList.length > 5) {
                this.setState({ attachement: event.fileList.slice(-5) });
            } else {
                let size = 0;
                (event.fileList || []).forEach(function (element) {
                   
                    size += element.size;

                    })

                    if (size > 15728640) {  
                        message.error(`I files sono troppo grandi! Upload max consentito 15Mb`); 
                        return false;
                    }
                this.setState({ attachement: event.fileList });
            }
            return true
        }
    }

    handleSubmit(event) {
        // const types = [
        //     "image/apng",
        //     "image/bmp",
        //     "image/gif",
        //     "image/jpeg",
        //     "image/pjpeg",
        //     "image/png",
        //     "image/svg+xml",
        //     "image/tiff",
        //     "image/webp",
        //     "image/x-icon"
        // ];


        this.refForm.current.validateFields().then(() => {

            const formData = new FormData();
            formData.append("body", this.refMessaggio.current.props.value)
            formData.append("mail", this.state.user.uEmailsString)
            formData.append("telaio", this.refTelaio.current.props.value)
            if (this.state.attachement.length != 0) {

                // this.state.attachement.map(immagine => {
                //                             formData.append("attachement", immagine.originFileObj)
                //                             formData.append("attachmentName", immagine.name)
                //                         })
                for (var i = 0; i < this.state.attachement.length; i++) {
                    formData.append("attachement"+i, this.state.attachement[i].originFileObj)
                    formData.append("attachmentName", this.state.attachement[i].name)
                }

            }

            fetch(getApiUrl() + "/quote/sendQuote", {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'include',
                headers: new Headers({

                    "Accept": 'application/json'
                }),
                body: formData
            }).then(res => res.json())
                .then(res => {
                    if (res.error) {
                        throw (res);
                    } else {
                        message.info("Richiesta preventivo inviato con successo")
                        this.refForm.current.resetFields()
                        this.setState({ attachement: [] });
                    }
                }).catch(errorInfo => {
                    console.log(errorInfo)
                    message.error("Impossibile inviare il preventivo")

                })
        }).catch(errorInfo => console.log("validation error"))



    }


    render() {

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };
        // const normFile = (e) => {
        //     if (Array.isArray(e)) {
        //         return e;
        //     }

        //     if(e.fileList.length > 1 && this.state.attivo === false)
        //         e.fileList.shift()
        //     return e && e.fileList;
        // };
        const { Panel } = Collapse;
        const { TextArea } = Input;
        const customPanelStyle = {
            background: '#f7f7f7',
            width: '80%',
            marginBottom: 24,

        };
        return (
            <Col xs={24} sm={24} md={18} lg={12} xl={12} style={{ margin: "auto" }}>
                <Row>
                    <Collapse style={customPanelStyle}>
                        <Panel header="Leggi le informazioni per richiedere un preventivo" key="1">
                            <p>Per richiedere un preventivo compilare il form in basso. <br />

                                E' consentito l'upload di max 5 immagini con dimensioni di max <b>15Mb</b> nei formati <em>*.jpg, *.bmp, *.gif, *.png.</em><br />

                                <Divider />

                                <b>N.B. <em>A causa di improvvise variazioni di listino, il preventivo ha validità esclusivamente nella data della sua redazione</em></b></p>
                        </Panel>
                    </Collapse></Row>
                <Row>
                    <Form {...formItemLayout} ref={this.refForm} name="basic">
                        <Form.Item name="telaio" label="N° Telaio / Targa" rules={[{ required: true, message: 'Inserire il N° Telaio o Targa' }]}>
                            <Input placeholder="N° Telaio / Targa" maxLength={17} ref={this.refTelaio} />
                        </Form.Item>
                        <Form.Item label="Messaggio" name="messaggio" rules={[{ required: true, message: 'Inserire il messaggio' }]}>
                            <TextArea rows={8} ref={this.refMessaggio} placeholder="Testo messaggio" />
                        </Form.Item>
                        <Form.Item label="Immagine" name="immagine" ref={this.refImmagine} >
                            <Upload accept="image/*" beforeUpload={() => false} onChange={this.handleUpload} fileList={this.state.attachement} multiple={true}>
                                <Button>
                                    <Icon type="upload" /> Click to Upload Max 15Mb (Solo immagini)
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item onClick={this.handleSubmit} name="ask" style={{ paddingLeft: 375 }} >
                            <Button type="primary">Richiedi Preventivo</Button>
                        </Form.Item>
                    </Form>
                </Row>
            </Col>
        )
    };






}

export default withRouter(connect(mapStateToProps, null)(Preventivo))