import React from 'react';
import Search from '../Search/search.js'
import Slider from '../Slider/slider.js'
import SearchResults from '../SearchResults/searchResults.js'
import ActionBar from '../ActionBar/actionBar.js'
import { connect } from "react-redux";

import { Col, Row, message } from 'antd';
import {Card, Avatar} from 'antd';

import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import {getApiUrl} from '../../utils.js'

const { Meta } = Card;


class Kstore extends React.Component {


    constructor(props) {
        super(props);

        this.openKStore = this.openKStore.bind(this);
    }


    openKStore(){

        let height = window.screen.height * 0.85
        let width  = window.screen.width *0.85

        console.log("Open KStore")
        fetch(getApiUrl() + "/kromeda/KStoreLogin", {
            credentials: "include",
            headers: new Headers({
                "Accept" : 'application/json'
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res.error);
            }
            window.open(res.url, '_blank', 'location=yes,height=' + height + ',width=' + width )
        })
        .catch(error => {
            message.error("Errore nell'apertura del KStore");
        })
    }

    componentDidMount(){
        this.openKStore();
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    shouldComponentUpdate(){
    }

    render() {
        return null;
    }
}
export default Kstore;