import React from 'react';
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom'
import { Tabs } from 'antd';
import {Avatar, Spin, List, Button, Skeleton, Badge, Progress, notification, Collapse} from 'antd'
import {PDFDownloadLink} from '@react-pdf/renderer';
import {PrintOrder, CreatePDF} from '../../print.js';
import {moneyFormat} from "../../utils.js"
import { UserOutlined } from '@ant-design/icons';
import { fetchBolle, fetchFatture } from '../../actions/actions.js';
import Filter from '../Filter/filter.js'
import './documents.css'
import {getApiUrl} from '../../utils.js'

const mapStateToProps = state => {
    return { 
        bolle : state.bolle,
        fatture: state.fatture,
        user: state.user
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchBolle : (last, filter) => dispatch(fetchBolle(last,filter)),
        fetchFatture : (last) => dispatch(fetchFatture(last)),
    }
}

class Documents extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            bolle: this.props.bolle,
            fatture: this.props.fatture,
            user : this.props.user,
            filter: {},
            //Start State
            collapseText : "Nascondi i filtri",

            textShow : "Mostra i filtri",
            textHidden : "Nascondi i filtri"
        }

        this.fetchMoreBolle = this.fetchMoreBolle.bind(this);
        this.fetchMoreFatture = this.fetchMoreFatture.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.onSubmitFilter = this.onSubmitFilter.bind(this);
        this.toggleCollapseText = this.toggleCollapseText.bind(this);

        this.map = new Map();

        this.map.set('1000',  'Fattura Immediata')
        this.map.set('1110',  'Fattura Immediata')
        this.map.set('1111',  'Fattura Differita')
        this.map.set('2001',  'Nota Credito')
        this.map.set('2000',  'Nota credito')
        this.map.set('2110',  'Nota credito')
        this.map.set('3110',  'Corrispettivo Magazzino')
        this.map.set('4110',  'Storno Corrispettivo Magazz.')
        this.map.set('1130',  'Fattura Immediata')
        this.map.set('1030',  'Fattura Immediata')
        this.map.set('1113',  'Fattura Differita')
        this.map.set('6130',  'Fattura Acquisto')
        this.map.set('11PA',  'Fatt. Mag. Pubbl. Amm.ne')
        this.map.set('21PA',  'Nota credito Mag. Pubbl. Amm.ne')
        this.map.set('5000',  'Fattura vendita Pubblica Amministrazione')
        this.map.set('5110',  'Fattura Vendita Magazzino Pubblica Amministrazione')
        this.map.set('2030',  'Nota credito')
        this.map.set('2003',  'Nota Credito')
        this.map.set('5200',  'Nota Credito libera Pubblica Amministrazione ')
        this.map.set('2130',  'Nota credito')
        this.map.set('7130',  'Nota credito')
        this.map.set('5210',  'Nota di credito magazzino Pubblica Amministrazione')
        this.map.set('1125',  'Fattura differita DFS')
        this.map.set('1135',  'Fattura differita DFS')

    }
   
    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.bolle !== prevProps.bolle){
            this.setState({
                ...this.state,
                bolle:  this.props.bolle
            })
        }

        if(this.props.fatture !== prevProps.fatture){
            this.setState({
                ...this.state,
                fatture:  this.props.fatture
            })
        }
    }

    componentDidMount() {
        //let last = this.state.bolle.pagination.last || null;
        this.props.fetchBolle(null, this.state.filter)
        this.props.fetchFatture(null)
    }


    fetchMoreBolle(){
        let last = this.state.bolle.pagination.last || null;
        this.props.fetchBolle(last, this.state.filter)
    }

    fetchMoreFatture(){
        let last = this.state.fatture.pagination.last || null;
        this.props.fetchFatture(last)
    }

    getPdf(item){

        fetch(getApiUrl() + "/documents/pdfAvailability?idDoc=" + item.idDoc + "&typeDoc=" + item.docType, {
            credentials: "include",
            method: "GET",
            headers: new Headers({
                "Accept" : 'application/json'
            })
        })
        .then(res => {

            if(res.status !== 200){
                throw res.status;
            }

            let url = getApiUrl() + "/documents/getPdf?idDoc=" + item.idDoc + "&typeDoc=" + item.docType
            window.open(url);
        })
        .catch(error => {
            notification["warning"]({
                message: 'Documento non ancora disponibile',
                description:
                  'Il documento al momento non è ancora disponibile, la preghiamo di riprovare più tardi',
              });

        })
    }

    onSubmitFilter(filter){
        
        this.setState({
            ...this.state,
            filter : filter
        }, function(){
            console.log(filter)
            this.props.fetchBolle(null, this.state.filter)
        })
    }

    toggleCollapseText(){

        this.setState({
            ...this.state,
            collapseText : this.state.collapseText === this.state.textHidden ? this.state.textShow : this.state.textHidden
        })

    }

    render() {

        const { Panel } = Collapse;

        const loadMoreBolle =
        (
          <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }} >
            <Button onClick={this.fetchMoreBolle}>Carica altri documenti</Button>
          </div>
        );

        const loadMoreFatture =
        (
          <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }} >
            <Button onClick={this.fetchMoreFatture}>Carica altri documenti</Button>
          </div>
        );

        const { TabPane } = Tabs;
        return (
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Bolle" key="1">
                
                <Collapse defaultActiveKey={['1']} onChange={this.toggleCollapseText} >
                    <Panel header={this.state.collapseText} key="1">
                        <Filter callback={this.onSubmitFilter} />
                    </Panel>
                </Collapse>

                <List 
                    className="documentsList"
                    itemLayout="vertical" 
                    size="large" /*pagination={{ onChange: page => { console.log(page); }, pageSize: 10, }}*/  
                    dataSource={this.state.bolle.data}
                    footer={<span>&nbsp;</span>}
                    loadMore={loadMoreBolle}
                    renderItem={item => (

                    <List.Item key={item.idDoc}
                        actions={[
                        ]}
                        extra={

                        <div className="cartInner">    

                            <div style={{display:"flex", width: "100%" }} >
                                
                                <div style={{ flexGrow:0, flexShrink:0, flexBasis: "50%"}} className="cartInfo" onClick={this.onClickCart}>
                                    <div className="cartInfo1">

                                    {
                                       item.details === undefined && (
                                        <img onClick={()=> { this.getPdf(item)} } style={{width:"1.5em", height:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-invia-alla-stampante-48-pronta.png'}></img>
                                       )
                                    }

                                    {
                                        /*
                                       item.details !== undefined && (
                                        <PDFDownloadLink document={<CreatePDF order={item} detail={item.details} />} fileName={item.tipoDoc + item.numeroDoc}>
                                            {({ blob, url, loading, error }) => (loading ? <PrintLoading /> : <ImgPrinterConst /> )}
                                        </PDFDownloadLink>
                                       )
                                       */
                                    }

                                    <span>&nbsp; {moneyFormat(item.netTotal)}</span>
                                    </div>
                                </div>

                                <div style={{flexGrow:0, flexShrink:0, flexBasis: "50%"  }}>
                                    <img style={{width:"1.5em", height:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-scatola-di-cartone-48.png'}></img>
                                    <span> &nbsp; {item.totPackages + " collo/i "} </span>
                                </div>
                            </div>
                        </div>
                    }>
                <List.Item.Meta avatar={ <img src={process.env.PUBLIC_URL + '/icon/icons8-camion-48.png'}></img>} 
                     size="medium"  title={"D.D.T. " + item.numDdt + " del " + item.shipDate.substring(0,10)} />

                </List.Item>
            )} 
            />
                </TabPane>

                    
                <TabPane tab="Fatture/Note Cred." key="2">
                

                {this.state.user.uShowOnlyDestData && (
                    (<p>Questa utenza è abilitata alla sola visualizzazione dei dati della destinazione</p>)
                )}

                { !this.state.user.uShowOnlyDestData && (
                <List 
                    className="documentsList"
                    itemLayout="vertical" 
                    size="large" /*pagination={{ onChange: page => { console.log(page); }, pageSize: 10, }}*/  
                    dataSource={this.state.fatture.data}
                    footer={<span>&nbsp;</span>}
                    loadMore={loadMoreFatture}
                    renderItem={item => (

                    <List.Item key={item.idDoc}
                        actions={[
                        ]}
                        extra={

                        <div className="cartInner">    

                            <div style={{display:"flex", width: "100%" }} >
                                
                                <div style={{ flexGrow:0, flexShrink:0, flexBasis: "50%"}} className="cartInfo" onClick={this.onClickCart}>
                                    <div className="cartInfo1">

                                    {
                                       item.details === undefined && (
                                        <img onClick={()=> { this.getPdf(item)} } style={{width:"1.5em", height:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-invia-alla-stampante-48-pronta.png'}></img>
                                       )
                                    }

                                    {
                                        /*
                                       item.details !== undefined && (
                                        <PDFDownloadLink document={<CreatePDF order={item} detail={item.details} />} fileName={item.tipoDoc + item.numeroDoc}>
                                            {({ blob, url, loading, error }) => (loading ? <PrintLoading /> : <ImgPrinterConst /> )}
                                        </PDFDownloadLink>
                                       )
                                       */
                                    }

                                    <span>&nbsp; {moneyFormat(item.netTotal)}</span>
                                    </div>
                                </div>

                                <div style={{flexGrow:0, flexShrink:0, flexBasis: "50%"  }}>
                                    Id. doc: {item.idDoc}
                                </div>
                            </div>
                        </div>
                    }>
                <List.Item.Meta avatar={ <img src={process.env.PUBLIC_URL + '/icon/icons8-fattura-48.png'}></img>} 
                     size="medium"  title={this.map.get(item.docType) + " Numero " + item.invoiceId + " del " + item.invoiceDate.substring(0,10)} />
                
                </List.Item>

            )} 
            />
            )}

                </TabPane>

            </Tabs>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Documents));