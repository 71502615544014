import React from 'react';
import {fetchLogout} from '../../actions/actions.js'
import { connect } from "react-redux";

const mapStateToProps = state => {
    return { 
        init : state.init,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fetchLogout : () => dispatch(fetchLogout())
    };
}

class Logout extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
       this.state = { 
       }
       
    }
    componentDidMount() {
        this.props.fetchLogout();
    }

    render() {
        return null;
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Logout);