import React from 'react';
import { connect } from "react-redux";
import {fetchInit} from "../../actions/actions.js"
import {withRouter} from 'react-router-dom'


const mapStateToProps = state => {
    return { 
        init : state.init,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchInit : () => dispatch(fetchInit()),
    };
}

class Init extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            init: this.props.init,
        }
    }

    componentDidMount() {
        this.props.fetchInit();
    }

    render() {
        return null;
    }

}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Init));