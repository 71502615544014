import React from 'react';
import { connect } from "react-redux";
import { Col, Row, message, Spin } from 'antd';
import { Select } from 'antd';
import { InputNumber } from 'antd';
import { Form } from 'antd';
import { Button } from 'antd';
import {getApiUrl} from '../../utils.js'
import {notification} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';

const { Option } = Select;

class SearchReport extends React.Component {

    constructor(props) {
            
        super(props);

        this.state = {
            startDate:"",
            endDate: "",
            waiting : false,
        } 
        this.getSearchReport = this.getSearchReport.bind(this)
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this)
    }

    componentDidMount() {
                
    }

    onChangeDatePicker(value){

    
        if(value === null || value===undefined){
            return
        }
        let startMoment = value[0].format("YYYY-MM-DD");
        let endMoment = value[1].format("YYYY-MM-DD");
        this.setState({
            ...this.state,
            startDate : startMoment,
            endDate : endMoment
        }
        )
    }


    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    getSearchReport(){

        this.setState({
            ...this.state,
            waiting:true
        }, () => {

        fetch(getApiUrl() + "/search/searchReport?startDate=" + this.state.startDate + "&endDate=" + this.state.endDate,{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'text/plain'
            }),
        })
        .then((response) => response.blob())
        .then((blob) => {

           console.log("Received response")
           console.log(blob)

           // Create blob link to download
           const url = window.URL.createObjectURL(
             new Blob([blob]),
           );
           const link = document.createElement('a');
           link.href = url;

           var d = new Date();
           let reportname =    "search_report" + d.getFullYear() + "_" + (d.getMonth()+1) + "_" + d.getDate() + "_" + d.getHours() + d.getMinutes() + ".csv"

           link.setAttribute( 'download', reportname,);
          
           // Append to html link element page
           document.body.appendChild(link);
          
           // Start download
           link.click();
          
           // Clean up and remove the link
           link.parentNode.removeChild(link);

           this.setState({
            ...this.state,
            waiting:false
        });
        })
        .catch(error => {

            let config = {
                message: 'Errore',
                description: 'Errore nel recuperare il report',
            };
            notification.success(config)
        })
    }
        )
    }

    render() {

        const { RangePicker } = DatePicker;

        return (
            <div className="site-layout-background" style={{minHeight: 380 }}>               
               <Form>
                   <Form.Item label = {"Intervallo date di ricerca"}>
                       
                   <RangePicker  onChange={this.onChangeDatePicker}/>
                   
                   </Form.Item>
               </Form>
               <Form>

                <Button loading={this.state.waiting} disabled={this.state.waiting} type="primary" onClick={this.getSearchReport} >Genera Report</Button>

                { this.state.waiting && (
                <Spin style={{marginLeft:"10%", paddingTop:"2em"}} size="large" tip="Generazione Report in corso..." /> 
                )
                }
                </Form>

                
            </div>
        )
    }
}
export default (SearchReport);