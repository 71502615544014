import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from './components/Login/login.js'
import Layout from './components/Layout/layout.js'
import AgentReport from './components/AgentReport/agentReport.js'
import Auth from './components/Auth/auth.js'
import { Provider } from 'react-redux'
import store from './store/store.js'
import {message } from 'antd';

import 'antd/dist/antd.css';
import './App.css';

function App() {

  /* Message global configuration */
  message.config({ top: 200, duration: 3, maxCount: 3, rtl: true,
  });



  return (
    <div className="App">
      <Provider store={store}>
         <Router>
            <Auth />
            <Switch>
               <Route exact path="/login">
                  <Login />
               </Route>
               <Route path="/report/:agentUrl">
                  <AgentReport/>
               </Route>
               <Route path="*" component={Layout} />
            </Switch>
          </Router>
      </Provider>
    </div>
  );
}

export default App;
