import React from 'react';
import Search from '../Search/search.js'
import Slider from '../Slider/slider.js'
import SearchResults from '../SearchResults/searchResults.js'
import ActionBar from '../ActionBar/actionBar.js'
import { connect } from "react-redux";

import { Col, Row, message } from 'antd';

import {fetchUserPromo} from "../../actions/actions.js"

const mapStateToProps = state => {
    return { 
        userPromo : state.userPromo
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchUserPromo: () => dispatch(fetchUserPromo())
    };
}

class Home extends React.Component {

    constructor(props) {
            
        super(props);
        this.state = {
            userPromo : this.props.userPromo,
        } 
    }

    componentDidMount() {
        this.props.fetchUserPromo();
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        console.log("Home: ComponentDidUpdate")

        if (this.props.userPromo !== prevProps.userPromo) {
            this.setState ({
              ...this.state,
              userPromo : this.props.userPromo
            });
        }
    }

    render() {
        return (
            <div className="site-layout-background" style={{minHeight: 380 }}>
                <Row style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
                    <Col className="colSearch colSearchHeight" xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Search />
                    </Col>
                    <Col className="colPromo colSearchHeight" style={{ marginRight: "-16px" }} xs={0} sm={0} md={0} lg={16} xl={16}>
                        <Slider userPromo={this.state.userPromo} style={{ width: "90%", marginLeft: "1em" }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: "3em" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <ActionBar />
                    </Col>
                </Row>
                <Row style={{ marginTop: "2em", overflowX: "scroll" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <SearchResults userPromo={this.state.userPromo} className="searchResults" />
                    </Col>
                </Row>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);