import React from 'react';
import { Carousel, Spin } from 'antd';
import {withRouter} from 'react-router-dom'
import './slider.css';

class Slider extends React.Component {


    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
       console.log("Search: ComponentDidUpdate")

       if (this.props.userPromo !== prevProps.userPromo) {
             this.setState ({
               ...this.state,
               userPromo : this.props.userPromo
             });
          }
    }

    constructor(props) {
        super(props);
        this.state = {
            userPromo : this.props.userPromo,
        } 

        this.onPromoClick = this.onPromoClick.bind(this);
    }

    onPromoClick(proId){
        this.props.history.push("/promoDetail/" + proId);
    }

    render() {

        let that = this;

        if(this.state.searchIsPending){
            return (  <Spin style={{marginLeft:"49%", paddingTop:"2em"}} size="large" tip="Loading promo..." /> )
        }

        return (
            <div style={{margin:"auto"}}>
                
                <Carousel autoplay>
                
                {this.state.userPromo.requestIsPending===false && (this.state.userPromo.data || [] ).map((promo, index) => {
                    return (
                        <img key={promo.proId} onClick={function(){that.onPromoClick(promo.proId)}} src={promo.proBannerLink} />        
                    )})
                }
                

                {/*
                <img src={"https://8bcg5a.am.files.1drv.com/y4m4BK4o6K-Y8G5FPF28JufduSZZ1MXPosgHWDnbX8ma5JPId7rFCMKtmdK46vdP-OMEhvOKJfwZ6iDBAHiexCL5FrvFHEm5ScUmbjgUa2lE3yqYjzD8pCzZ92WTSwlojeTodW4zeAAtJEuj2NBQtVJVhc6qZ1sfgL5iPdksPlEZn_vpmigaAqvpgRe4Rumz0FQ36_m7sLq_hdv_605QTOTXQ?width=1247&height=310&cropmode=none"} />                                
                <img src={"https://8bbl0a.am.files.1drv.com/y4mhJsnY3CxE1CUZasj4PBAqzzM4Z0rq8CnuLURd1UYIR_gvHoOhRIhswdZEQv64-stqz9JWyQ2FjTh4MrovoaEXQMZLSVd5o3fj4XgQuXHxSbHrmKA06LA0bK63yfLI5fEuKK2Oqs3HyXM9idW05wCcxOnTlsHghiG6_VeEG8FMr0GaSFRFP9q0uSaCxj9D4GVIiZsi1nQFF0kbkInhcmbfQ?width=1247&height=310&cropmode=none"} />
                */}

                </Carousel>
                
            </div>
        )
    }
}


export default withRouter(Slider);