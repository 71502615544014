import React from 'react';
import {fetchCheckLogin, fetchAddToCart} from "../../actions/actions.js"
import { connect } from "react-redux";
import { Table, Spin, Modal} from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import TextArea from 'antd/lib/input/TextArea';
import {getApiUrl} from '../../utils.js'

const mapStateToProps = state => {
    return {} 
};

function mapDispatchToProps(dispatch) {
    return {
        fetchCheckLogin : (token) => dispatch(fetchCheckLogin(token)),
        fetchAddToCart : (product) => dispatch(fetchAddToCart(product)),
    };
}


class KstoreLanding extends React.Component {

    constructor(props) {
        super(props)

        const urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get("pGeneric");
        let sessionKey = urlParams.get("sessionKey");

        this.state = {
            token : token,
            sessionKey : sessionKey,
            results : [],
            waiting : true, 
            popupNoteCliente : null
        }
        this.updateAvalaibility = this.updateAvalaibility.bind(this);
        this.updateQntRichiesta = this.updateQntRichiesta.bind(this);
    }

    componentDidMount(){

        this.props.fetchCheckLogin(this.state.token);

        fetch(getApiUrl() + "/auth/getCookie?token=" + this.state.token, {
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res => res.json())

        let updtAvlFnc = this.updateAvalaibility;
        
        fetch(getApiUrl() + "/kromeda/KStoreLanding?sessionKey=" + this.state.sessionKey + "&token=" + this.state.token,{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res=>res.json())
        .then(res => {
            
            this.setState({
                ...this.state,
                results : res.data,
                waiting : false
            }, function(){

                //UPDATE AVALAIBILITY
                (res.data || []).map(function(value){
                    updtAvlFnc(value.precodice, value.codice);
                })
            }) 
        })
        .catch(err => console.log("Error in login with token calling API KStoreLanding" + err))
    }
    updateAvalaibility(precodice, codice){

        /* Change state in waiting for avalaibility */
        let newResults = (this.state.results|| []).map(function(value){
            if(value.precodice === precodice && value.codice === codice && value.precodice!== 'P'){
                value.IAMAvalaibilityPending = true;
            }
            return value
        })

        this.setState({
            ...this.state,
            results : newResults
        }, function(){ 

            /* Call service */
            let url = ""
            if(precodice.startsWith('Q')){
                url = "/AML/productAvailability?makerCode=" + precodice + "&productCode=" + codice + "&token=" + this.state.token
            }else if(precodice.startsWith('R')){
                url = "/CLL/productAvailability?makerCode=" + precodice + "&productCode=" + codice + "&token=" + this.state.token
            }else if(precodice.startsWith('S')){
                url = "/CLC/productAvailability?makerCode=" + precodice + "&productCode=" + codice + "&token=" + this.state.token
            }else {
                return 
            }

            fetch(getApiUrl() + url, {
                credentials: "include",
                method: "GET",
                headers: new Headers({
                    "Accept" : 'application/json'
                })
            })
            .then(res => res.json())
            .then(res => {

                let results = this.state.results;

                let newResults = (results|| []).map(function(value){
                    if(value.precodice === precodice && value.codice === codice){
                        value.disp = res.totalOrderable
                        value.IAMAvalaibilityPending = false;
                        /* 
                        * set-up information about wharehouse
                        */
                        value.availabilityWarehouse = res.availabilityWarehouse
                        value.primaryWarehouse = res.primaryWarehouse
                    }
                    return value
                })
                this.setState({
                    ...this.state,
                    results : newResults
                })
            })
        });

    }
    updateQntRichiesta(record, qnt){
        let newResults = (this.state.results|| []).map(function(value){
            if(value.precodice === record.precodice && value.codice === record.codice){
                value.qntRichiesta = qnt;
            }
            return value
        })

        this.setState({
            ...this.state,
            results : newResults
        })
    }
    
    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

  
    render() {

        let dtc = new DataColumnUtil();

        let config = {
            onChangeQntRich : this.updateQntRichiesta,
            onAddToChart : this.props.fetchAddToCart,
            onFindCrossReference : null,
            onDeliveryDate : null,
            onNoteClick : this.onOpenNoteCliente
        };

        let columns = dtc.createProductTableCol(config);

        if(this.state.waiting){
            return(
                <Spin style={{marginLeft:"49%", paddingTop:"2em"}} size="large" tip="Ricerca in corso..." /> 
            )
        }

        let noteClienteText = ( this.state.popupNoteCliente !== undefined && this.state.popupNoteCliente !== null && 
            this.state.popupNoteCliente.noteCliente !== undefined ) ?  this.state.popupNoteCliente.noteCliente : ""
        
    
        return (
            <div>
               <h1>Sotto trovi gli articoli della ricerca che puoi inserire nel carrello </h1>
               <h1>Dopo aver inserito gli articoli, torna al B2B Sirmec </h1>
               <Table className="search-results-table" 
                            pagination={{ pageSize: 15}}
                            columns={columns} 
                            expandable={false} 
                            dataSource={this.state.results} defaultExpandAllRows={false} 
                            rowClassName={ (record, index) => { if(record.animationClass!==undefined){ return record.animationClass}  }   }
                        />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KstoreLanding);