import {FETCH_SEARCH_PEND,FETCH_SEARCH_SUCC,FETCH_SEARCH_FAIL, FETCH_AUTH_PEND
       ,FETCH_AUTH_FAIL,FETCH_AUTH_SUCC, FETCH_CART_PEND, FETCH_CART_SUCC, FETCH_CART_FAIL, FETCH_URGENT_CART_PEND, FETCH_URGENT_CART_SUCC, FETCH_URGENT_CART_FAIL,
       FETCH_NEWCART_SUCC, FETCH_UPDATE_QNT_RICH,CART_NOTE_UPD, CART_RIF_UPD, FETCH_SEND_ORDER_SUCC, FETCH_SEND_ORDER_FAIL, FETCH_SEND_ORDER_WARN, FETCH_SEND_ORDER_PEND, 
       FETCH_ORDER_LIST_PEND, FETCH_ORDER_LIST_SUCC, FETCH_ORDER_LIST_FAIL, FIND_CROSS_REFERENCE_PEND, FIND_CROSS_REFERENCE_SUCC,
       FIND_CROSS_REFERENCE_FAIL, DELIVERY_DATE_PEND, DELIVERY_DATE_SUCC, DELIVERY_DATE_FAIL, FETCH_LOGOUT_SUCC, FETCH_ORDER_DETAIL_PEND,
       FETCH_ORDER_DETAIL_SUCC, FETCH_ORDER_DETAIL_FAIL, IAM_AVALAIBILITY_SUCC, IAM_AVALAIBILITY_FAIL,
       MISSING_FAIL,MISSING_PEND, MISSING_SUCC, FETCH_BOLLE_FAIL, FETCH_BOLLE_SUCC, FETCH_BOLLE_PEND, FETCH_FATT_SUCC, FETCH_FATT_PEND, 
       FETCH_FATT_FAIL, SAVE_NOTE_CLIENTE, FETCH_USER_PROMO_SUCC, FETCH_USER_PROMO_PEND, FETCH_USER_PROMO_FAIL, FETCH_UPDATE_QNT_RICH_PROMO,
       FETCH_PROMO_DETAIL_FAIL, FETCH_PROMO_DETAIL_SUCC, FETCH_PROMO_DETAIL_PEND, FETCH_INIT_SUCC, FIND_CROSS_REFERENCE_PROMO_PEND, FIND_CROSS_REFERENCE_PROMO_SUCC,
       FIND_CROSS_REFERENCE_PROMO_FAIL, DELIVERY_DATE_PROMO_PEND, DELIVERY_DATE_PROMO_FAIL, DELIVERY_DATE_PROMO_SUCC, FADE_OUT_PRODUCT, REMOVE_PRODUCT_FROM_SEARCH,
       IAM_AVALAIBILITY_PROMO_SUCC, IAM_AVALAIBILITY_PROMO_FAIL, CLEAR_SEARCH_HISTORY, FETCH_BOLLE_CLEAR, SET_LOGIN_MESSAGE, INVALIDATE_ORDER_LIST,
       RESI_SUCC,RESI_PEND, RESI_FAIL, RESI_REQ_SUCC, RESI_REQ_PEND, RESI_REQ_FAIL,
       FETCH_BOLLE_DETAIL_PEND, FETCH_BOLLE_DETAIL_SUCC, FETCH_BOLLE_DETAIL_FAIL, FETCH_BOLLE_DETAIL_CLEAR, FETCH_BEHALF_PEND, FETCH_BEHALF_SUCC, FETCH_BEHALF_FAIL,
       FETCH_BEHALF_EXIT_SUCC, FETCH_ALTERNATIVE_SUCC, FETCH_ALTERNATIVE_ERR, FETCH_ALL_PROMO_SUCC, FETCH_ALL_PROMO_PEND, FETCH_ALL_PROMO_FAIL, FETCH_ORDINI_PEND,
       FETCH_ORDINI_SUCC, FETCH_ORDINI_FAIL
      } from "../actions/types.js"

import {updateProductList,deleteFromProductList} from '../utils.js'

const stringNOAlternativeFound = "NESSUN CODICE ALTERNATIVO TROVATO"

const initialState = {
  
    init: {
      userIsLogged: false,
      loadingIsPending: false,
      loginMessage: "",
      apiVersion : "",
    },

    user: {
        username : "",
        idCliente: 0, 
        isBehelf: false,
	      originalUser: null
    },

    behalf:{
        requestIsPending: false,
        error: "",
        redirect: false, //redirect after success login
    },

    cart : {
      requstIsPending : false,
      cartData:{
        netTotal: 0,
        totaliArticoli: 0
      }
    },

    lastSearch : {
      searchIsPending: false,
      noResultsFound: false,
      results : []
    },

    orderList : {
      last : null,
      searchIsPending: false,
      results : []
    },
    prevSearch: [],

    /* List of articles ordered but not delivered with estimated dates */
    missing : {
      requstIsPending : false,
      data : [],
      lastFetch: null
    },

    bolle : {
      requstIsPending : false,
      data : [],
    },

    bolleDetail: {
      requstIsPending : false,
      data : [],
    },

    fatture: {
      requstIsPending : false,
      data : [],
    },

    userPromo : {
      requstIsPending : false,
      data : [
        /*
            {
              ...PROMO
              promoProducts: []
            },
            {

            }
        */
      ]
    },
    resi : {
      requstIsPending : false,
      data : [],
      lastFetch: null
    },
    resiReq : {
      requstIsPending : false,
      data : [],
      lastFetch: null
    },
   
    
  };
  
  function rootReducer(state = initialState, action) {

    console.log("Action  dispatched")
    switch (action.type) {

        case FETCH_AUTH_PEND : {

          return Object.assign({}, state, {
            ...state,
            init : {
              ...state.init,
              loadingIsPending : true
            }
          });
        }
        case FETCH_AUTH_FAIL : {
          return Object.assign({}, state, {
            ...state,
            init : {
              ...state.init,
              loadingIsPending : false,
              userIsLogged: false,
              loginMessage: action.data.message
            }
          });
        }
        case FETCH_AUTH_SUCC : {
          return Object.assign({}, state, {
            ...state,
            init : {
              ...state.init,
              loadingIsPending : false,
              userIsLogged: true,
              loginMessage: ''
            },
            user: action.data
          });
        }
        case FETCH_SEARCH_PEND : {

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              results: [],
              searchIsPending:true
            },
            prevSearch : state.lastSearch.results.concat(state.prevSearch)
          });
        }
        case FETCH_SEARCH_SUCC : {
          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              results: action.data,
              searchIsPending:false,
              noResultsFound: (action.data.length > 0 ? false : true)
            }
          });
        }
        case FETCH_SEARCH_FAIL : {

          return Object.assign({}, state, {
            ...state,
            init : {
              ...state.init,
              loadingIsPending : false,
              loginMessage : action.data.message
            }
          });
        }
        case CLEAR_SEARCH_HISTORY : {
          return Object.assign({}, state, {
            ...state,
            lastSearch : initialState.lastSearch,
            prevSearch : initialState.prevSearch
          });
        }

        case FETCH_CART_PEND : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : true,
              cartData : state.cart.cartData
            },
            isPriorityOrder: false
          });
        }
        case FETCH_CART_SUCC : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              cartData : action.data
            },
            isPriorityOrder: false
          });
        }
        case FETCH_CART_FAIL : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : state.cart.cartData
            },
            isPriorityOrder: false
          });
        }
        case FETCH_URGENT_CART_PEND : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : true,
              cartData : state.cart.cartData
            },
            isPriorityOrder: true
          });
        }
        case FETCH_URGENT_CART_SUCC : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              cartData : action.data
            },
            isPriorityOrder: true
          });
        }
        case FETCH_URGENT_CART_FAIL : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : state.cart.cartData
            },
            isPriorityOrder: false
          });
        }

        case CART_NOTE_UPD : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : {
                ...state.cart.cartData,
                cNotes : action.data.currentTarget.value
              }
            }
          });
        }

        case CART_RIF_UPD : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : {
                ...state.cart.cartData,
                cRifOrdCli : action.data.currentTarget.value
              }
            }
          });
        }

        case FETCH_SEND_ORDER_PEND : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : true,
              /*set initial state  */
              cartData : {
                ...state.cart.cartData,
              }
            }
          });
        }
        case FETCH_SEND_ORDER_SUCC : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : {
                ...initialState.cart.cartData,
              }
              
            },
            isPriorityOrder: false
          });
        }
        case FETCH_SEND_ORDER_FAIL : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : {
                ...state.cart.cartData,
              }
            }
          });
        }
        case FETCH_SEND_ORDER_WARN : {
          return Object.assign({}, state, {
            ...state,
            cart :  {
              requstIsPending : false,
              /*set initial state  */
              cartData : {
                ...state.cart.cartData,
              }
            }
          });
        }

        case FETCH_UPDATE_QNT_RICH : {

          let record = action.data.product
          let value = action.data.value

          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = updateProductList(sr,record.precodice,record.codice, {quantitaRichiesta:value});
          let prUpdt = updateProductList(pr,record.precodice,record.codice, {quantitaRichiesta:value});

          return Object.assign({}, state, {
              ...state,
              lastSearch : {
                ...state.lastSearch,
                results : srUpdt
              },
              prevSearch: prUpdt
            });
        }
        
        case FETCH_UPDATE_QNT_RICH_PROMO : {

          let record = action.data.product
          let value = action.data.value
          let promoId = action.data.promoId

          console.log("Update Qnta Richiesta for record below and promoId:" + promoId + " and qnta:" + value)
          console.log(record)

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);
          let promoUpdt = updateProductList(item.promoProducts,record.precodice,record.codice, {quantitaRichiesta:value});
          item.promoProducts = promoUpdt;

          console.log("Item Updated")
          console.log(item);

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          console.log("New Promos")
          console.log(newPromo);

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }   
        case FETCH_ORDINI_PEND:{
          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              searchIsPending: true,  
            }
          });

        }
        case FETCH_ORDINI_SUCC : {

          let results = null
          //Append
          if(action.data.lastReq){
            results = state.orderList.results.concat(action.data.data)
          }
          //First call
          else {
            results = action.data.data;
          }
          
          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              results : results,
              last : action.data.last
            }
          });

        }

        case FETCH_ORDINI_FAIL : {
          
          return Object.assign({}, state, {
           
            orderList : {
              ...state.orderList,
              
              searchIsPending: false,  
            }
          });

          
        }
        
        // case FETCH_ORDINI_CLEAR : {

        //   return Object.assign({}, state, {
        //     ...state,
        //     results : initialState.results
        //   });
        // }

        case FETCH_ORDER_LIST_PEND : {
          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              searchIsPending: true,  
            }
          });
        }

      
        case FETCH_ORDER_LIST_FAIL : {
          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...this.state.orderList,
              searchIsPending: false,  
            }
          });
        }
        case FETCH_ORDER_LIST_SUCC : {

          let results = null
          //Append
          if(action.data.lastReq){
            results = state.orderList.results.concat(action.data.data)
          }
          //First call
          else {
            results = action.data.data;
          }

          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              results : results,
              last : action.data.last
            }
          });
        }

        case INVALIDATE_ORDER_LIST : {
          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              last : null
            }
          });
        }

        case FETCH_ORDER_DETAIL_SUCC: {

          let item = action.data.item;
          let data = action.data.data;

          let ris = state.orderList.results.map( (element) =>{
            if(element.numeroDoc===item.numeroDoc && element.anno === item.anno){
              element.searchIsPending = false
              element.details = data;
              return element;
            }
            else {
              return element;
            }
          })

          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              results : ris
            }
          });
        }
        case FETCH_ORDER_DETAIL_PEND: {

          let item = action.data;

          let ris = state.orderList.results.map( (element) =>{
            if(element.numeroDoc===item.numeroDoc && element.anno === item.anno){
              element.searchIsPending = true
              return element;
            }
            else {
              return element;
            }
          })

          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              results : ris
            }
          });
        }
        case FETCH_ORDER_DETAIL_FAIL: {

          let item = action.data;

          let ris = state.orderList.results.map( (element) =>{
            if(element.numeroDoc===item.numeroDoc && element.anno === item.anno){
              element.searchIsPending = false
              return element;
            }
            else {
              return element;
            }
          })

          return Object.assign({}, state, {
            ...state,
            orderList : {
              ...state.orderList,
              results : ris
            }
          });
        }
        case FIND_CROSS_REFERENCE_PEND : {
          
          let sr = state.lastSearch.results.map(function(value){
            if(value.precodice === action.data.precodice && value.codice === action.data.codice){
              value.IAMSearchPending = true
            }
            return value;
          });

          let ps = state.prevSearch.map(function(value){
            if(value.precodice === action.data.precodice && value.codice === action.data.codice){
              value.IAMSearchPending = true
            }
            return value;
          });

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results: sr,
            },
            prevSearch : ps 
          });
        }
        case FIND_CROSS_REFERENCE_FAIL : {
          let sr = state.lastSearch.results.map(function(value){
            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = false
            }
            return value;
          });

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results: sr,
            }
          });
        }
        case FIND_CROSS_REFERENCE_SUCC : {

          let alternative = (action.data.data ||[]).map(function(el){
            el.hideIAMButton = true;
            return el;
          })

          let sr = state.lastSearch.results.map(function(value){

            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = false
              value.alternative = alternative

              if(value.alternative.length===0){
                value.message = stringNOAlternativeFound
              }

            }
            return value;
          });

          let ps = state.prevSearch.map(function(value){

            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = false
              value.alternative = alternative

              if(value.alternative.length===0){
                value.message = stringNOAlternativeFound
              }

            }
            return value;
          });

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results: sr,
            },
            prevSearch : ps
          });
        }

        /* CROSS REFERENCE IN PROMO VIEW */
        case FIND_CROSS_REFERENCE_PROMO_PEND : {
          
          let record = action.data.product
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);
          
          let products = item.promoProducts.map(function(value){
            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = true
            }
            return value;
          });

          item.promoProducts = products

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }

        case FIND_CROSS_REFERENCE_PROMO_FAIL : {
          let record = action.data.product
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);
          
          let products = item.promoProducts.map(function(value){
            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = false
            }
            return value;
          });

          item.promoProducts = products

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }

        case FIND_CROSS_REFERENCE_PROMO_SUCC : {

          let alternative = (action.data.data ||[]).map(function(el){
            el.hideIAMButton = true;
            return el;
          })

          let record = action.data.productToDecode
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);
          
          let products = item.promoProducts.map(function(value){
            if(value.precodice === action.data.productToDecode.precodice && value.codice === action.data.productToDecode.codice){
              value.IAMSearchPending = false
              value.alternative = alternative

              if(value.alternative.length===0){
                value.message = stringNOAlternativeFound
              }

            }
            return value;
          });

          item.promoProducts = products

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }
              
        case DELIVERY_DATE_PEND : {

          let record = action.data
          
          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = updateProductList(sr,record.precodice,record.codice, {deliveryTimeSearchIsPending:true});
          let prUpdt = updateProductList(pr,record.precodice,record.codice, {deliveryTimeSearchIsPending:true});

          return Object.assign({}, state, {
              ...state,
              lastSearch : {
                ...state.lastSearch,
                results : srUpdt
              },
              prevSearch: prUpdt
            });
        }
        case DELIVERY_DATE_SUCC : {
          let record = action.data.record
          let ris = action.data.ris
          
          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let message = ris.sirmecAvalability
      
          let srUpdt = updateProductList(sr,record.precodice,record.codice, {deliveryTimeSearchIsPending:false, message: message});
          let prUpdt = updateProductList(pr,record.precodice,record.codice, {deliveryTimeSearchIsPending:false, message: message});

          return Object.assign({}, state, {
              ...state,
              lastSearch : {
                ...state.lastSearch,
                results : srUpdt
              },
              prevSearch: prUpdt
            });
        }
        case DELIVERY_DATE_FAIL : {
          let record = action.data
          
          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = updateProductList(sr,record.precodice,record.codice, {deliveryTimeSearchIsPending:false});
          let prUpdt = updateProductList(pr,record.precodice,record.codice, {deliveryTimeSearchIsPending:false});

          return Object.assign({}, state, {
              ...state,
              lastSearch : {
                ...state.lastSearch,
                results : srUpdt
              },
              prevSearch: prUpdt
            });
        }

        case DELIVERY_DATE_PROMO_PEND : {

          let record = action.data.product
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);

          let promoProductsUpdated = updateProductList(item.promoProducts,record.precodice,record.codice, {deliveryTimeSearchIsPending:true});

          item.promoProducts = promoProductsUpdated

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }

        case DELIVERY_DATE_PROMO_FAIL: {

          let record = action.data.record
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);

          let promoProductsUpdated = updateProductList(item.promoProducts,record.precodice,record.codice, {deliveryTimeSearchIsPending:false});

          item.promoProducts = promoProductsUpdated

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }

        case DELIVERY_DATE_PROMO_SUCC : {

          let record = action.data.record
          let promoId = action.data.promoId
          let message = action.data.ris.sirmecAvalability

          //all promo
          let promo = state.userPromo.data;

          let item = promo.find(promo => promo.proId===promoId);

          let promoProductsUpdated = updateProductList(item.promoProducts,record.precodice,record.codice, {deliveryTimeSearchIsPending:false, message: message});  
          item.promoProducts = promoProductsUpdated

          let newPromo = promo.map(function(element,index){
            if(element.proId==promoId){
              return item;
            }else{
              return element
            }
          })

          return Object.assign({}, state, {
              ...state,
              userPromo : {
                ...state.userPromo,
                data : newPromo
              }
            });
        }


        case FETCH_LOGOUT_SUCC : {
          return Object.assign({}, state, {
            ...initialState
          });
        }

        /*
        case IAM_AVALAIBILITY_PEND: {

          let record = action.data.record
          let sr = state.lastSearch.results;
          let srUpdt = updateProductList(sr, record.precodice,record.codice, {IAMAvalaibilityPending:true });

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results : srUpdt
            },
          });
        }
        */
        case IAM_AVALAIBILITY_SUCC: {

          let record = action.data.record
          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = updateProductList(sr, record.precodice,record.codice, {IAMAvalaibilityPending:false, avalaibility: action.data.avalaibility});
          let prUpdt = updateProductList(pr,record.precodice,record.codice, {IAMAvalaibilityPending:false,  avalaibility: action.data.avalaibility});


          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results : srUpdt
            },
            prevSearch: prUpdt
          });
        }
        case IAM_AVALAIBILITY_FAIL:{
          let record = action.data.record
          let sr = state.lastSearch.results;
          let srUpdt = updateProductList(sr,record.precodice,record.codice, { IAMAvalaibilityPending:false, message:"Impossibile recuperare la disponibilità"});

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results : srUpdt
            },
          });
        }

        case IAM_AVALAIBILITY_PROMO_SUCC: {

          let record = action.data.record
          let promoId = action.data.promoId

          //all promo
          let promos = state.userPromo.data;
          let promo = promos.find(e => e.proId===promoId);

          let newPromoProducts = updateProductList(promo.promoProducts,record.precodice,record.codice, {IAMAvalaibilityPending:false,  avalaibility: action.data.avalaibility}); 

          let  newPromo = promos.map(function(element){
            if(element.proId===promoId){
              element.promoProducts = newPromoProducts
            }
            return element;
          })


          return Object.assign({}, state, {
            ...state,
            userPromo : {
              ...state.userPromo,
              data : newPromo
            }
          });
        }
        case IAM_AVALAIBILITY_PROMO_FAIL:{
          let record = action.data.record
          let promoId = action.data.promoId

          //all promo
          let promo = state.userPromo.data;
          let item = promo.find(promo => promo.proId===promoId);

          let newPromo = updateProductList(item.promoProducts,record.precodice,record.codice, {IAMAvalaibilityPending:false,  message:"Impossibile recuperare la disponibilità"}); 

          return Object.assign({}, state, {
            ...state,
            userPromo : {
              ...state.userPromo,
              data : newPromo
            }
          });
        }

        case MISSING_SUCC: {  

          let data = null;
          if(action.data.startFrom===undefined || action.data.startFrom===null){
            data = action.data.data
          }else {
            //Append mode
            let old = state.missing.data
            data = old.concat(action.data.data)
          }

          return Object.assign({}, state, {
            ...state,
            missing : {
              ...state.missing,
              requstIsPending : false,
              data : data,
              pagination : action.data.pagination
            },
          });
        }
        case MISSING_PEND: {
          return Object.assign({}, state, {
            ...state,
            missing : {
              ...state.missing,
              requstIsPending : true
            },
          });
        }
        case MISSING_FAIL: {
          return Object.assign({}, state, {
            ...state,
            missing : {
              ...state.missing,
              requstIsPending : false
            },
          });
        }


        case FETCH_BOLLE_PEND : {
          return Object.assign({}, state, {
            ...state,
            bolle : {
              ...state.bolle,
              requstIsPending : true
            },
          });
        }

        case FETCH_BOLLE_SUCC : {

          let old = state.bolle.data || [];
          let res = old.concat(action.data.data)

          return Object.assign({}, state, {
            ...state,
            bolle : {
              ...state.bolle,
              requstIsPending : false,
              data : res,
              pagination: action.data.pagination
            },
          });
        }

        case FETCH_BOLLE_FAIL : {
          return Object.assign({}, state, {
            ...state,
            bolle : {
              ...state.bolle,
              requstIsPending : false,
            },
          });
        }

        case FETCH_BOLLE_CLEAR : {

          return Object.assign({}, state, {
            ...state,
            bolle : initialState.bolle
          });
        }

        case FETCH_BOLLE_DETAIL_PEND : {
          return Object.assign({}, state, {
            ...state,
            bolleDetail : {
              ...state.bolleDetail,
              requstIsPending : true
            },
          });
        }

        case FETCH_BOLLE_DETAIL_SUCC : {

          let old = state.bolleDetail.data || [];
          let res = old.concat(action.data.data)

          return Object.assign({}, state, {
            ...state,
            bolleDetail : {
              ...state.bolleDetail,
              requstIsPending : false,
              data : res,
              pagination: action.data.pagination
            },
          });
        }

        case FETCH_BOLLE_DETAIL_FAIL : {
          return Object.assign({}, state, {
            ...state,
            bolleDetail : {
              ...state.bolleDetail,
              requstIsPending : false,
            },
          });
        }

        case FETCH_BOLLE_CLEAR : {

          return Object.assign({}, state, {
            ...state,
            bolle : initialState.bolle
          });
        }

        case FETCH_BOLLE_DETAIL_CLEAR : {

          return Object.assign({}, state, {
            ...state,
            bolleDetail : initialState.bolleDetail
          });
        }


        case FETCH_FATT_PEND : {
          return Object.assign({}, state, {
            ...state,
            fatture : {
              ...state.fatture,
              requstIsPending : true
            },
          });
        }

        case FETCH_FATT_SUCC : {

          let old = state.fatture.data || [];
          let res = old.concat(action.data.data)
          
          return Object.assign({}, state, {
            ...state,
            fatture : {
              ...state.fatture,
              requstIsPending : false,
              data : res,
              pagination: action.data.pagination
            },
          });
        }

        case FETCH_FATT_FAIL : {
          return Object.assign({}, state, {
            ...state,
            fatture : {
              ...state.fatture,
              requstIsPending : false,
            },
          });
        }

        case SAVE_NOTE_CLIENTE : {

          let sr = state.lastSearch.results;
          let pr = state.prevSearch;
          let cart = state.cart.cartData.productsList

          if(action.data.context==='SEARCH'){
          
            let lsUpdt = updateProductList(sr,action.data.record.precodice,action.data.record.codice, { noteCliente: action.data.noteCliente});
            let prUpdt = updateProductList(pr,action.data.record.precodice,action.data.record.codice, { noteCliente: action.data.noteCliente});
            return Object.assign({}, state, {
              ...state,
              lastSearch : {
                results: lsUpdt
              },
              prevSearch : prUpdt
            });
          }
          else if(action.data.context==='CART'){
            let cartUpdt = updateProductList(cart,action.data.record.precodice,action.data.record.codice, { noteCliente: action.data.noteCliente});
          
            return Object.assign({}, state, {
              ...state,
              cart : {
                cartData : {
                  ...state.cart.cartData,
                  productsList : cartUpdt
                }
              }
            });
          }
        }

        case FETCH_USER_PROMO_PEND : {

          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : true
            }
          });
        }

        case FETCH_USER_PROMO_SUCC : {

          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : false,
              data : action.data
            }
          });
        }

        case FETCH_USER_PROMO_FAIL : {
          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : false,
              data : []
            }
          });
        }

        
        case FETCH_ALL_PROMO_PEND : {

          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : true
            }
          });
        }

        case FETCH_ALL_PROMO_SUCC : {

          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : false,
              data : action.data
            }
          });


        }

        case FETCH_ALL_PROMO_FAIL : {
          return Object.assign({}, state, {
            ...state,
            userPromo : {
              requestIsPending : false,
              data : []
            }
          });
        }


        case FETCH_PROMO_DETAIL_PEND : {
          let id = action.data
          let promos = (state.userPromo.data||[]) .map( (element, index, array) => {
             if(element.proId==id){
               element.searchIsPending=true;
               return element
             }else {
               return element
             }
          })
          
          return Object.assign({}, state, {
            ...state,
            userPromo : {
              ...state.userPromo,
              data : promos
            }
          });
        }

        case FETCH_PROMO_DETAIL_SUCC : {
          let id = action.data.promoId
          let promos = (state.userPromo.data||[]) .map( (element, index, array) => {
             if(element.proId==id){

              //first call reset data
              if(action.data.startFrom===undefined || action.data.startFrom===null || action.data.startFrom===0){
                element = action.data.promo
              }
              //append data
              else{
                let append = {
                  ...element,
                  promoProducts: [...element.promoProducts, ...action.data.promo.promoProducts]
                }
                element = append
              }
               element.searchIsPending=false;
               return element
             }else {
               return element
             }
          })
          
          return Object.assign({}, state, {
            ...state,
            userPromo : {
              ...state.userPromo,
              data : promos
            }
          });
        }

        case FETCH_PROMO_DETAIL_FAIL : {
         
          return Object.assign({}, state, {
            ...state,
          });
        }

        case FETCH_INIT_SUCC : {

          let ris = "" 
          if(action.data !== null &&
              action.data.apiVersion !== undefined){
                ris = action.data.apiVersion
              }
          return  Object.assign({}, state, {
            ...state,
            init : {
              ...state.init,
              apiVersion : ris
            }});
        }

        case FADE_OUT_PRODUCT : {

          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = updateProductList(sr,action.data.precodice,action.data.codice, { animationClass: "fadeOut" });
          let prUpdt = updateProductList(pr,action.data.precodice,action.data.codice, { animationClass: "fadeOut" });

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results : srUpdt
            },
            prevSearch: prUpdt
          });

        }

        case REMOVE_PRODUCT_FROM_SEARCH : {

          let sr = state.lastSearch.results;
          let pr = state.prevSearch;

          let srUpdt = deleteFromProductList(sr,action.data.precodice,action.data.codice);
          let prUpdt = deleteFromProductList(pr,action.data.precodice,action.data.codice);

          return Object.assign({}, state, {
            ...state,
            lastSearch : {
              ...state.lastSearch,
              results : srUpdt
            },
            prevSearch: prUpdt
          });

        }

        case SET_LOGIN_MESSAGE : {
          return Object.assign({}, state, {
            ...state,
            init: {
               ...state.init,
               loadingIsPending : false,
               userIsLogged: false,
               loginMessage: action.data
            }
          });
        }

        case RESI_SUCC: {  

          let data = []
          if(action.data.startFrom===undefined || action.data.startFrom===null){
            data = action.data.data
          }else {
            //Append mode
            data = state.resi.data
            data = data.concat(action.data.data)
          }

          return Object.assign({}, state, {
            ...state,
            resi : {
              ...state.resi,
              requestIsPending : false,
              data : data,
              pagination : action.data.pagination
            },
          });
        }
        case RESI_PEND: {
          return Object.assign({}, state, {
            ...state,
            resi : {
              ...state.resi,
              requestIsPending : true
            },
          });
        }
        case RESI_FAIL: {
          return Object.assign({}, state, {
            ...state,
            resi : {
              ...state.resi,
              requestIsPending : false
            },
          });
        }

        case RESI_REQ_SUCC: {  

          let data = null
          if(action.data.startFrom===undefined || action.data.startFrom===null){
            data = action.data.data
          }else {
            //Append mode
            let old = state.resiReq.data
            data = old.concat(action.data.data)
          }

          return Object.assign({}, state, {
            ...state,
            resiReq : {
              ...state.resiReq,
              requestIsPending : false,
              data : data,
              pagination : action.data.pagination
            },
          });
        }
        case RESI_REQ_PEND: {
          return Object.assign({}, state, {
            ...state,
            resiReq : {
              ...state.resiReq,
              requestIsPending : true
            },
          });
        }
        case RESI_REQ_FAIL: {
          return Object.assign({}, state, {
            ...state,
            resiReq : {
              ...state.resiReq,
              requestIsPending : false
            },
          });
        }

        case FETCH_BEHALF_PEND: {
          return Object.assign({}, state, {
            ...state,
            behalf:{
              requestIsPending:true,
              redirect:false
            }
          })
        }

        case FETCH_BEHALF_SUCC: {
          return Object.assign({}, state, {
            ...initialState,
            init: state.init,
            behalf:{
              requestIsPending:false,
              redirect:true
            },
            user: action.data
          });
        }

        case FETCH_BEHALF_FAIL: {
          return Object.assign({}, state, {
            ...state,
            behalf:{
              requestIsPending:false,
              error: action.data,
              redirect:false
            },
          });
        }

        case FETCH_BEHALF_EXIT_SUCC: {
          return Object.assign({}, state, {
            ...state,
            behalf:{
              requestIsPending:false,
              redirect:false //redirect
            },
            user: action.data
          });
        }

        /* TODO */
        case FETCH_ALTERNATIVE_ERR : {
          return Object.assign({}, state, {
            ...state
          });
        }

        case FETCH_ALTERNATIVE_SUCC : {
            console.log("Reducers FETCH_ALTERNATIVE_SUCC called with params:")
            console.log(action.data)

            let current = action.data.source;
            
            let sr = state.lastSearch.results;
            let srUpdt = updateProductList(sr,current.precodice,current.codice, {suggested: action.data.ris});

            return Object.assign({}, state, {
              ...state,
              lastSearch : {
                ...state.lastSearch,
                results : srUpdt
              }
            });
        }
        
      default:
          return state
      }
  };
  

 //TODO: ADD MIDDLEWARE AND REDUX-THUNK
  export default rootReducer;