import React from 'react';
import { connect } from "react-redux";
import {fetchCart, fetchRemoveFromCart, updateCartNote, fetchUpdateNotes, fetchSendOrder, saveNoteCliente, fetchUpdateQntToCart, fetchBehalf} from "../../actions/actions.js"
import {withRouter, Redirect} from 'react-router-dom'
import { AutoComplete, Button, Row, Spin, Alert } from 'antd';
import {getApiUrl} from '../../utils.js'

import './behalf.css'

const mapStateToProps = state => {
    return { 
        user : state.user,
        behalf: state.behalf
    };
};
function mapDispatchToProps(dispatch) {
    return {
        loginBehalf : (uid) => dispatch(fetchBehalf(uid))
    };
}
class Cart extends React.Component {

    constructor(props) {
        super(props);

        /*
         * LOCAL STATE
        */
        this.state = {
            clients: [],
            allClients : [],
            uidCliente : "",
            user: this.props.user,
            behalf: this.props.behalf

        }

        this.onClientSearch = this.onClientSearch.bind(this)
        this.onSelectClient = this.onSelectClient.bind(this);
    }

    onClientSearch = (searchText) => {
        searchText =  searchText.toUpperCase()
        if(searchText===""){
            this.setState({
                ...this.state,
                clients : this.state.allClients
            })
            return
        }

        let r = this.state.allClients.filter( elem => elem.label.replaceAll('.','').toUpperCase().includes(searchText) ? true : false )
        this.setState({
            ...this.state,
            clients : r
        })
    }

    onSelectClient(value,option){
        this.setState({
            ...this.state,
            uidCliente : option.id
        }, () => console.log("Cliente settato"))
    }

    componentDidMount() {        
        
        fetch(getApiUrl() + "/auth/getAvaibleUsersForBehalf"  ,{
            method: 'GET', 
            cache: 'no-cache',
            credentials: 'include',
            headers: new Headers({
                "Accept" : 'application/json'
            }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.error) {
                throw(res);
            }

            let ris = res.data.map(elem =>{
                    var ret = {
                        label:elem.user.uUsername + " - " + elem.customer.businessName, //+ " - " + elem.city,
                        value:elem.user.uUsername + " - " + elem.customer.businessName, //+ " - " + elem.city,
                        id: elem.user.uId
                    };
                    return ret
                 });

            this.setState({
                ...this.state,
                clients: ris,
                allClients : ris
            })
            
        })
        .catch(res => {
            this.setState({
                ...this.state,
                error: "Errore nel recuperare la lista dei clienti" + res
            })
        })
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        if (this.props.user !== prevProps.user || this.props.behalf !== prevProps.behalf) {
          this.setState ({
            behalf : this.props.behalf,
            user: this.props.user
          });
        }
      }

    render() {

        if(this.state.behalf.redirect){
            return (  <Redirect to={"/home"}/> )
        }

        if(this.state.behalf!==null && this.state.behalf!==undefined && this.state.behalf.requestIsPending ){
            return (
                <Row>
                    <Spin style={{margin:"auto"}} size="large" />
                </Row>
            )
        }

        return (
            <div>
               <label>Digita il nome del cliente (senza punti)</label>
               <AutoComplete options={this.state.clients} style={{ width: "95%", marginBottom:"1em" }}  onSelect={this.onSelectClient} onSearch={this.onClientSearch}  placeholder="Digita il nome del cliente" />
               <Row>
                    <Button onClick={()=> this.props.loginBehalf(this.state.uidCliente)} type="primary">Login</Button>
               </Row>
               <br></br>

               { this.state.behalf.error !== undefined && this.state.behalf.error !== null && this.state.behalf.error !== "" && (
                    <Alert message={this.state.behalf.error} type="error" closable onClose={this.onCloseError} />
                )}

            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Cart));