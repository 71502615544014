import React from 'react';
import { connect } from "react-redux";

import {Divider, DatePicker, Form, Input, Button, Alert} from 'antd'

import 'moment/locale/it'
import locale from 'antd/es/date-picker/locale/it_IT'
import { FormInstance } from 'antd/lib/form';

class Filter extends React.Component {


    constructor(props) {
        super(props);

        let initFilter = {
            articolo : "",
	        dataBollaStart: "",
	        dataBollaEnd: "",
            numeroBolla : "",
            numeroOrdine : "",
            notaOrdine : "",
            notaRiga : "",
        }

    
        /*
         * LOCAL STATE
        */
        this.state = {
            initFilter :  initFilter,
            filter: initFilter,

            updateKey :  1,
            //Function that take filter object
            callback : this.props.callback,

            showError : false,
            errorMessage : ""

        }
        this.handleArticoloChange = this.handleArticoloChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
        this.onChangeDataBolla = this.onChangeDataBolla.bind(this)
        this.onClear = this.onClear.bind(this)
        this.onChangeNumBolla = this.onChangeNumBolla.bind(this)
        this.handleOrdineChange = this.handleOrdineChange.bind(this)
        this.handleNoteOrdini = this.handleNoteOrdini.bind(this)
        this.handleNoteRiga = this.handleNoteRiga.bind(this)
        this.onCloseError = this.onCloseError.bind(this)
    }
    
    onChangeNumBolla(v){


        if (isNaN(v.target.value))
        {
            this.setState({
                ...this.state,
                showError: true,
                errorMessage: "Inserire solo il numero della bolla senza i prefissi B101, B103, ecc"
            })
            setTimeout(this.onCloseError, 5000)
            return
        }

        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                numeroBolla : v.target.value
            }
        })
    }

    onChangeDataBolla(value, stringValeus){

        if(value===undefined || value === null){
            return
        }

        let startMoment = value[0];
        let endMoment = value[1];

        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                dataBollaStart : startMoment.format("YYYY-MM-DD"),
                dataBollaEnd   : endMoment.format("YYYY-MM-DD")
            }
        })
    }

    handleArticoloChange(e){
        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                articolo : e.target.value
            }
        })
    }

    handleOrdineChange(e){
        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                numeroOrdine : e.target.value
            }
        })
    }

    handleNoteOrdini(e){
        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                notaOrdine : e.target.value
            }
        })
    }

    handleNoteRiga(e){
        this.setState({
            ...this.state,
            filter:{
                ...this.state.filter,
                notaRiga : e.target.value
            }
        })
    }

    onCloseError(){

        this.setState({
            ...this.state,
            showError: false,
            errorMessage: ""
        })
    }

    /*
    toggleFilter(){
        this.setState({
            ...this.state,
            showFilter: !this.state.showFilter,
            text: this.state.showFilter ? this.state.textHidden : this.state.textShow
        })
    }
    */

    onSubmit(){
        this.state.callback(this.state.filter)
    }

    onClear(){
        this.setState({
            ...this.state,
            filter : this.state.initFilter,
            updateKey : this.state.updateKey + 1 ,
        })
    }

    render() {

        const { RangePicker } = DatePicker;

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 8 },
            size : 'middle'
        };
        

        if(this.state.showError){

            return(
                <Alert message="Errore" description={this.state.errorMessage} type="error" closable onClose={this.onCloseError} />
            )
        }

        return (
        <div>
                <div>
                    <Form ref={this.formRef} {...layout}>

                        <Divider orientation="left">Filtri per articolo</Divider>
                        <Form.Item width={"15em"} label="Codice articolo">
                            <Input key={ "inp1-" + this.state.updateKey } onChange={this.handleArticoloChange}  placeholder="Inserisci il codice articolo" />
                        </Form.Item>

                        <Divider orientation="left">Filtri per bolla</Divider>
                        <Form.Item width={"15em"} label="Numero Bolla">
                            <Input onChange={this.onChangeNumBolla} key={ "inp2-" + this.state.updateKey } placeholder="Inserisci il numero di bolla" />
                        </Form.Item>
                        <Form.Item width={"15em"} label="Bolle dal ... al">
                            <RangePicker key={ "dpk1-" + this.state.updateKey } locale={locale} ref={this.refRangePick} allowClear onChange={this.onChangeDataBolla} />
                        </Form.Item>

                        <Divider orientation="left" >Filtri per ordine</Divider>
                        <Form.Item width={"15em"} label="Numero Ordine">
                            <Input onChange={this.handleOrdineChange} key={ "inp2-" + this.state.updateKey } placeholder="Esempio 4599" />
                        </Form.Item>
                        {/*
                        <Form.Item width={"15em"} label="Ordini dal ... al">
                            <RangePicker key={ "dpk2-" + this.state.updateKey } locale={locale} allowClear />
                        </Form.Item>
                        */}
                        <Divider orientation="left" >Filtri per note</Divider>
                        <Form.Item width={"15em"} label="Note dell' ordine">
                            <Input onChange={this.handleNoteOrdini} key={ "inp3-" + this.state.updateKey } placeholder="note ordine" />
                        </Form.Item>

                        <Form.Item width={"15em"} label="Note riga">
                            <Input onChange={this.handleNoteRiga} key={ "inp4-" + this.state.updateKey } placeholder="note riga" />
                        </Form.Item>
                    </Form>
                    
                    <Button onClick={this.onSubmit} type="primary" htmlType="submit"> Applica i filtri </Button>

                    <Button style={{marginLeft:"2em"}} onClick={this.onClear} type="primary" htmlType="submit"> Pulisci Filtri </Button>

                    <br />
                    <br />
                </div>
        </div>
        )
    }
}

export default Filter;
