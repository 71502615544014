import React from 'react';
import { withRouter } from 'react-router-dom'
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Radio, Table, Button, Alert, Typography } from 'antd';
import DataColumnUtil from '../SearchResults/dataColumnUtil.js'
import Icon from '@ant-design/icons';
import { getApiUrl } from '../../utils.js'
import { connect } from "react-redux";
import { Tabs } from 'antd';
import { Collapse } from 'antd';
import reducers from 'video-react/lib/reducers';


class ScontiObsolete extends React.Component {

    constructor(props) {
        super(props)

        /* localState */

        this.state = {
            listaSconti: [],
        }

        this.setResults = this.setResults.bind(this)

    }

    setResults(data) {
        this.setState({
            ...this.state,
            listaSconti: data
            
        });
        
        console.log(this.state.listaSconti)
    }

    render() {
        require('./obsolete.css');
        const { Dragger } = Upload;
        //let state = this.state;
        let initLoad = this.initLoad;
        let setResults = this.setResults;
        const types = ["text/csv"];
        const props = {
            name: 'file',
            multiple: false,
            customRequest: function (obj) {
                var formData = new FormData();
                console.log(obj)
                console.log(obj.file)
                formData.append('file', obj.file);
                console.log(formData);
                fetch(getApiUrl() + "/obsolete/loadObsDiscounts",
                    {
                        method: 'POST',
                        credentials: 'include',
                        cache: 'no-cache',
                        headers: new Headers({
                            'Accept': 'application/json'
                        }),
                        body: formData
                    }).then(res => res.json()).then(res => {
                        obj.onSuccess(res)
                        if (!res.error) {
                            setResults(res.data);
                        }
                        if (res.error == true) {

                            message.error("Il file: \"" + obj.file.name + "\" non rispetta i requisiti. Prego consultarli.")

                        }

                        if (typeof (res.data.unparsedObsoletes) !== 'undefined') {

                            message.error("Attenzione il file: \"" + obj.file.name + "\" è stato caricato ma contiene degli errori")

                        }
                        // if (res.data.unparsedObsoletes.length !=  0){ 
                        //     message.error ("Attenzione il file: \"" + obj.file.name + "\" è stato caricato ma contiene degli errori")
                        //   }

                    }).catch(err => {

                        obj.onError(err);

                    }).catch(res => {

                        message.error("Errore nell'upload del file: " + res.error)
                    })
            },
            onChange: function (obj) {
                const status = obj.status;

                if (status !== 'uploading') {
                    console.log(obj.file, obj.fileList);
                }
                if (status === 'done') {
                    console.log(status);
                    message.success(`${obj.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${obj.file.name} file upload failed.`);
                }
            }


        }
        const columns = [
            {
                title: 'Id Cliente', width: "16%", key: 'uIdCliente', index: 'uIdCliente',
                render: function (text, record, index) {
                    return (<span>{record.uIdCliente}</span>);
                },
                
              },
              {
                title: 'Sconto Acquisto', width: "16%", key: 'odScontoAcq', index: 'odScontoAcq',
                render: function (text, record, index) {
                    return (<span>{record.odScontoAcq}</span>);
                },
                
              },
              {
                title: 'Sconto Vendita', width: "16%", key: 'odScontoVend', index: 'odScontoVend',
                render: function (text, record, index) {
                    return (<span>{record.odScontoVend}</span>);
                },
                
              }, 
              {
                title: 'Dal', width: "16%", key: 'odStartTime', index: 'odStartTime',
                render: function (text, record, index) {
                    return (<span>{record.odStartTime}</span>);
                },
                
              },
              {
                title: 'Al', width: "16%", key: 'odEndTime', index: 'odEndTime',
                render: function (text, record, index) {
                    return (<span>{record.odEndTime}</span>);
                },

               
              }, 
              {
                title: 'Lettera Sconto', width: "16%",  key: 'odCatSc', index: 'odCatSc',
                render: function (text, record, index) {
                    return (<span>{record.odCatSc}</span>);
                },
                
              }, 
             

        ]

        const { Panel } = Collapse;



        return (
            <div>
                <h1>Sconti Prodotti Obsoleti</h1>
                <Collapse accordion>
                    <Panel header="Mostra i requisiti del file da caricare" key="1">
                        <p>Il form accetta <b>solo</b> file di formato <em>.CSV</em> con i seguenti requisiti:</p>
                        <ul>
                            <li>I campi devono essere forniti nell'ordine:
                                <table className='requisiti'><tr><th className='requisiti'>Id Cliente</th><th className='requisiti'>Sconto Acquisto</th><th className='requisiti'>Sconto Vendita</th><th className='requisiti'>Data Partenza Sconto</th><th className='requisiti'>Data Fine Sconto</th><th className='requisiti'>Lettera Sconto</th></tr></table>
                            </li>

                            <li>Utilizzare il separatore "<b className='puntoVirgola'>;</b>" tra i campi</li>
                            <li>Non deve contenere intestazioni</li>
                        </ul>
                    </Panel>
                </Collapse>

                <title></title>
                <body>
                    <Dragger {...props} accept=".csv" beforeUpload={(file) => {
                        if (!types.includes(file.type)) {
                            message.error(`${file.name} is not a csv file`);
                            return false;
                        } else {
                            return true
                        }
                    }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <h1>Seleziona o trascina un file che contiene i codici e le quantità da caricare per importare il suo contenuto nel carrello</h1>
                        <h1>Il file deve contenere su ogni riga il codice e la quantità</h1>
                    </Dragger>

                    <br></br>
                    
                            <div><Table columns={columns} dataSource={this.state.listaSconti}></Table></div>
                        




                </body>
            </div>
        )
    }

}
export default withRouter(ScontiObsolete)