import { Button, Form, Input, InputNumber, message, Radio, Select } from "antd"
import FormItem from "antd/lib/form/FormItem"
import { FormProvider } from "rc-field-form"
import React from "react"
import { getApiUrl } from "../../utils"


class CustomerInfoSingleForm extends React.Component {

    //Costruttore
    //componentDidMount
    //onDidUpdate
    //render  

    // Costruttore
    constructor(props) {
        super(props)
        this.state = {
            cNumTecnici: 1,
            cNumAccettatori: 1,
            cNumPonti: 1,
            cMarcaOlio: null,
            cServizioGomme: false,
            showInputMarcaOlio: false
        }
        //bindingThis
        this.onChangeNumTecnici = this.onChangeNumTecnici.bind(this);
        this.onChangeNumAccettatori = this.onChangeNumAccettatori.bind(this);
        this.onChangeNumPonti = this.onChangeNumPonti.bind(this);
        this.onChangeMarcaOlio = this.onChangeMarcaOlio.bind(this);
        this.onInputMarcaOlio = this.onInputMarcaOlio.bind(this);
        this.onChangeServizioGomme = this.onChangeServizioGomme.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    }

    //componentDidMount
    componentDidMount() {
        let getCustomerInfoEndpoint = getApiUrl() + "/registration/getCustomerInfo";
        fetch(getCustomerInfoEndpoint, {
            method: "GET",
            credentials: "include",
            headers: new Headers({
                "Accept": "application/json"
            })
        }
        ).then(res => res.json()).then(res => {
            if (res.error) {
                throw(res.error)
            }else{
                let resData = res.data
                if(resData !== undefined && res.data.length !== 0){
                    let singleCustomerInfo = resData[0]
                    this.setState({
                        ...this.state,
                        cNumAccettatori: singleCustomerInfo.cNumAccettatori,
                        cNumPonti: singleCustomerInfo.cNumPonti,
                        cNumTecnici: singleCustomerInfo.cNumTecnici,
                        cServizioGomme: singleCustomerInfo.cServizioGomme,
                        cMarcaOlio: singleCustomerInfo.cMarcaOlio
                    })
                    message.success("Informazioni del cliente caricate con successo")
                }
            }
        }
        ).catch(error => message.error("C'è stato un problema nel recuperare i dati dell'utente"))
    }


render() {
    const { Option, OptGroup } = Select;
    return (
        <div>
            <Form layout="horizontal" wrapperCol={{ span: 2 }} labelCol={{
    span: 4
}}>
                <Form.Item label="Numero Tecnici: ">
                    <InputNumber value = {this.state.cNumTecnici} onChange={this.onChangeNumTecnici}></InputNumber>
                </Form.Item>
                <Form.Item label="Numero Accettatori: ">
                    <InputNumber value = {this.state.cNumAccettatori} onChange={this.onChangeNumAccettatori}></InputNumber>
                </Form.Item>
                <Form.Item label="Numero Ponti: ">
                    <InputNumber value={this.state.cNumPonti} onChange={this.onChangeNumPonti}></InputNumber>
                </Form.Item>
                <Form.Item label="Marca olio utilizzata">
                    <Select value={this.state.cMarcaOlio} onChange={this.onChangeMarcaOlio}>
                        <Option value="Eurorepar">Eurorepar</Option>
                        <Option value="Fuchs">Fuchs</Option>
                        <Option value="Shell">Shell</Option>
                        <Option value="Castrol">Castrol</Option>
                        <Option value="Total">Total</Option>
                        <Option value="Mobil">Mobil</Option>
                        <Option value="Altro">Altro</Option>
                    </Select>
                    <Input hidden={!this.state.showInputMarcaOlio} onChange={this.onInputMarcaOlio} />
                </Form.Item>
                <Form.Item label="Servizio Gomme">
                    <Radio.Group value={this.state.cServizioGomme} onChange={this.onChangeServizioGomme}>
                        <Radio value={true} >SI</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.onSubmit} >Salva Dati</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

onChangeNumTecnici(event) {
    this.setState({
        ...this.state,
        cNumTecnici: event
    })
    console.log('cnumTecnici: ' + this.state.cNumTecnici)
}

onChangeNumAccettatori(event) {
    this.setState({
        ...this.state,
        cNumAccettatori: event
    }
    )
}

onChangeNumPonti(event) {
    this.setState({
        ...this.state,
        cNumPonti: event
    }
    )
}

onChangeMarcaOlio(dati) {
    this.setState({
        ...this.state,
        cMarcaOlio: dati,

    }, () => {
        if (dati === "Altro") {
            this.setState({
                ...this.state,
                showInputMarcaOlio: true
            })
        } else {
            this.setState({
                ...this.state,
                showInputMarcaOlio: false
            })
        }
    })


}

onInputMarcaOlio(event){
    this.setState({
        ...this.state,
        cMarcaOlio: event.target.value
    })
}

onChangeServizioGomme(event){
    this.setState({
        ...this.state,
        cServizioGomme: event.target.value
    })
}

onSubmit(event){
    let singleCustomerInfo = { ...this.state }
    let addCustomerInfoEndpoint = getApiUrl() + "/registration/addCustomerInfo"
    fetch(addCustomerInfoEndpoint, {
        credentials: "include",
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-type": "application/json"
        }),
        body: JSON.stringify(singleCustomerInfo)
    }).then(
        res => res.json()
    ).then(res => {
        if (res.error) {
            throw (res.error)
        } else {
            let customerInfoList = []
            customerInfoList = res.data
            //TODO FIX ENDPOINT MUST RETURN ONLY ONE DATA
            let singleCustomer = customerInfoList[0]
            this.setState({
                ...this.state,
                cNumAccettatori: singleCustomer.cNumAccettatori,
                cNumTecnici: singleCustomer.cNumTecnici,
                cNumPonti: singleCustomer.cNumPonti,
                cMarcaOlio: singleCustomer.cMarcaOlio,
                cServizioGomme: singleCustomer.cServizioGomme
            })
            message.success("Informazioni clienti registrate con successo");
        }
    }
    ).catch(error =>
        message.error("C'è stato un problema nel registrare le informazioni del cliente")
    )




}


}

export default CustomerInfoSingleForm