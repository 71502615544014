import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import {message} from 'antd';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {moneyFormat} from "./utils.js"
import {discountFormat} from "./utils.js"
import {getApiUrl} from './utils.js'

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    intestazione : { width: '85%', marginTop: "10em", marginLeft: "10em" },
    summaryRow : { fontSize: '10px', marginTop: "6em", marginLeft: "12em",  fontWeight: 900 },
    intestazioneImg : {width: '85%'},
    row: { display: "flex", flexDirection: "row", fontSize: 8, width: '84%', marginLeft: '8%', marginTop: '10em'},
    column: { display: "flex", flexDirection: "column", flexGrow: 1, minWidth:'0' },
    td : {border: "1px solid #cacaca", padding: "4em",  /*display: "flex", flexDirection: "column", flexGrow: 1, alignItems:"baseline"*/ },
    th : {border: "1px solid #cacaca", padding: "4em", fontWeight: 900},
    cancelled : {textDecoration: 'line-through'}
  });
  
const getOrderDetail =  (order) => {

    console.log("Printing order");
    console.log(order)

    return fetch(getApiUrl() + "/orders/orderDetails", {
        credentials: "include",
        method: "POST",
        headers: new Headers({
            "Accept" : 'application/json',
            "Content-Type": 'application/json'
        }),
        body: JSON.stringify(order)
    }) 
}


export const CreatePDF = (props) => {
    
     console.log("printing order with details:")
     console.log(props.detail)


    let intestazione= ""
    if(props.order.tipoDoc==="OW03"){
      intestazione = process.env.PUBLIC_URL + '/sirmec-intestazione_ca.png';
    }else {
      intestazione = process.env.PUBLIC_URL + '/sirmec-intestazione.png';
    }

    let valueCellStyle=null

     return (
        <Document>
          <Page size="A4">
        
          <View style={styles.intestazione}> 
                  <Image src={intestazione} style={styles.intestazioneImg}  />
          </View>
          
          <View> 
                <Text style={styles.summaryRow}>Ordine numero: {props.order.numeroDoc} </Text>
                <Text style={styles.summaryRow}>Data: {props.order.dataDocumento.substring(0, 10)}  </Text>
                <Text style={styles.summaryRow}>Importo: {moneyFormat(props.order.netTotal)} </Text>
          </View>
          
          <View style={ { ...styles.row }}>
              <View style={styles.column}> 
                <View style={ { ...styles.th, } }>
                
                <Text>Precodice</Text></View>
                {
                  props.detail.map((value, index) => {

                    if(value.descrizioneMancataVendita!==undefined){
                      valueCellStyle = styles.cancelled
                    }
                    return (
                      <View style={styles.td}><Text style={valueCellStyle} >{value.precodice}</Text></View> 
                    )
                  })
                }
              </View>

              <View style={styles.column}>
                   <View style={{ ...styles.th } }><Text>Codice</Text></View>
                {
                props.detail.map((value, index) => {
                   return <View style={styles.td}><Text style={valueCellStyle} >{value.codice}</Text></View> 
                })
                }
              </View>

              <View style={styles.column}>
                <View style={styles.th}><Text>Descrizione</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={{...styles.td, with:'100px'}}>
                        <Text style={{ ...valueCellStyle, whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', minWidth:'0', maxWidth:'100px'}} >{value.description}</Text>
                      </View> 
                    )
                  })
                }
              </View>

              <View style={{...styles.column}}>
                <View style={styles.th}><Text>Note Cliente</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={{...styles.td, textOverflow:'ellipsis', maxWidth: '100px',overflow: 'hidden'}}>
                        <Text style={{ ...valueCellStyle, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:'100px'}} >{value.noteCliente}</Text></View> 
                    )
                  })
                }
              </View>

              <View style={styles.column}>
                <View style={styles.th}><Text>Listino</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={styles.td}><Text style={valueCellStyle} >{moneyFormat(value.listino)}</Text></View> 
                    )
                  })
                }
              </View>

              <View style={styles.column}>
                <View style={styles.th}><Text>Sconto</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={styles.td}><Text style={valueCellStyle}>{discountFormat(value.sconto)}%</Text></View> 
                    )
                  })
                }
              </View>
              <View style={styles.column}>
                <View style={styles.th}><Text>Netto</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={styles.td}><Text style={valueCellStyle}>{moneyFormat(value.netto)}</Text></View> 
                    )
                  })
                }
              </View>

              <View style={styles.column}>
                <View style={styles.th}><Text>Qnta</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={styles.td}><Text style={valueCellStyle}>{value.qntRichiesta}</Text></View> 
                    )
                  })
                }
              </View>

              <View style={styles.column}>
                <View style={styles.th}><Text>Tot. Netto</Text></View>
                {
                  props.detail.map((value, index) => {
                    return (
                      <View style={styles.td}><Text style={valueCellStyle}>{moneyFormat(value.totNettoRichiesto)}</Text></View> 
                    )
                  })
                }
              </View>

          </View>
    
          </Page>
        </Document>
        )
}