import React from 'react';
import { Menu, Upload } from 'antd';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    SearchOutlined, UserOutlined, VerticalRightOutlined, BarcodeOutlined, CarOutlined, QuestionOutlined, FormOutlined,
    MenuOutlined, PercentageOutlined, EditOutlined, HomeOutlined, OrderedListOutlined, LogoutOutlined,
    ExclamationCircleOutlined, SlidersOutlined, UsergroupAddOutlined, BookOutlined, FileAddOutlined,
    UserSwitchOutlined, SettingOutlined, StockOutlined, UploadOutlined, BarChartOutlined, DeliveredProcedureOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import MenuItem from 'antd/lib/menu/MenuItem';
import './menubar.css';

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

const { SubMenu } = Menu;

class MenuBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user
        }
    }
    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {

        console.log("MenuBar: ComponentDidUpdate")

        if (this.props.user !== prevProps.user) {
            this.setState({
                ...this.state,
                user: this.props.user,
            });
        }
    }

    render() {

        console.log("User is")
        console.log(this.state.user)
        return (
            <div className="menuBar">
                <Menu mode="horizontal">

                    <Menu.SubMenu title={
                        <span className="menuBar item">
                            <MenuOutlined />  Menu
                        </span>
                    }>

                        {/*<Menu.ItemGroup title="Ricerca"> */}
                        <Menu.Item key="setting:1">
                            <Link to={"/home"}> <span><SearchOutlined />Ricerca codici</span></Link>
                        </Menu.Item>
                        <Menu.Item key="setting:2">
                            <Link to={"/missing"}><span><ExclamationCircleOutlined /> Lista mancanti</span></Link>
                        </Menu.Item>
                        <Menu.Item key="setting:3">
                            <Link to={"/orderList"}><span><OrderedListOutlined />Storico Ordini</span></Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="setting:3a">
                            <Link to={"/listaResi"}><span><OrderedListOutlined />Lista Resi</span></Link>
                        </Menu.Item>
                        <Menu.Item key="setting:3b">
                            <Link to={"/nuovoReso"}><span><OrderedListOutlined />Nuovo Reso</span></Link>
                        </Menu.Item>
                        <Menu.Item key="setting:3c">
                            <Link to={"/richiesteReso"}><span><OrderedListOutlined />Richieste di Reso</span></Link>
                        </Menu.Item>
                        {/* </Menu.ItemGroup> */}
                        <Menu.Divider />
                        <Menu.Item key="setting:4">
                            <Link to={"/promoList"}><span><PercentageOutlined />Promo</span></Link>
                        </Menu.Item>

                        <Menu.Divider />
                        {/*<Menu.ItemGroup title="Cataloghi"> */}
                        <Menu.Item key="setting:5">
                            <Link to={"/kstore"}>
                                <span> <VerticalRightOutlined /> Catalogo KSTORE </span>
                            </Link>
                        </Menu.Item>
                        {this.state.user.uIsObsoleteSupplier && (
                            <Menu.Divider />
                        )}
                        {this.state.user.uIsObsoleteSupplier && (


                            <SubMenu title="Obsoleti" >
                                <Menu.Item key="14"><Link to={"/obsolete"}><span><UploadOutlined />Carica Obsoleti</span></Link></Menu.Item>
                                <Menu.Item key="15"><Link to={"/listaObsolete"}><span><BarcodeOutlined />Mostra Obsoleti Caricati</span></Link></Menu.Item>
                                {this.state.user.uRole === "ADMIN" && (
                                    <Menu.Item key="16"><Link to={"/scontiObsolete"}><span><PercentageOutlined />Carica Sconti</span></Link></Menu.Item>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <Menu.Item key="17"><Link to={"/visualizzaSconti"}><span><BarChartOutlined />Visualizza Sconti</span></Link></Menu.Item>
                                )}
                            </SubMenu>

                        )}
                        { /*
                        <Menu.Item key="setting:6"><span><CarOutlined />Catalogo Vetri/Carr./Fari</span></Menu.Item>
                        */ }
                        {/*</Menu.ItemGroup>*/}
                        <Menu.Divider />
                        <Menu.Item key="setting:7">
                            <Link to={"/documents"}><FormOutlined />Documenti</Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="setting:8">
                            <Link to={"/customerInfo"}><FormOutlined />Profilo Utente</Link>
                        </Menu.Item>
                        {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                            <Menu.Divider />
                        )}

                        {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                            <SubMenu key="sub2" icon={<SettingOutlined />} title="Amministrazione">

                                { /* this.state.user.uRole==="ADMIN" && (
                            <MenuItem key="setting:8">
                                <span><UsergroupAddOutlined />Gestisci Utenti</span>
                            </MenuItem>
                        )*/ }
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:9">
                                        <Link to={"/promoConsole"}><span><SlidersOutlined /> Gestisci Promo</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:9g">
                                        <Link to={"/loadTariffario"}><span><UploadOutlined /> Carica Tariffario Autodoc</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:9h">
                                        <Link to={"/orderListTracking"}><span><DeliveredProcedureOutlined />Inserisci Tracking</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                                    <MenuItem key="setting:9b">
                                        <Link to={"/reportConsole/000000"}><span> <CarOutlined />Report Visite </span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                                    <MenuItem key="setting:9c">
                                        <Link to={"/behalf"}><span> <UserSwitchOutlined /> Impersonifica utente</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN") && (
                                    <MenuItem key="setting:9d">
                                        <Link to={"/stockCheck"}><span> <StockOutlined /> Analisi dello Stock</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:9e">
                                        <Link to={"/registration"}><span><UsergroupAddOutlined /> Gestione registrazione utente</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN") && (
                                    <MenuItem key="setting:9f">
                                        <Link to={"/searchReport"}><span> <StockOutlined /> Report delle ricerche</span></Link>
                                    </MenuItem>
                                )}
                            </SubMenu>
                        )}

                        <Menu.Divider />

                        <MenuItem key="setting:10">
                            <Link to={"/contacts"}>
                                <span><BookOutlined />Contatti</span>
                            </Link>
                        </MenuItem>

                        <Menu.Divider />

                        <MenuItem key="setting:10bis">
                            <Link to={"/logout"}>
                                <span><LogoutOutlined />Disconnessione</span>
                            </Link>
                        </MenuItem>

                    </Menu.SubMenu>

                    <MenuItem key="setting:11">
                        <Link to={"/home"}>
                            <span className="menuBar item"><SearchOutlined /> Ricerca codici</span>
                        </Link>
                    </MenuItem>

                    <MenuItem key="setting:12">
                        <Link to={"/kstore"}><span className="menuBar item"><CarOutlined />Catalogo KSTORE</span></Link>
                    </MenuItem>
                    {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && <Menu.SubMenu
                        title={
                            <span className="menuBar item">
                                <StockOutlined></StockOutlined>Amministrazione
                            </span>}>
                            {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:1">
                                        <Link to={"/promoConsole"}><span><SlidersOutlined /> Gestisci Promo</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:2">
                                        <Link to={"/loadTariffario"}><span><UploadOutlined /> Carica Tariffario Autodoc</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:2">
                                        <Link to={"/orderListTracking"}><span><DeliveredProcedureOutlined />Inserisci Tracking</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                                    <MenuItem key="setting:3">
                                        <Link to={"/reportConsole/000000"}><span> <CarOutlined />Report Visite </span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN" || this.state.user.uRole === "SALESMAN") && (
                                    <MenuItem key="setting:4">
                                        <Link to={"/behalf"}><span> <UserSwitchOutlined /> Impersonifica utente</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN") && (
                                    <MenuItem key="setting:5">
                                        <Link to={"/stockCheck"}><span> <StockOutlined /> Analisi dello Stock</span></Link>
                                    </MenuItem>
                                )}
                                {this.state.user.uRole === "ADMIN" && (
                                    <MenuItem key="setting:6">
                                        <Link to={"/registration"}><span><UsergroupAddOutlined /> Gestione registrazione utente</span></Link>
                                    </MenuItem>
                                )}
                                {(this.state.user.uRole === "ADMIN") && (
                                    <MenuItem key="setting:7">
                                        <Link to={"/searchReport"}><span> <StockOutlined /> Report delle ricerche</span></Link>
                                    </MenuItem>
                                )}
                                                 
                    </Menu.SubMenu>
                    }


                </Menu>
            </div>
        )
    }

}

export default connect(mapStateToProps, null)(MenuBar);