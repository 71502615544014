import React from 'react';
import Promo from '../Promo/promo.js'

import { connect } from "react-redux";

import { Col, Row, message } from 'antd';

import {fetchUserPromo} from "../../actions/actions.js"

const mapStateToProps = state => {
    return { 
        userPromo : state.userPromo
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchUserPromo: () => dispatch(fetchUserPromo())
    };
}

class PromoList extends React.Component {

    constructor(props) {
            
        super(props);
        this.state = {
            userPromo : this.props.userPromo,
        } 
    }

    componentDidMount() {
        this.props.fetchUserPromo();
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        console.log("Home: ComponentDidUpdate")

        if (this.props.userPromo !== prevProps.userPromo) {
            this.setState ({
              ...this.state,
              userPromo : this.props.userPromo
            });
        }
    }

    render() {
        return (
            <div className="site-layout-background" style={{minHeight: 380 }}>

                <Row style={{ marginTop: "2em", overflowX: "scroll" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        
                    { this.state.userPromo.requestIsPending===false && (
                    <Row gutter={[32,32]}>
                        { (this.state.userPromo.data || [] ).map((promo, index) => {
                            return (
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}   >    
                                    <Promo promo={promo} admin={false} />
                                </Col>
                            )})}
                    </Row>
                    )}
                    </Col>
                </Row>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromoList);