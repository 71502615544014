import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Card, Avatar, Row, Col, message, Spin, Modal } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import './promoDetail.css'
import {withRouter} from 'react-router-dom'
import Promo from '../Promo/promo.js'
import SearchResults from '../SearchResults/searchResults.js'
import {fetchPromoDetail} from "../../actions/actions.js"
import PromoEditor from "../PromoEditor/promoEditor.js"
import { Player, ControlBar, PlayToggle } from 'video-react';
import {fetchSearch, fetchIAMAvalaibility} from '../../actions/actions.js'
const mapStateToProps = state => {
    return { 
        userPromo : state.userPromo,
        
    };
};
function mapDispatchToProps(dispatch) {
    return {
        fetchPromoDetail : (promoId, startFrom) => dispatch(fetchPromoDetail(promoId,startFrom)),
        fetchIAMAvalaibility: () => dispatch(fetchIAMAvalaibility()),
       

    };
}


class PromoDetail extends React.Component {

    constructor(props) {
        super(props);

        let proId = null;
        let editMode = false;

        /*
         * PROMO LOADED AS COMPONENT
         */
        if(props.proId!==undefined){
            proId = this.props.proId
        }else{
            /*
            * PROMO LOADED AS PAGE - FIND PARAMETER IN URL
            */
            let url = new URL(window.location.href)
            let pathandQuery = url.pathname
            let split = pathandQuery.split('/');
            proId = split[split.length - 1]

            if(pathandQuery.indexOf("/edit/")!==-1){
                editMode = true;
               
            }
        }


        /*
         * LOCAL STATE
        */
        this.state = {
            editMode : editMode,
            promo : this.props.userPromo,
            startFrom: 0,
            proId: proId,
            actualPromo : (this.props.userPromo.data || []).find(element =>element.proId === proId)
        }


        this.closeEdit = this.closeEdit.bind(this);
    }


    closeEdit(){
        this.setState({
            ...this.state,
            editMode : false
        })
    }

    componentDidMount() {
    
        this.props.fetchPromoDetail(this.state.proId, this.state.startFrom);
    
        
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        console.log("----> PromoDetail: ComponentDidUpdate props")
        console.log( this.props);
        console.log("----> PromoDetail: ComponentDidUpdate prevProps")
        console.log(prevProps);

        if(this.props.userPromo !== prevProps.userPromo || 
            this.props.userPromo.data !== prevProps.userPromo.data || 
            this.props.userPromo.promoProducts !== prevProps.userPromo.promoProducts ){
            console.log("props" + this.props.userPromo)
            console.log("prevprops" + prevProps.userPromo)
            
            let actualPromo = (this.props.userPromo.data || []).find(element =>element.proId === this.state.proId)
           
            console.log(actualPromo);
            this.setState({
                ...this.state,
                promo: this.props.userPromo,
                actualPromo : actualPromo,
              
            })
        }

       

    }

    render() {

            if(this.state.actualPromo===undefined || this.state.actualPromo.searchIsPending === undefined ||
                this.state.actualPromo.searchIsPending === true){
                return (<Spin style={{marginLeft:"49%", paddingTop:"2em"}} size="large" tip="Loading promo..." />)
            }
            else {

                if(this.state.editMode===true){
                        return (
                            <Modal width={"75%"} title="Modifica Promo" visible={this.state.editMode} onOk={this.handleOk} onCancel={this.closeEdit} okButtonProps={{ style: { display: 'none' } }} >
                                <PromoEditor promo={this.state.actualPromo}/>
                            </Modal>
                        )
                }
                else
                {
                    return (
                        <div>
                            <Promo promo={this.state.actualPromo} admin={false}  cssStyle="promoBig" />

                            {/*add other images*/}
                            {(this.state.actualPromo.proImgUrl || []).map((value, index) => {
                                    return <img style={{marignBottom:"2em"}} src={value} ></img>
                            })}
                             {/*add video*/}
                             {(this.state.actualPromo.proVideoUrl || []).map((value, index) => {
                                    return (
                                        <>
                                        <Player preload={"none"} src={value}>
                                            <ControlBar autoHide={false} disableDefaultControls={false}>
                                                <PlayToggle />
                                            </ControlBar>
                                        </Player>
                                        <br />
                                        </>
                                    )
                            })}

                    
                            <Row style={{ marginTop: "2em", overflowX: "scroll" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <SearchResults mode="PROMODETAIL" trigger={this.state.actualPromo.promoProducts} userPromo={this.state.actualPromo} className="searchResults" />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            }
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PromoDetail));