import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Descriptions, Spin  } from 'antd';
import { connect } from "react-redux";
import {fetchAuth} from '../../actions/actions.js'
import {Redirect } from "react-router-dom";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import './login.css'



const mapStateToProps = state => {
    return { 
        init : state.init,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fetchAuth : (usrnm,pwd, fp) => dispatch(fetchAuth(usrnm,pwd,fp))
    };
}

class Login extends React.Component {


    constructor(props) {
        super(props);

        let max = 6;
        let random = Math.floor(Math.random() * max) + 1

        /*
         * LOCAL STATE
        */
        this.state = {

            background:  process.env.PUBLIC_URL + '/login-background/' + random + '.jpg',
            username : "",
            password : "",
            fp: "",
            
            init: {
                userIsLogged: false,
                loadingIsPending: false,
                //Load message that can be setted from another action when session expired
                loginMessage: this.props.init.loginMessage || "" ,
            },
        }

        this.userRef = React.createRef();
        this.pwdRef =  React.createRef();

        this.handleOnClick = this.handleOnClick.bind(this);

    }

    callAuth(user, pass){
        this.props.fetchAuth(user,pass, this.state.fp);
    }

    componentDidMount(){

        (async () => {
            const fp = await FingerprintJS.load();
            // The FingerprintJS agent is ready.
            // Get a visitor identifier when you'd like to.
            const result = await fp.get();

            this.setState({
                ...this.state,
                fp: result.visitorId
            })
        })();
    }


    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
        
        console.log("Login: ComponentDidUpdate")

        if (this.props.init !== prevProps.init) {
          this.setState ({
            ...this.state,
            init:{ 
                ...this.props.init,
            },
          });
        }
      }

    
    handleOnClick(){
       this.callAuth(this.userRef.current.props.value,this.pwdRef.current.props.value);
    }  

    render() {

        if (this.state.init.userIsLogged === true) {

            let par = new URLSearchParams(window.location.search).get("from")

            if(par===null || par===undefined || par==='/logout' || par === "/"){
                return <Redirect to='/home' />
            }else{
                return <Redirect to={par} />
            }
            
        }

        return (

            <div style={{backgroundColor:'#eaeaea'}} >

                <Row className="header">
                    <img src={process.env.PUBLIC_URL + '/sirmec-logo_512_trasp.png'} style={{ marginLeft:'3em', maxHeight: '6em' }}></img>
                </Row>

                <Col style={{margin:'auto'}} xs={24} sm={24} md={24} lg={22} xl={18}>
                
                <Row style={{margin:'auto'}}>

                        <Col className="col1" xs={0} sm={0} md={11} lg={14} xl={14}>
                           <div style={{backgroundImage:'url(' + this.state.background + ')' }} className="loginPageBackground">
                           </div>
                        </Col>

                        <Col xs={24} sm={24} md={11} lg={8} xl={8}>
                        
                           <div className="loginPanel">
                            <h1>Benvenuto nel nostro B2B</h1>
                            <img src={process.env.PUBLIC_URL + '/sirmec-logo_512_trasp.png'} style={{ maxWidth: '100%', maxHeight: '10em', margin:'auto' }}></img>

                            <Form style={{ marginTop: '2em' }}
                                initialValues={{ remember: true }}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Per favore inserisci il tuo username' }]}
                                >
                                    <Input ref={this.userRef} />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Per favore inserisci la tua password' }]}
                                >
                                    <Input.Password ref={this.pwdRef} />
                                </Form.Item>

                                <Form.Item name="remember" valuePropName="checked">
                                    <Checkbox>Ricordami</Checkbox>
                                </Form.Item>

                                <Form.Item >
                                    <Button onClick={this.handleOnClick} type="primary">
                                        Accedi
                                        </Button>
                                </Form.Item>

                                { this.state.init.loadingIsPending && (
                                   <Spin size="large" />
                                )}

                                { this.state.init.loginMessage && (
                                <Form.Item className="loginMessage" >
                                    <img src="/icon/icons8-avviso-di-freno-48.png"></img> &nbsp;
                                    <Descriptions.Item label="Remark">{this.state.init.loginMessage}</Descriptions.Item>
                                </Form.Item>
                                )
                                }   

                            </Form>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>



        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Login);