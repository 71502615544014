import React from 'react';
import Search from '../Search/search.js'
import Slider from '../Slider/slider.js'
import SearchResults from '../SearchResults/searchResults.js'
import ActionBar from '../ActionBar/actionBar.js'
import { connect } from "react-redux";

import { Col, Row, message } from 'antd';
import {Card, Avatar} from 'antd';
import './contacts.css';


import { PhoneOutlined, MailOutlined } from '@ant-design/icons';

const { Meta } = Card;


class Contacts extends React.Component {

    constructor(props) {
            
        super(props);
        this.state = {
        } 
    }

    componentDidMount() {
    }

    /* Manage state update */
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <div className="contacts-page" style={{minHeight: 380 }}> 
            

            {/* MAGAZZINO */}
            <div className="mainSection">
                <h1>Call Center e Magazzino Senise</h1>
                <h4>Per supporto, ricerca ricambi, resi, bolle, consegne, spedizioni</h4>

                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-telefono-48.png'}  ></img> <h4 className="contact" ><a href="tel:0973584315">0973 584315</a></h4>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} ></img> <h4 className="contact"><a href="mailto:mag@sirmec.eu">mag@sirmec.eu</a></h4>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-whatsapp-48.png'} ></img> <h4 className="contact"><a href="tel:3701422667">370 14 22 667</a></h4>
                    </Col>
                </Row>

                <Row style={{marginTop:"1em"}} gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card  className="user-image-cover" cover={<img alt="Nicola Angiolillo" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Nicola Angiolillo" description="Resp. Approvvigionamento"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:nicola.angiolillo@sirmec.eu">nicola.angiolillo@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Rocco Marcone" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Rocco Marcone" description="Resp. Magazzino"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:rocco.marcone@sirmec.eu">rocco.marcone@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card  className="user-image-cover" cover={<img alt="Marco Forciniti" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Marco Forciniti" description="Resi e Reclami"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:marco.forciniti@sirmec.eu">marco.forciniti@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card  className="user-image-cover" cover={<img alt="Antonio Caporusso" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Antonio Caporusso" description="Preventivi, Ordini, Disponibilità"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:antonio.caporusso@sirmec.eu">antonio.caporusso@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* MAGAZZINO CAGLIARI */}
            <div className="mainSection">
                <h1>Call Center Magazzino Cagliari</h1>
                <h4>Per supporto, ricerca ricambi, resi, bolle, consegne, spedizioni</h4>

                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} ></img> <h4 className="contact"><a href="mailto:magcagliari@sirmec.eu">magcagliari@sirmec.eu</a></h4>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-whatsapp-48.png'} ></img> <h4 className="contact"><a href="tel:3773386949">377 33 86 949</a></h4>
                    </Col>
                </Row>

                <Row style={{marginTop:"1em"}} gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Dario Uda" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Dario Uda" description="Resp. Magazzino Cagliari"/>
                            <div style={{marginTop:"1em", marginBottom: "3em"}} >
                                <img style={{width:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:dario.uda@sirmec.eu">dario.uda@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Roberto Rosati" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Roberto Rosati" description="Resp. Filiale Cagliari"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:roberto.rosati@sirmec.eu">roberto.rosati@sirmec.eu</a></h5>
                            </div>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-telefono-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="tel:3371636447">337 163 6447</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Sebastiano Olivieri" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Sebastiano Olivieri" description="Preventivi, Ordini, Disponibilità"/>
                            <div style={{marginTop:"1em", marginBottom: "3em"}} >
                                <img style={{width:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:sebastiano.olivieri@sirmec.eu">sebastiano.olivieri@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Dario Uda" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Fabio Masala" description="Resi e Garanzie"/>
                            <div style={{marginTop:"1em", marginBottom: "3em"}} >
                                <img style={{width:"1.5em"}} src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:fabio.masala@sirmec.eu">fabio.masala@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* AMMINISTRAZIONE */}
            <div className="mainSection">
                <h1>Amministrazione</h1>
                <h4>Per fatture, pagamenti, pratiche per flotte e consorzi</h4>

                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-telefono-48.png'}  ></img> <h4 className="contact" ><a href="tel:0973585096">0973 585096</a></h4>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} ></img> <h4 className="contact"><a href="mailto:amministrazione@sirmec.eu">amministrazione@sirmec.eu</a></h4>
                    </Col>
                </Row>

                <Row style={{marginTop:"1em"}} gutter={16}>

                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Francesca Propati" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Francesca Propati" description="Resp. Contabilità"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:francesca.propati@sirmec.eu">francesca.propati@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Fabio Matrone" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Fabio Matrone" description="Flotte, consorzi, DFS"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:fabio.matrone@sirmec.eu">fabio.matrone@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Enzo De Stefano" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Enzo De Stefano" description="IT"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:enzo.destefano@sirmec.eu">enzo.destefano@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>
                    
                </Row>
            </div>

             {/* DIREZIONE */}
             <div className="mainSection">
                <h1>Direzione</h1>
                
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <img src={process.env.PUBLIC_URL + '/icon/icons8-telefono-48.png'}  ></img> <h4 className="contact" ><a href="tel:0973585096">0973 585096</a></h4>
                    </Col>
                </Row>

                <Row style={{marginTop:"1em"}} gutter={16}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Mario Rocco Messuti" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Mario Rocco Messuti" description="Amministratore Delegato"/>
                            <div style={{marginTop:"1em", marginBottom: "3em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:rocco.messuti@sirmec.eu">rocco.messuti@sirmec.eu</a></h5>
                            </div>
                        </Card>
                    </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Card className="user-image-cover" cover={<img alt="Nicola Messuti" src= {process.env.PUBLIC_URL + '/sirmec-logo-192.png'}/>}> 
                            <Meta title="Nicola Messuti" description="Direttore Operativo"/>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-posta-elettronica-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="mailto:nicola.messuti@sirmec.eu">nicola.messuti@sirmec.eu</a></h5>
                            </div>
                            <div style={{marginTop:"1em"}} >
                                <img style={{width:"1.5em"}}src={process.env.PUBLIC_URL + '/icon/icons8-telefono-48.png'} className="contactIcon" ></img> <h5 style={{display:"inline-block"}}><a href="tel:3701412074">370 14 12 074</a></h5>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            </div>
        )
    }
}
export default Contacts;