import React from 'react';
import { connect } from "react-redux";

import {Divider, DatePicker, Form, Input, Button} from 'antd'
import {fetchResi} from "../../actions/actions.js"
import { Table, Icon, Tag, Select, Spin, Modal, Dropdown  } from 'antd';
import { SearchOutlined, SettingOutlined, CalendarOutlined } from '@ant-design/icons';
import { Collapse, Card } from 'antd';
import {moneyFormat} from "../../utils.js"
import { useHistory, Link, Redirect } from "react-router-dom";
import get from "lodash.get";
import "./style.css"

import TextArea from 'antd/lib/input/TextArea';

const mapStateToProps = state => {
    return { 
        resi : state.resi,
        user: state.user
    };
};
function mapDispatchToProps(dispatch) {

    return {
        fetchResi : (startFrom, year) => dispatch(fetchResi(startFrom, year))
    }
}

class Resi extends React.Component {

    constructor(props) {
        super(props);
        /*
         * LOCAL STATE
        */
        this.state = {
            user : this.props.user,
            resi:  null,
            codeToFilter: null,
            key : 1,
            pageSize: 10,

            showDetail: false,
            detailRecord: null,

            selectedYear: new Date().getFullYear(),

            redirectToNewReq: false,
            redirectToReqList: false,
        }

        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.handleNewClick = this.handleNewClick.bind(this); 
        this.handleReqList  = this.handleReqList.bind(this);
        this.onShowSizeChange = this.onShowSizeChange.bind(this);
        this.onShowDetails = this.onShowDetails.bind(this);
        this.showDate = this.showDate.bind(this);
    }
    

    onShowDetails(record){

      this.setState(
        {
          ...this.state,
          showDetail:true,
          detailRecord:record
        }
      )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.resi !== prevProps.resi)
        {
         console.log("Updating state - Resi State changed ")
         console.log(this.props.resi)

         let loadNext = false

         if(this.props.resi.data.length > 0){
           loadNext = true
         }

         this.setState({
             ...this.state,
             resi : this.props.resi,
             pagination : this.props.resi.pagination,
             requestIsPending : this.props.resi.requestIsPending
         })
       }
    }

    componentDidMount() {        
        this.props.fetchResi();
    }
    
    handleChangeYear(y){
      this.setState({
        ...this.state,
        key : this.state.key + 1,
        selectedYear : y
      }, ()=>{
        this.props.fetchResi(null,y);
      } )
    }

    handleReset() {
      this.setState({
        ...this.state,
        key: this.state.key + 1
      })
    };


    handleSearch(selectedKeys, confirm, dataIndex){
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    }

    handleNewClick(){

      this.setState({
        ...this.state,
        redirectToNewReq:true
      })
      
    }

    handleReqList(){
      this.setState({
        ...this.state,
        redirectToReqList:true
      })
    }

    onShowSizeChange(current, size){

      console.log("on show size change")
      console.log(size)

      this.setState({
        ...this.state,
        pageSize:size
      })
    }

    showDate(date) {
      return(
        <Tag icon={<CalendarOutlined />} color="#cd201f" ><b style={{color:'#fff'}}>{date}</b></Tag>
      )
    }

    getColumnSearchProps = (dataIndex, exact, dataIndex2, replaceDotWithComma) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Filtra
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Elimina i filtri
            </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
      onFilter: function(value, record) {
    
          console.log("On filter called with value: " + value + " and record")
          console.log(record)
          
          if(replaceDotWithComma){
            value = value.replaceAll(',','.')
          }
    
    
          //Search on field1 + field2  
          if(dataIndex2!==null && dataIndex2!==undefined){
            if(exact==null || exact===undefined || exact===false ){
              return ( (get(record, dataIndex) || "")  + "-" + (get(record, dataIndex2) || "") ).toString().toLowerCase().includes(value.toString().toLowerCase())
            }else{
              //exact
              return ( (get(record, dataIndex) || "")  + "-" + (get(record, dataIndex2) || "") ).toString().toLowerCase() === (value.toString().toLowerCase())
            }
            
          }
          else if(exact===null || exact===undefined || exact===false){
            console.log("Search exact false " )
            console.log( ("Record:" + get(record, dataIndex)  || "").toString().toLowerCase() )
            console.log("Value:"  + value.toString().toLowerCase())
            return (get(record, dataIndex)  || "").toString().toLowerCase().includes(value.toString().toLowerCase())
          }
          else{
            return (get(record, dataIndex) || "").toString().toLowerCase() === value.toString().toLowerCase()
          }
      }
        ,
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
    });

    render() {

        const { Option } = Select;
        const { Panel } = Collapse;

        let currentYear = new Date().getFullYear();
        let firstYear = 2020

        var years = [];
        for (var i = currentYear; i >= firstYear; i--) {
          years.push(i);
        }

        let xScroll = true;
        if(this.state.user.uRole==="ADMIN"){
          xScroll = 1600;
        }


        let status = [] 
        status["RICHIESTO"] = "blue";
        status["AUTORIZZATO"] = "#2db7f5"
        status["RIFIUTATI"] = "red"              
        status["RICEVUTO"] = "#f50"                            
        status["BOLLA CLIENTE MANCANTE"] = "magenta"
        status["COMPLETO"] = "#87d068"            
        
        let t = this
        
        const columns = [

          {
            title: 'Prec.', width: "7%", key: 'precodice', index: 'precodice',
            render: function (text, record, index) {
                return (<span>{record.dmsData.precodice}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "precodice"])
          },
          {
            title: 'Codice', width: "10%", key: 'codice', index: 'codice',
            render: function (text, record, index) {
              return (<span>{record.dmsData.articolo}</span>);
            }, 
            ...this.getColumnSearchProps(["dmsData", "articolo"])
          },
          {
            title: 'Descr.', width: "15%", key: 'descr_articolo', index: 'descr_articolo',
            render: function (text, record, index) {
              return (<span>{record.dmsData.descr_articolo}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "descr_articolo"])
          }, 
          {
            title: 'Qnt.', width: "8%", key: 'quantita', index: 'quantita',
            render: function (text, record, index) {
              return (<span>{record.dmsData.quantita}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "quantita"], true)
          }, 
          {
            title: 'Importo', width: "8%", key: 'importo_riga', index: 'importo_riga',
            render: function (text, record, index) {
              return (<span>{moneyFormat(record.dmsData.importo_riga)}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "importo_riga"],true, null, true)
          }, 
          {
            title: 'Note Agg.', width: "20%",  key: 'note', index: 'note',
            render: function (text, record, index) {
              return (<span>{record.dmsData.note_agg}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "note_agg"])
          }, 
          {
            title: 'Stato', width: "8%",  key: 'status', index: 'status',
            render: function (text, record, index) {
              return (<Tag color={status[record.status]} >{record.status}</Tag>);
            },
            ...this.getColumnSearchProps(["status"])
          }, 
          {
            title: 'Bolla Cliente', width: "8%",  key: 'td_bolla', 
            render: function (text, record, index) {
              if(record.dmsData.td_bolla){

                return (
                <Dropdown overlay={t.showDate( (record.dmsData.data_bolla || "").substring(0, 10))}>  
                    <span>{record.dmsData.td_bolla}-{record.dmsData.num_bolla} </span>
                </Dropdown>
                );

              }else{

                return (
                <Dropdown overlay={t.showDate( (record.dmsData.data_bolla || "").substring(0, 10))}> 
                  <span>{record.dmsData.num_bolla} </span>
                </Dropdown>
                );

              }
            }, 
            ...this.getColumnSearchProps(["dmsData","td_bolla"], true, ["dmsData","num_bolla"] )
          },
          {
            title: 'Lista ', width: "8%",  key: 'lista', 
            render: function (text, record, index) {
            return (  
              <Dropdown overlay={t.showDate( (record.dmsData.data_doc || "").substring(0, 10))}>
                 <span>{record.dmsData.tipo_doc}-{record.dmsData.num_doc} </span>
              </Dropdown>
            );
            }, 
            ...this.getColumnSearchProps(["dmsData", "tipo_doc"], false, ["dmsData", "num_doc"] )
          },
          {
            title: 'Fatt./Nota Cred.', width: "8%",  key: 'num_fatt', 
            render: function (text, record, index) {
              return (  
                <Dropdown overlay={t.showDate( (record.dmsData.data_fatt || "").substring(0, 10))}>
                  <span>{record.dmsData.td_fatt}-{record.dmsData.num_fatt} </span>
                </Dropdown>   
              );
            }, 
            ...this.getColumnSearchProps(["dmsData", "td_fatt"], true, ["dmsData", "num_fatt"] )
          }
        ];

        const columnsAdmin = [

          {
            title: 'Cliente.', width: "72px", key: 'id_cliente', index: 'id_cliente', fixed: 'left',
            render: function (text, record, index) {
                return (<span>{record.dmsData.id_cliente}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "id_cliente"],true)

          },
          {
            title: 'Rag.Soc.', width: "140px", key: 'rRagSoc', index: 'rRagSoc', fixed: 'left',
            render: function (text, record, index) {
                return (<span>{record.rRagSoc}</span>);
            },
            ...this.getColumnSearchProps("rRagSoc",false)

          },
          {
            title: 'Prec.', width: "80px", key: 'precodice', index: 'precodice', fixed: 'left',
            render: function (text, record, index) {
                return (<span>{record.dmsData.precodice}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "precodice"])
          },
          {
            title: 'Codice', width: "130px", key: 'codice', index: 'codice', fixed: 'left',
            render: function (text, record, index) {
              return (<span>{record.dmsData.articolo}</span>);
            }, 
            ...this.getColumnSearchProps(["dmsData", "articolo"])
          },
          {
            title: 'Descr.', width: "200px", key: 'descr_articolo', index: 'descr_articolo',
            render: function (text, record, index) {
              return (<span>{record.dmsData.descr_articolo}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "descr_articolo"])
          }, 
          {
            title: 'Qnt.', width: "64px", key: 'quantita', index: 'quantita',
            render: function (text, record, index) {
              return (<span>{record.dmsData.quantita}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "quantita"],true)
          }, 
          {
            title: 'Importo', width: "96px", key: 'importo_riga', index: 'importo_riga',
            render: function (text, record, index) {
              return (<span>{moneyFormat(record.dmsData.importo_riga)}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "importo_riga"],true, null, true)
          }, 
          {
            title: 'Note Agg.', width: "350px",  key: 'note', index: 'note',
            render: function (text, record, index) {
              return (<span>{record.dmsData.note_agg}</span>);
            },
            ...this.getColumnSearchProps(["dmsData", "note_agg"])
          }, 
          {
            title: 'Stato', width: "120px",  key: 'status', index: 'status',
            render: function (text, record, index) {
              return (<Tag color={status[record.status]} >{record.status}</Tag>);
            },
            ...this.getColumnSearchProps(["status"])
          }, 
          {
            title: 'Bolla Cliente', width: "120px",  key: 'td_bolla', 
            render: function (text, record, index) {
              if(record.dmsData.td_bolla){

                return (
                <Dropdown overlay={t.showDate( (record.dmsData.data_bolla || "").substring(0, 10))}>  
                    <span>{record.dmsData.td_bolla}-{record.dmsData.num_bolla} </span>
                </Dropdown>
                );

              }else{

                return (
                <Dropdown overlay={t.showDate( (record.dmsData.data_bolla || "").substring(0, 10))}> 
                  <span>{record.dmsData.num_bolla} </span>
                </Dropdown>
                );

              }
            }, 
            ...this.getColumnSearchProps(["dmsData","td_bolla"], true, ["dmsData","num_bolla"] )
          },
          {
            title: 'Lista ', width: "120px",  key: 'lista', 
            render: function (text, record, index) {
            return (  
              <Dropdown overlay={t.showDate( (record.dmsData.data_doc || "").substring(0, 10))}>
                 <span>{record.dmsData.tipo_doc}-{record.dmsData.num_doc} </span>
              </Dropdown>
            );
            }, 
            ...this.getColumnSearchProps(["dmsData", "tipo_doc"], false, ["dmsData", "num_doc"] )
          },
          {
            title: 'Fatt./Nota Cred.', width: "120px",  key: 'num_fatt', 
            render: function (text, record, index) {
              return (  
                <Dropdown overlay={t.showDate( (record.dmsData.data_fatt || "").substring(0, 10))}>
                  <span>{record.dmsData.td_fatt}-{record.dmsData.num_fatt} </span>
                </Dropdown>   
              );
            }, 
            ...this.getColumnSearchProps(["dmsData", "td_fatt"], true, ["dmsData", "num_fatt"] )
          }
        ];
        
        if(this.state.requestIsPending){
            return (  
            <div>
              <Spin style={{marginLeft:"49%", paddingTop:"2em"}} size="large" tip="Ricerca in corso..." /> 
            </div>
            )
        }

        let col = columns
        if(this.state.user.uRole==='ADMIN'){
          col = columnsAdmin
        }  


        if(this.state.redirectToNewReq){
          return(
          <Redirect to="/nuovoReso" />
          )
        }

        if(this.state.redirectToReqList){
          return(
          <Redirect to="/richiesteReso" />
          )
        }

        return(
            <div>
            <Collapse defaultActiveKey={['']}>
              <Panel className="resi red" header="Clicca qui per leggere le informazioni sui resi" key="1">

              <p>Nella sezione <b>Lista Resi</b> puoi vedere lo stato di tutti i resi <b>che sono stati già ricevuti dal nostro magazzino.</b></p>
              <p>Nello sezione <Link href="/richiesteReso">Richieste di Reso</Link> puoi monitorare tutte le richieste di reso e le relative autorizzazioni alla resa.</p>
              <p>Per richiedere un nuovo reso, utilizza il pulsante <Link href="/nuovoReso">Nuovo Reso</Link> </p>
              <p>Gli stati dei resi sono: </p>
              {
                /*
              <p><Tag color="blue">Richiesto</Tag>: il cliente ha provveduto a chiedere il reso</p>
              <p><Tag color="#2db7f5">Autorizzato</Tag>: il cliente ha provveduto a chiedere il reso che è stato autorizzato dal nostro magazzino</p>
              <p><Tag color="red">Rifiutato</Tag>: il cliente ha provveduto a chiedere il reso ma la richiesta è stata rifiutata dal magazzino</p>
                */
              }
              <p><Tag color="#f50">Ricevuto</Tag>: il magazzino ha ricevuto correttamente il prodotto reso.</p>
              <p><Tag color="magenta">Bolla cliente mancante</Tag>: il magazzino ha ricevuto il prodotto reso ma senza la bolla del cliente che dovrà essere reinviata via mail a <a href="mailto:mag@sirmec.eu"> mag@sirmec.eu</a> per continuare il processo di reso.</p>
              <p><Tag color="#87d068">Completo</Tag>: Il reso è stato liquidato (vedi campo Nota Credito). </p>
              
              <p>I resi ricevuti in un anno e liquidati nell'anno successivo vengono chiusi con documento <b>XX-999999</b> nell'anno del ricevimento del reso 
                 e sono <b>riaperti nell'anno successivo</b> </p>
              </Panel>
            </Collapse>

            <Card title="">
                 <span>Seleziona l'anno &nbsp;</span>
                <Select value={this.state.selectedYear} style={{ width: 120 }} onChange={this.handleChangeYear}>
                  {
                    years.map((value, index) => {
                      return <Option value={value}>{value}</Option>
                    })
                  }
                </Select>

                <Button onClick={() => this.handleReset()} >
                  Elimina i filtri
                </Button>
                
                <Button type="primary" onClick={this.handleNewClick} style={{marginLeft:"2em"}} >
                 Nuovo Reso
                </Button>

                <Button type="primary" onClick={this.handleReqList} style={{marginLeft:"2em"}} >
                 Richieste di Reso
                </Button>
            </Card>
            <div style={{maxHeight:'60em', overflow:'scroll'}}>
                { this.state.resi !== undefined && this.state.resi  !== null && (
                <Table key={this.state.key} className="search-results-table" 
                    columns={col} scroll={{x: xScroll}}
                    dataSource={this.state.resi.data} 
                    pagination={{ pageSize: this.state.pageSize, showSizeChanger: true, onShowSizeChange: this.onShowSizeChange, pageSizeOptions: [5,10,15,30,50]
                  }}  />
                )}
            </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Resi);
