import React from 'react';
import { connect } from "react-redux";

import { Divider, DatePicker, Form, Input, Button, Row } from 'antd'
import { fetchResiReq } from "../../actions/actions.js"
import { Table, Icon, Tag, Select, Spin, Modal, Dropdown, Alert } from 'antd';
import { SearchOutlined, SettingOutlined, SendOutlined, ExclamationCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import get from "lodash.get";
import { Collapse, Tooltip, Card, message } from 'antd';
import { moneyFormat } from "../../utils.js"
import { useHistory, Link, Redirect } from "react-router-dom";
import TextArea from 'antd/lib/input/TextArea';
import { getApiUrl } from '../../utils.js'
import "./style.css"

const mapStateToProps = state => {
  return {
    resiReq: state.resiReq,
    user: state.user
  };
};
function mapDispatchToProps(dispatch) {

  return {
    fetchResiReq: (startFrom, year, pageLimit) => dispatch(fetchResiReq(startFrom, year, pageLimit))
  }
}

class ResiReq extends React.Component {

  constructor(props) {
    super(props);
    /*
     * LOCAL STATE
    */
    this.state = {
      user: this.props.user,
      resiReq: null,
      codeToFilter: null,
      key: 1,
      pageSize: 10,
      redirectToNew: false,

      showAdminPopup: false,
      adminRow: null,
      modalKey: 1,
      showSuccessMessages: false,

      pageLimit: 49,
      showPageLimit: true
    }

    this.refReqType = React.createRef();
    this.refNote = React.createRef();
    this.refForm = React.createRef();

    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.onManageClick = this.onManageClick.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.manageReturn = this.manageReturn.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.resend = this.resend.bind(this);
  }


  manageReturn() {

    this.refForm.current.validateFields()
      .then(v => {

        let param = {
          rId: this.state.adminRow[0].rId,
          rAuth: this.refReqType.current.props.value === "1" ? true : false,
          sirNote: this.refNote.current.props.value === undefined ? "" : this.refNote.current.props.value
        }

        fetch(getApiUrl() + "/returns/handle?" + new URLSearchParams(param), {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'include',
          headers: new Headers({
            "Content-Type": 'application/json',
            "Accept": 'application/json'
          }),
          body: JSON.stringify(param)
        })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              throw (res);
            } else {

              let t = this
              let newList = this.state.resiReq.data.map(function callback(currentValue, index, array) {
                if (currentValue.rId === t.state.adminRow[0].rId) {
                  return res.data
                } else {
                  return currentValue
                }
              })
              this.setState({
                ...this.state,
                showSuccessMessages: true,
                resiReq: {
                  ...this.state.resiReq,
                  data: newList
                },
                //key : this.state.key + 1,
                //modalKey: this.state.modalKey + 1,
              })
            }
          })
          .catch(res => {
            alert(res.message)
          })
      })
      //ERROR IN VALIDATION
      .catch(errorInfo => console.log("Validation error"))
  }

  showPopup(info) {
    return (
      <Tag color="#cd201f" ><b style={{ color: '#fff' }}>{info}</b></Tag>
    )
  }

  dropPageFilter() {
    this.setState({
      ...this.state,
      pageLimit: Number.MAX_SAFE_INTEGER,
      showPageLimit: false
    }, () => this.props.fetchResiReq(undefined, undefined, this.state.pageLimit))
  }

  addPageFilter() {
    this.setState({
      ...this.state,
      pageLimit: 49,
      showPageLimit: true
    }, () => this.props.fetchResiReq(undefined, undefined, this.state.pageLimit))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.resiReq !== prevProps.resiReq) {
      console.log("Updating state - ResiReq State changed ")
      console.log(this.props.resiReq)

      this.setState({
        ...this.state,
        resiReq: this.props.resiReq,
        pagination: this.props.resiReq.pagination,
        requestIsPending: this.props.resiReq.requestIsPending,
      })
    }
  }

  componentDidMount() {
    console.log("Calling fetch resi request")
    this.props.fetchResiReq(undefined, undefined, this.state.pageLimit);
  }

  getColumnSearchProps = (dataIndex, exact, dataIndex2, replaceDotWithComma) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Filtra
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Elimina i filtri
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#ff471a' : undefined }} />,
    onFilter: function (value, record) {

      console.log("On filter called with value: " + value + " and record")
      console.log(record)

      if (replaceDotWithComma) {
        value = value.replaceAll(',', '.')
      }


      //Search on field1 + field2  
      if (dataIndex2 !== null && dataIndex2 !== undefined) {
        if (exact == null || exact === undefined || exact === false) {
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase().includes(value.toString().toLowerCase())
        } else {
          //exact
          return ((get(record, dataIndex) || "") + "-" + (get(record, dataIndex2) || "")).toString().toLowerCase() === (value.toString().toLowerCase())
        }

      }
      else if (exact === null || exact === undefined || exact === false) {
        console.log("Search exact false ")
        console.log(("Record:" + get(record, dataIndex) || "").toString().toLowerCase())
        console.log("Value:" + value.toString().toLowerCase())
        return (get(record, dataIndex) || "").toString().toLowerCase().includes(value.toString().toLowerCase())
      }
      else {
        return (get(record, dataIndex) || "").toString().toLowerCase() === value.toString().toLowerCase()
      }
    }
    ,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });


  handleReset() {
    this.setState({
      ...this.state,
      key: this.state.key + 1
    })
  };

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleNewClick() {
    this.setState({
      ...this.state,
      redirectToNew: true
    })
  }

  onShowSizeChange(current, size) {

    console.log("on show size change")
    console.log(size)

    this.setState({
      ...this.state,
      pageSize: size
    })
  }

  onManageClick(record) {

    this.setState({
      ...this.state,
      showAdminPopup: true,
      adminRow: [record],
      modalKey: this.state.modalKey + 1
    })

  }
  resend(record) {

    fetch(getApiUrl() + "/returns/resend", {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: new Headers({
        "Content-Type": 'application/json',
        "Accept": 'application/json'
      }),
      body: JSON.stringify(record)
    })
      .then(res => {

        if (res.ok) {
          message.info("Email reinviata")
        } else {
          throw ("Error");


        }
      }).catch(errorInfo => {
        console.log(errorInfo)
        message.error("Impossibile inviare la mail controllare l'indirizzo")

      })


  }

  hideModal() {
    this.setState({
      ...this.state,
      showAdminPopup: false,
      adminRow: null,
      showSuccessMessages: false
    })
  }

  render() {

    const { Option } = Select;
    const { Panel } = Collapse;

    let status = []
    status["RICHIESTO"] = "blue";
    status["AUTORIZZATO"] = "#2db7f5"
    status["RIFIUTATO"] = "red"
    status["RICEVUTO"] = "#f50"
    status["BOLLA CLIENTE MANCANTE"] = "magenta"
    status["COMPLETO"] = "#87d068"

    let t = this

    if (this.state.redirectToNew == true) {
      return (
        <Redirect to="/resi/new" />
      )
    }



    if (this.state.requestIsPending) {
      return (
        <div>
          <Spin style={{ marginLeft: "49%", paddingTop: "2em" }} size="large" tip="Ricerca in corso..." />
        </div>
      )
    }


    const columnsAdmin = [

      {
        title: 'Data Richiesta', width: "96px", key: 'rCreationTime', index: 'rCreationTime', fixed: 'left',
        render: function (text, record, index) {

          return (record.rCreationTime.substring(0, 19));
        },
        ...this.getColumnSearchProps("rCreationTime", false)

      },
      {
        title: 'Rag.Soc.', width: "100px", key: 'rRagSoc', index: 'rRagSoc', fixed: 'left',
        render: function (text, record, index) {
          return (
            <Dropdown overlay={t.showPopup(record.uIdCliente + "-" + record.username)}>
              <span>{record.rRagSoc} </span>
            </Dropdown>
          );
        },
        ...this.getColumnSearchProps("rRagSoc", false)

      },
      //  {
      //    title: 'Destinazione', width: "50px", index: 'dest', fixed: 'left',
      //    render: function (text, record, index) {
      //        return (<span>{record.destinazione}</span>);
      //    },
      //    ...this.getColumnSearchProps("destinazione",true)

      //  },
      {
        title: 'Prec.', width: "50px", key: 'rPrecode', index: 'rPrecode', fixed: 'left',
        render: function (text, record, index) {
          return (<span>{record.rPrecode}</span>);
        },
        ...this.getColumnSearchProps("rPrecode", true)
      },
      {
        title: 'Codice', width: "100px", key: 'rCode', index: 'rCode', fixed: 'left',
        render: function (text, record, index) {
          return (<span>{record.rCode}</span>);
        },
        ...this.getColumnSearchProps("rCode", false)
      },
      {
        title: 'Descrizione', width: "100px", key: 'rDescr', index: 'rDescr', fixed: 'left',
        render: function (text, record, index) {
          return (<span>{record.rDescr}</span>);
        },
        ...this.getColumnSearchProps("rDescr", true)

      },
      {
        title: 'Qnt.', width: "50px", key: 'rQnt', index: 'rQnt',
        render: function (text, record, index) {
          return (<span>{record.rQnt}</span>);
        },
        ...this.getColumnSearchProps("rQnt", true)
      },
      {
        title: 'Motivo', width: "100px", key: 'rrExtendedText', index: 'rrExtendedText',
        render: function (text, record, index) {
          return (<span>{record.rrExtendedText}</span>);
        },
        ...this.getColumnSearchProps(["rrExtendedText"])
      },

      {
        title: 'Note', width: "200px", key: 'rNote', index: 'rNote',
        render: function (text, record, index) {
          return (<span>{record.rNote}</span>);
        },
        ...this.getColumnSearchProps(["rNote"])
      },
      {
        title: 'Stato', width: "110px", key: 'status', index: 'status',
        render: function (text, record, index) {
          var moment = require('moment-business-days');
          var diff = moment(record.rCreationTime.substring(0, 10), 'YYYY-MM-DD').businessDiff(moment(record.rOutboundDdtDate, 'YYYY-MM-DD'));
          const displayStatus = (diff > 4 && record.rrExtendedText !== "Cauzione" && record.status === "RICHIESTO") ? "inline" : "none"
          return (<div>
            <Row>
            <Tag color={status[record.status]} > {record.status} </Tag>
            </Row>
            <Row>
            <icon style={{ display: displayStatus }}> {<Tag color="red"><ClockCircleOutlined /> </Tag>}</icon>
            </Row>
          </div>);
        },
        ...this.getColumnSearchProps(["status"])
      },
      {
        title: 'Azioni', width: "50px", key: 'action', index: 'action',
        render: function (text, record, index) {
          return (<span><SettingOutlined onClick={() => { t.onManageClick(record) }} /> {record.rAuthCode !== undefined && <Tooltip title="Reinvia mail di conferma"><SendOutlined onClick={() => { t.resend(record) }} /></Tooltip>}</span>)
        },
      },      
      {
        title: 'DDT Vend.', width: "65px", key: 'rOutboundDdt', index: 'rOutboundDdt',
        render: function (text, record, index) {
          return (<span>{record.rOutboundDdtType}-{record.rOutboundDdt}</span>);
        },
        ...this.getColumnSearchProps(["rOutboundDdtType"], false, ["rOutboundDdt"])
      },
      {
        title: 'Data DDT', width: "96px", key: 'rOutboundDdtDate', index: 'rOutboundDdtDate',
        render: function (text, record, index) {
          return (<span>{record.rOutboundDdtDate}</span>);
        },
        ...this.getColumnSearchProps("rOutboundDdtDate")
      },
      {
        title: 'Cod.Aut.', width: "90px", key: 'rAuthCode', index: 'rAuthCode',
        render: function (text, record, index) {
          return (<span>{record.rAuthCode}</span>);
        },
        ...this.getColumnSearchProps(["rAuthCode"], true)
      }
    ];

    const columns = [
      {
        title: 'Data Richiesta', width: "8%", key: 'rCreationTime', index: 'rCreationTime', fixed: 'left',
        render: function (text, record, index) {
          return (<span>{record.rCreationTime.substring(0, 19)}</span>);
        },
        ...this.getColumnSearchProps("rCreationTime", false)

      },
      {
        title: 'Prec.', width: "50px", key: 'rPrecode', index: 'rPrecode',
        render: function (text, record, index) {
          return (<span>{record.rPrecode}</span>);
        },
        ...this.getColumnSearchProps("rPrecode", true)
      },
      {
        title: 'Codice', width: "100px", key: 'rCode', index: 'rCode',
        render: function (text, record, index) {
          return (<span>{record.rCode}</span>);
        },
        ...this.getColumnSearchProps("rCode", false)
      },
      {
        title: 'Descrizione', width: "100px", key: 'rDescr', index: 'rDescr', fixed: 'left',
        render: function (text, record, index) {
          return (<span>{record.rDescr}</span>);
        },
        ...this.getColumnSearchProps("rDescr", true)

      },
      {
        title: 'Qnt.', width: "64px", key: 'rQnt', index: 'rQnt',
        render: function (text, record, index) {
          return (<span>{record.rQnt}</span>);
        },
        ...this.getColumnSearchProps("rQnt", true)
      },
      {
        title: 'DDT Vend.', key: 'rOutboundDdt', index: 'rOutboundDdt',
        render: function (text, record, index) {

          return (<span>{record.rOutboundDdtType}-{record.rOutboundDdt}</span>);
        },

        ...this.getColumnSearchProps(["rOutboundDdtType"], false, ["rOutboundDdt"])
      },
      {
        title: 'Data DDT', width: "96px", key: 'rOutboundDdtDate', index: 'rOutboundDdtDate',
        render: function (text, record, index) {
          return (<span>{record.rOutboundDdtDate}</span>);
        },
        ...this.getColumnSearchProps("rOutboundDdtDate")
      },
      {
        title: 'Motivo', key: 'rrExtendedText', index: 'rrExtendedText',
        render: function (text, record, index) {
          return (<span>{record.rrExtendedText}</span>);
        },
        ...this.getColumnSearchProps(["rrExtendedText"])
      },

      {
        title: 'Note', key: 'rNote', index: 'rNote',
        render: function (text, record, index) {
          return (<span>{record.rNote}</span>);
        },
        ...this.getColumnSearchProps(["rNote"])
      },

      {
        title: 'Stato', key: 'status', index: 'status',
        render: function (text, record, index) {
          return (<Tag color={status[record.status]} >{record.status}</Tag>);
        },
        ...this.getColumnSearchProps(["status"])
      },
      {
        title: 'Cod.Aut.', key: 'rAuthCode', index: 'rAuthCode',
        render: function (text, record, index) {
          return (<span>{record.rAuthCode}</span>);
        },
        ...this.getColumnSearchProps(["rAuthCode"], true)
      },
    ];

    let col = columns;

    if (this.state.user.uRole === 'ADMIN') {
      col = columnsAdmin;
    }

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <div>

        <Modal key={this.state.modalKey} width={"85%"} title="Conferma Reso" visible={this.state.showAdminPopup} onOk={null} onCancel={this.hideModal}
          okText={null} cancelText="Chiudi" okButtonProps={{ style: { display: 'none' } }} >
          <p>Stai approvando il reso per l'articolo:</p>

          <Table className="search-results-table" columns={col} dataSource={this.state.adminRow} />

          <Form ref={this.refForm} {...layout} name="basic" initialValues={{ remember: true }}  >

            <Form.Item label="Indicare se approvare il reso" name="motivation" rules={[{ required: true, message: 'Inserisci lo stato del reso' }]}>
              <Select ref={this.refReqType}>
                <span key={1}>AUTORIZZATO</span>
                <span key={0}>RIFIUTATO</span>
              </Select>
            </Form.Item>

            <Form.Item label="Note" name="note" rules={[{ required: false }]}>
              <TextArea ref={this.refNote} />
            </Form.Item>

            <Form.Item label="Imposta" name="qnt" >
              <Button onClick={this.manageReturn} type="primary">Cambia stato</Button>
            </Form.Item>

          </Form>

          {this.state.showSuccessMessages === true && (
            <Alert message="Conferma" description="Lo stato è stato cambiato con successo." type="success" showIcon />
          )
          }

        </Modal>

        <Collapse defaultActiveKey={['']}>
          <Panel className="resi red" header="Leggi le informazioni sui resi" key="1">

            <p>In questa sezione <b>Richieste reso</b> puoi vedere lo stato di tutte le richieste di reso e la loro approvazione.</p>
            <p>Nella sezione <Link to="/resi">Resi</Link> puoi vedere lo stato di tutti i resi <b>che sono stati già ricevuti dal nostro magazzino.</b></p>

            <p>Gli stati dei resi sono: </p>

            <p><Tag color="blue">Richiesto</Tag>: il cliente ha provveduto a chiedere il reso</p>
            <p><Tag color="#2db7f5">Autorizzato</Tag>: il cliente ha provveduto a chiedere il reso che è stato autorizzato dal nostro magazzino</p>
            <p><Tag color="red">Rifiutato</Tag>: il cliente ha provveduto a chiedere il reso ma la richiesta è stata rifiutata dal magazzino</p>

          </Panel>
        </Collapse>

        <Card title="">
          <Button onClick={() => this.handleReset()} >
            Elimina i filtri
          </Button>
          &nbsp;

          {
            this.state.showPageLimit && (
              <a onClick={() => this.dropPageFilter()}>
                Stai visualizzando le prime {this.state.pageLimit + 1} pagine. Clicca qui per visualizzare tutti i resi.
              </a>
            )}

          {
            !this.state.showPageLimit && (
              <a onClick={() => this.addPageFilter()}>
                Stai visualizzando tutte le pagine. Clicca qui per ricaricare solo le prime.
              </a>
            )}
        </Card>

        <div style={{ maxHeight: '60em', overflow: 'scroll' }}>
          {this.state.resiReq !== undefined && this.state.resiReq !== null && (
            <Table key={this.state.key} className="search-results-table"
              columns={col}
              dataSource={this.state.resiReq.data}
              pagination={{
                pageSize: this.state.pageSize, showSizeChanger: true, onShowSizeChange: this.onShowSizeChange, pageSizeOptions: [5, 10, 15, 30, 50]
              }} />
          )
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResiReq);
