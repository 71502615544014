import React from 'react';
import {Link} from "react-router-dom";

class Messagebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: []
        }
    }


    render() {

        return(
            <div className="messageBar">
                <div className="messageBar item">
                    <div><Link style={{color:"white"}} to="/contacts"><span>SCOPRI TUTTI I MODI PER CONTATTARCI</span></Link></div>
                </div>
            </div>
        )
    }

}

export default Messagebar;